import { useCallback, useState, useEffect } from "react"
import orderBy from "lodash.orderby"

export const useBusinessCompare = (rows) => {
    const [defaultRows, setDefaultRows] = useState(rows)
    const [activeSort, setActiveSort] = useState<{
        sort_by: string
        sort_order: "asc" | "desc"
    }>({
        sort_by: "created",
        sort_order: "desc",
    })

    const handleSort = useCallback(
        (columnId: string, sorted: boolean) => {
            setActiveSort({
                sort_by: columnId,
                sort_order: sorted ? "asc" : "desc",
            })
        },
        [setActiveSort]
    )

    useEffect(() => {
        setDefaultRows(
            orderBy(
                rows,
                function (o) {
                    return new Number(o[activeSort.sort_by])
                },
                [activeSort.sort_order]
            )
        )
    }, [activeSort, rows])

    return {
        activeSort,
        handleSort,
        defaultRows,
    }
}
