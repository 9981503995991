const useDeviceCheck = () => {
    const userAgent = navigator.userAgent.toLowerCase()

    return {
        isMobile: /iphone|ipod|android|blackberry|windows phone/g.test(userAgent),
        isTablet: /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent),
        isWebView:
            userAgent ===
            "Mozilla/5.0 (Linux; Android 4.1.1; Galaxy Nexus Build/JRO03C) AppleWebKit/535.19 (KHTML, like Gecko) Chrome/18.0.1025.166 Mobile Safari/535.19".toLowerCase(),
    }
}

export default useDeviceCheck
