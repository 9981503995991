import { PaginationSearchLayout } from "@/components/widget/pagination-search-layout"
import useResponsive from "@/hooks/use-responsive"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { LocationCard } from "./location-card"
import { LocationCardSkeleton } from "@/components/widget/skeleton/location-card-skeleton"
import useLogic from "./useLogic"
import useUiPageSession from "@/hooks/useUiPageSession"

export const QrCode: React.FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { isMobile } = useResponsive()
    const { metadata, isLoading, locations, filterValue, handleChangePage, setFilterValue, isSingleBusiness } =
        useLogic()

    return (
        <>
            {!isSingleBusiness && (
                <PaginationSearchLayout
                    layoutTitle={t("MENU.QR_CODE_LINK")}
                    titleIcon="qr"
                    metadata={metadata}
                    handleChangePage={handleChangePage}
                    loading={isLoading}
                    searchValue={filterValue}
                    onSearch={setFilterValue}
                >
                    <div
                        style={{
                            display: "grid",
                            gap: 16,
                            gridTemplateColumns: isMobile ? "1fr" : "repeat(2, 1fr)",
                        }}
                    >
                        {isLoading
                            ? [...Array(8)].map((_, key) => <LocationCardSkeleton key={key} />)
                            : locations.map((location) => (
                                  <LocationCard
                                      key={location.id}
                                      location={location}
                                      onClick={() => navigate(`/qr/${location.id}`)}
                                      isMobile={isMobile}
                                  />
                              ))}
                    </div>
                </PaginationSearchLayout>
            )}
        </>
    )
}
