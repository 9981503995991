import { Stack } from "@mui/material"
import { SkeletonBusinessListItem } from "./item"
import { v4 as uuidv4 } from "uuid"

export const SkeletonBusinessList = () => {
    return (
        <Stack gap={2} flexDirection="row" flexWrap={"wrap"}>
            {[...Array(10)].map(() => (
                <SkeletonBusinessListItem key={uuidv4()} />
            ))}
        </Stack>
    )
}
