import { Theme } from "@emotion/react"
import { SxProps } from "@mui/material"
import { makeStyles } from "tss-react/mui"

export const logoSx: SxProps<Theme> = {
    padding: 2,
    width: "calc(100% - 16px)",
    maxWidth: 346 + 32,
    boxSizing: "border-box",
    "@media (min-width: 768px)": {
        width: "100%",
        p: 2,
    },
    "& .title-wrapper": {
        width: "100%",
        gap: 0,
        display: "flex",
        "& h2": {
            width: "fit-content",
            mx: "auto",
            whiteSpace: "nowrap",
        },
        "& .btn-x": {
            position: "absolute",
            top: "20px",
            right: "14px",
        },
    },
    "& .add-file": {
        display: "flex",
        gap: 1,
    },
    "& .error": {
        p: "3px 0px 0px 8px",
        fontSize: 12,
    },
    "& .drop-zone-two": {
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        flexDirection: "column",
        display: "flex",
        backgroundColor: "background.default",
        borderRadius: 1,
        maxWidth: 346,
        boxSizing: "border-box",
        gap: 1,
        color: "primary.main",
        margin: "auto",
        "& .add-or-drop": {
            display: "flex",
            gap: 1,
            p: 1,
            "& span": {
                textAlign: "center",
                wordBreak: "break-word",
            },
        },
        "& .image-type": {
            fontSize: 14,
            color: "action.active",
            letterSpacing: "0.17px",
        },
        "& .btn-txt": {
            color: "primary.main",
            textDecoration: "underline",
        },
        "& .image-plus": {
            p: 1.5,
        },
    },
    "& .drop-zone": {
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        flexDirection: "column",
        backgroundColor: "background.default",
        borderRadius: 1,
        maxWidth: 346,
        height: 225,
        boxSizing: "border-box",
        gap: 1,
        color: "primary.main",
        "& .add-or-drop": {
            display: "flex",
            gap: 1,
            p: 1,
            "& span": {
                textAlign: "center",
                wordBreak: "break-word",
            },
        },
        "& .image-type": {
            fontSize: 14,
            textAlign: "center",
            color: "action.active",
        },
        "& .btn-txt": {
            color: "primary.main",
            textDecoration: "underline",
        },
        "& .image-plus": {
            p: 1.5,
        },
    },
    "& .action-wrapper": {
        "@media (min-width: 768px)": {
            flexDirection: "column",
        },
    },
}

export const labelErrorSx = ({
    error = false,
    height,
    hidden,
}: {
    error: boolean
    height?: number
    hidden: boolean
}): SxProps => ({
    borderWidth: 2,
    borderColor: "transparent",
    borderStyle: "solid",
    ...(error && {
        borderColor: "error.main",
    }),
    height,
    cursor: "pointer",
    display: hidden ? "none" : "flex",
    "@media (min-width: 768px)": {
        height: height ? 346 : "auto",
    },
    "&:hover": {
        borderColor: error ? "error.main" : "divider",
        "& .prerequisites": {
            "& span": {
                color: "text.primary",
                transition: "color .6s ease-in-out",
            },
        },
        transition: "border-width .6s ease-in-out",
    },
})

const useStyles = makeStyles()((theme) => ({
    boxFilename: {
        height: 225,
        color: "common.white",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
            "&::before": {
                opacity: 1,
            },
            "& svg": {
                opacity: 1,
            },
        },
    },
    svgBloc: {
        gap: 16,
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        backgroundColor: "rgba(1, 128, 255, 0.16)",
        borderRadius: 10,
        padding: "0px 16px",
        color: theme.palette.primary.main,
        margin: "auto",
    },
}))

export default useStyles
