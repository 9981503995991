import ErrorComponent from "@/components/features/errors/400"
import { UiService } from "@/services"
import { useEffect } from "react"

const Error404 = () => {
    useEffect(() => {
        UiService.setState({ title: "404" })
    }, [])
    return <ErrorComponent code="404" />
}

export default Error404
