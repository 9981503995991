import GPlaceInputAddress from "@/components/widget/autocomplete/address-autocomplete"
import BaseInputPhone from "@/components/base/BaseInputPhone"
import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import BaseInput from "@/components/base/input"
import RadioTabButton from "@/components/base/radio-tab-buttons"
import BaseSelect from "@/components/base/select"
import AddLogoModal from "@/components/features/business/add-logo-modal"
import EditMainInfoContainer from "@/components/features/business/form-container/edit/edit-main-info"
import FormAction from "@/components/features/business/form-container/edit/form-action"
import { MAX_INPUT } from "@/data/limiter"
import useLogic from "@/hooks/businesses/edit-main-info/useLogic"
import { PageProps } from "@/types/page"
import { Box, Button, IconButton, Paper, Typography } from "@mui/material"
import classNames from "classnames"
import { Controller } from "react-hook-form"
import { TitleWrapper } from "@/components/base/title-wrapper"
import { useTranslation } from "react-i18next"
const EditMainInfo = ({ onClose, open }: PageProps) => {
    const { t } = useTranslation()

    const {
        companies,
        phone,
        zipInputValue,
        formValues,
        control,
        countryList,
        addressValuePlace,
        isZipValid,
        stateList,
        provinceList,
        loading,
        formState,
        langOfCountryName,
        defaultFlag,
        openLogoModal,
        location,
        logo,
        haveLogo,
        addressVisible,
        handlePreviousButton,
        handleSubmit,
        onSubmit,
        onErrors,
        setAddressValuePlace,
        handleAddressChange,
        handleAddressInputChange,
        register,
        zipInputHandler,
        handleCancelButton,
        setAddressVisible,
        handlePhoneChange,
        openLogo,
        closeLogo,
        setLogo,
        trigger,
    } = useLogic()

    return (
        <>
            <EditMainInfoContainer open={open} onClose={onClose}>
                <TitleWrapper
                    dataTestId="previous-edit-business"
                    handlePreviousButton={handlePreviousButton}
                    title={t("BUSINESSES.EDIT_MAIN_INFO.TITLE")}
                />
                <form onSubmit={handleSubmit(onSubmit, onErrors)}>
                    <Box className="inputs-container">
                        <Paper className="inputs-wrapper">
                            <Typography variant="h2" className="title">
                                <Icon name="profile" className="title-icon" />
                                {t("USERS.MAIN_INFO_EDIT")}
                            </Typography>
                            <Box className="logo-wrapper">
                                {(logo || location?.logo) && (
                                    <Box
                                        className="image-frame image-preview"
                                        onClick={openLogo}
                                        sx={{ cursor: "pointer" }}
                                    >
                                        <Box>
                                            <img
                                                src={(logo && URL.createObjectURL(logo)) || location.logo}
                                                alt="Logo"
                                            />
                                            <Icon name="imagePlus" />
                                        </Box>
                                        <Typography component={"span"}>
                                            {t("BUSINESSES.EDIT_LOGO.TITLE_TOOLTIP")}
                                        </Typography>
                                    </Box>
                                )}
                                {!location?.logo && !logo && (
                                    <Button
                                        className="image-frame without-logo"
                                        data-testid="edit-logo"
                                        onClick={openLogo}
                                    >
                                        <Icon name="imagePlus" />
                                        <Typography component={"span"}>{t("BUSINESSES.EDIT_LOGO.ADD")}</Typography>
                                    </Button>
                                )}
                                <Box className="inputs">
                                    <Controller
                                        render={({ field, fieldState }) => (
                                            <BaseInput
                                                max={MAX_INPUT.TEXT}
                                                data-testid="name"
                                                label={t("BUSINESSES.NAME")}
                                                required
                                                error={!!fieldState.error}
                                                helperText={!!fieldState.error && t("BUSINESSES.NAME_REQUIRED")}
                                                showLabelStar={true}
                                                {...field}
                                            />
                                        )}
                                        control={control}
                                        name="name"
                                    />
                                    {companies && (
                                        <BaseSelect
                                            label={t("USERS.COMPANY_ROLE")}
                                            options={companies}
                                            control={control}
                                            controlName="company"
                                            disabled
                                            data-testid="company"
                                        />
                                    )}
                                    <BaseInputPhone
                                        control={control}
                                        name="phone"
                                        label={t("BUSINESSES.EDIT_GENERAL.BUSINESS_PHONE_NUMBER")}
                                        onlyCountries={["CA", "US", "FR", "BE"]}
                                        lang={langOfCountryName}
                                        required={phone.isRequired}
                                        error={phone.isInvalid || phone.isRequired}
                                        defaultCountry={defaultFlag}
                                        onChange={handlePhoneChange}
                                        errorLabel={
                                            phone.isRequired
                                                ? t("BUSINESSES.WEBSITE_OR_PHONE_REQUIRED")
                                                : phone.isInvalid
                                                ? t("BUSINESSES.EDIT_MAIN_INFO.PHONE_INVALID")
                                                : ""
                                        }
                                    />
                                </Box>
                            </Box>
                        </Paper>
                        <Paper className="inputs-wrapper address">
                            <Box className="title-wrapper">
                                <Typography variant="h2" className="title">
                                    <Icon name="map" className="title-icon" />
                                    {t("COMPANIES.ADDRESS")}
                                </Typography>
                                <Box className="switch-button-wrapper">
                                    <RadioTabButton
                                        defaultActive={addressVisible ? 0 : 1}
                                        onChange={(value) => setAddressVisible(value === 0)}
                                        btnWrapperClassNames="radio-tab-button"
                                        data={[
                                            { label: t("BUSINESSES.ADDRESS.VISIBLE"), id: "visible" },
                                            { label: t("BUSINESSES.ADDRESS.HIDDEN"), id: "hidden" },
                                        ]}
                                    />
                                    <CustomTooltip title={t("BUSINESSES.TOGGLE_TOOLTIP")}>
                                        <IconButton>
                                            <Icon name="info" />
                                        </IconButton>
                                    </CustomTooltip>
                                </Box>
                            </Box>
                            <Box
                                className={classNames(
                                    "inputs",
                                    (formValues?.country === "FR" || formValues?.country === "BE") && "half-each"
                                )}
                            >
                                {countryList && (
                                    <BaseSelect
                                        required
                                        label={t("COMPANIES.COUNTRY")}
                                        options={countryList}
                                        control={control}
                                        controlName="country"
                                        data-testid="country"
                                    />
                                )}
                                <GPlaceInputAddress
                                    setField={setAddressValuePlace}
                                    field={addressValuePlace}
                                    label={t("COMPANIES.ADDRESS")}
                                    onSelect={handleAddressChange}
                                    required
                                    countryRestrictions={[formValues?.country]}
                                    defaultValue=""
                                    inputValueChange={handleAddressInputChange}
                                    error={!addressValuePlace?.description}
                                />
                                <Controller
                                    render={({ field, fieldState }) => (
                                        <BaseInput
                                            max={MAX_INPUT.TEXT}
                                            data-testid="city"
                                            required
                                            label={t("COMPANIES.TOWN")}
                                            showLabelStar={true}
                                            error={!!fieldState.error}
                                            {...field}
                                        />
                                    )}
                                    control={control}
                                    name="city"
                                />

                                <BaseInput
                                    shrink={!!formValues?.zip}
                                    max={MAX_INPUT.TEXT}
                                    data-testid="zip"
                                    label={t("COMPANIES.CODE")}
                                    value={zipInputValue}
                                    register={register("zip")}
                                    error={!zipInputValue || (zipInputValue && !isZipValid)}
                                    showLabelStar={true}
                                    required
                                    helperText={!isZipValid && t("COMPANIES.ZIP_INVALID")}
                                    onInput={zipInputHandler}
                                />

                                {(formValues?.country === "US" || formValues?.country === "CA") && (
                                    <BaseSelect
                                        control={control}
                                        controlName="region"
                                        required
                                        label={
                                            formValues?.country === "US"
                                                ? t("COMPANIES.STATE")
                                                : t("COMPANIES.PROVINCE")
                                        }
                                        options={formValues?.country === "US" ? stateList : provinceList}
                                        formStateError={!!formState.errors["region"]}
                                        onItemClicked={() => {
                                            setTimeout(() => trigger())
                                        }}
                                        data-testid="region"
                                    />
                                )}
                            </Box>
                        </Paper>
                    </Box>
                    <FormAction
                        handleCancel={handleCancelButton}
                        isDisabled={loading || !formState.isValid || !haveLogo || phone.isRequired}
                        isLoading={loading}
                        isUpdating
                    />
                </form>
            </EditMainInfoContainer>
            <AddLogoModal setLogo={setLogo} open={openLogoModal} close={closeLogo} />
        </>
    )
}

export default EditMainInfo
