import { OverviewTitleProps } from "@/types/businesses"
import { Box, IconButton, Typography } from "@mui/material"
import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"

const OverviewTitle = ({ icon, title, isSecondTitle, tooltipText }: OverviewTitleProps) => {
    return (
        <Box className="title" data-testid="title">
            <Box className={isSecondTitle ? "second-title" : "title-wrapper"} sx={titleSx}>
                {icon && <Icon name={icon} />}
                <Typography variant={isSecondTitle ? "h3" : "h2"} className="break-word">
                    {title}
                </Typography>
            </Box>
            {tooltipText && (
                <CustomTooltip title={tooltipText}>
                    <IconButton className="edit-btn" data-testid="edit-btn">
                        <Icon name="edit" />
                    </IconButton>
                </CustomTooltip>
            )}
        </Box>
    )
}
export default OverviewTitle

const titleSx = { color: "action.active" }
