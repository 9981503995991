import ListContainer from "@/components/widget/page-container/list-container"
import TitleWrapper from "@/components/widget/page-container/list-container/title-wrapper"
import ListTable from "@/components/widget/list-table"
import useLogic from "@/hooks/companies/list/useLogic"
import { Paper } from "@mui/material"
import { useTranslation } from "react-i18next"

const ListCompany = () => {
    const { t } = useTranslation()
    const { handleChangePage, items, metadata, selectedItem, updateViewState, loading } = useLogic()

    return (
        <ListContainer>
            <TitleWrapper title={t("COMPANIES.TITLE")} pageIcon={"company"} />
            <Paper>
                <ListTable
                    isLoading={loading}
                    handleChangePage={handleChangePage}
                    generateLabels={(row) => [row.name]}
                    rows={items}
                    metadata={metadata}
                    toolTipRowText={t("COMPANIES.VIEW_TOOLTIP")}
                    toolTipEditText={t("COMPANIES.EDIT_TOOLTIP")}
                    handleEdit={(index: number) => {
                        if (items[index]) {
                            updateViewState({
                                showPage: "edit",
                                selectedItem: selectedItem?.uid === items[index]?.uid ? selectedItem : items[index],
                            })
                        }
                    }}
                />
            </Paper>
        </ListContainer>
    )
}

export default ListCompany
