import { SxProps, TextField, TextFieldProps, Typography } from "@mui/material"
import { ChangeEventHandler, HTMLInputTypeAttribute, ReactNode, Ref, forwardRef } from "react"
import { defaultSx } from "./style"

interface BaseInputProps {
    sx?: SxProps
    label?: string
    value?: HTMLInputTypeAttribute
    onChange?: ChangeEventHandler
    size?: "small" | "medium"
    error?: boolean
    className?: string
    multiline?: boolean
    noBottomBorder?: boolean
    disabled?: boolean
    showLabelStar?: boolean
    id?: string
    helperText?: string
    inputProps?: any
    register?: any
    /**
     * helper text position
     */
    helperTextPosition?: AlignSetting
    /**
     * Input type
     */
    type?: "email" | "password" | "text" | "tel" | "range" | "radio" | "number"
    /**
     * Icon of any element placed with the input at the left side
     */
    startAdornment?: ReactNode
    /**
     * default value
     */
    defaultValue?: string
    onFocus?: () => void
    onBlur?: () => void
    /**
     * Max length
     */
    max?: number
    /**
     * Min length
     */
    min?: number
    inputRef?: Ref<HTMLInputElement>
    required?: boolean
    autoComplete?: string
    shrink?: boolean
    onInput?: ChangeEventHandler
    hiddenLabel?: boolean
    focused?: boolean
    autoFocus?: boolean
    endAdornment?: ReactNode
    descriptionBottom?: string
}

const BaseInput = forwardRef((props: TextFieldProps & BaseInputProps, ref) => {
    const {
        sx,
        label,
        value,
        className,
        onChange,
        size,
        max,
        multiline,
        error,
        noBottomBorder,
        disabled,
        id,
        type,
        startAdornment,
        onFocus,
        defaultValue,
        showLabelStar,
        onBlur,
        register,
        inputRef,
        required = false,
        autoComplete,
        shrink,
        onInput,
        helperTextPosition,
        hiddenLabel = false,
        inputProps = {},
        focused,
        autoFocus,
        endAdornment,
        descriptionBottom,
        ...rest
    } = props

    return (
        <>
            <TextField
                value={value}
                onChange={onChange}
                onInput={onInput}
                size={size}
                focused={focused}
                autoFocus={autoFocus}
                hiddenLabel={hiddenLabel}
                label={
                    hiddenLabel ? null : (
                        <Typography
                            component="span"
                            sx={{
                                color: "action.active",
                                ".Mui-disabled &": { color: "text.disabled" },
                                ".Mui-error &": { color: "error.main" },
                            }}
                        >
                            {label}
                        </Typography>
                    )
                }
                ref={ref}
                error={!!error}
                variant="filled"
                className={className}
                multiline={multiline}
                disabled={disabled}
                type={type}
                id={id}
                autoComplete={autoComplete}
                inputProps={{ maxLength: max, ...inputProps }}
                defaultValue={defaultValue}
                InputProps={{
                    startAdornment,
                    endAdornment,
                }}
                onBlur={onBlur}
                onFocus={onFocus}
                helperText={
                    !descriptionBottom ? (
                        ""
                    ) : (
                        <Typography component="span" sx={{ color: "action.active" }}>
                            {descriptionBottom}
                        </Typography>
                    )
                }
                inputRef={inputRef}
                required={required}
                sx={{
                    ...defaultSx({ noBottomBorder, showLabelStar, error }),
                    ...sx,
                }}
                FormHelperTextProps={{ sx: { textAlign: helperTextPosition, color: "action.active" } }}
                {...(shrink && { InputLabelProps: { shrink: true } })}
                {...(register || {})}
                {...rest}
            />
        </>
    )
})

export default BaseInput
