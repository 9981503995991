import Icon from "@/components/base/Icon"
import useDeviceCheck from "@/hooks/useDeviceCheck"
import useLocationStore from "@/store/location"
import { Button } from "@mui/material"
import { useState } from "react"
import QRcodeStandaloneModal from "./modal"

const QRcodeStandalone = () => {
    const { businesses } = useLocationStore()

    const [openModal, setOpenModal] = useState(false)
    const { isWebView } = useDeviceCheck()

    if (!isWebView || !businesses?.length) return
    return (
        <>
            <Button
                sx={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    outline: "none",
                    background: "none!important",
                    color: "inherit",
                    "& svg": {
                        width: 80,
                        height: 80,
                        color: "inherit",
                    },
                }}
                onClick={() => setOpenModal(true)}
            >
                <Icon name="circleQRCode"></Icon>
            </Button>
            {businesses && <QRcodeStandaloneModal open={openModal} onClose={() => setOpenModal(false)} />}
        </>
    )
}

export default QRcodeStandalone
