import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(() => ({
    pagination: {
        display: "flex",
        justifyContent: "flex-end",
        "& .bloc-review": {
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 16,
            "&:hover": {
                cursor: "pointer",
            },
        },
        "& .MuiToolbar-root": {
            gap: 26,
        },
        "& .table-pagination-actions-wrapper": {
            display: "flex",
            "& button": {
                padding: 12,
            },
        },
    },
}))

export default useStyles
