import BaseInputPhone from "@/components/base/BaseInputPhone"
import Icon from "@/components/base/Icon"
import BaseInput from "@/components/base/input"
import { TitleWrapper } from "@/components/base/title-wrapper"
import EditContactContainer from "@/components/features/business/form-container/edit/edit-contact"
import FormAction from "@/components/features/business/form-container/edit/form-action"
import useLogic from "@/hooks/businesses/edit-contact/useLogic"
import { PageProps } from "@/types/page"
import { Box, Paper, Typography } from "@mui/material"
import { Controller } from "react-hook-form"

const EditContact = ({ open, onClose }: PageProps) => {
    const {
        handlePreviousButton,
        t,
        countryPhone,
        errors,
        setValueChanged,
        valueChanged,
        errorsFrom,
        control,
        handleCancelButton,
        handlePhoneChange,
        langOfCountryName,
        handleSubmit,
        onSubmit,
        isSubmitting,
        isLoading,
    } = useLogic()

    return (
        <EditContactContainer open={open} onClose={onClose}>
            <TitleWrapper handlePreviousButton={handlePreviousButton} title={t("BUSINESSES.EDIT_CONTACT.TITLE.EDIT")} />
            <form className="inputs-container" onSubmit={handleSubmit(onSubmit)}>
                <Paper>
                    <Typography variant="h2" className="title">
                        <Icon name="user1" className="title-icon" />
                        {t("BUSINESSES.EDIT_CONTACT.TITLE.OVERVIEW")}
                    </Typography>
                    <Box className="inputs-wrapper">
                        <BaseInputPhone
                            control={control}
                            inputRef={(input: HTMLInputElement) => {
                                if (input && !errors.secondPhone && errors.mainPhone) {
                                    input.focus()
                                }
                            }}
                            name="mainPhone"
                            label={t("BUSINESSES.EDIT_CONTACT.BUSINESS_OTHER_PHONE")}
                            onlyCountries={["CA", "US", "FR", "BE"]}
                            lang={langOfCountryName}
                            error={errors.mainPhone}
                            defaultCountry={countryPhone.mainPhone}
                            onChange={(value) => handlePhoneChange(value, "mainPhone")}
                            errorLabel={
                                errors.mainPhone && !valueChanged && t("BUSINESSES.EDIT_MAIN_INFO.PHONE_INVALID")
                            }
                        />
                        <BaseInputPhone
                            control={control}
                            inputRef={(input: HTMLInputElement) => {
                                if (input && !errors.mainPhone && errors.secondPhone) {
                                    input.focus()
                                }
                            }}
                            name="secondPhone"
                            label={t("BUSINESSES.EDIT_CONTACT.BUSINESS_OTHER_PHONE")}
                            onlyCountries={["CA", "US", "FR", "BE"]}
                            lang={langOfCountryName}
                            error={errors.secondPhone}
                            defaultCountry={countryPhone.secondPhone}
                            onChange={(value) => handlePhoneChange(value, "secondPhone")}
                            errorLabel={
                                errors.secondPhone && !valueChanged && t("BUSINESSES.EDIT_MAIN_INFO.PHONE_INVALID")
                            }
                        />
                        <Controller
                            render={({ field }) => (
                                <BaseInput
                                    {...field}
                                    inputRef={(input: HTMLInputElement) => {
                                        if (input && errors.email && isSubmitting && errorsFrom[0] === "email") {
                                            input.focus()
                                        }
                                    }}
                                    onChange={(e) => {
                                        field.onChange(e)
                                        setValueChanged(true)
                                    }}
                                    name="email"
                                    label={t("BUSINESSES.EDIT_CONTACT.EMAIL")}
                                    error={errors.email}
                                    helperText={errors.email && !valueChanged && t("USERS.INVALID_FORMAT_EMAIL")}
                                />
                            )}
                            control={control}
                            name="email"
                        />
                    </Box>
                </Paper>
                <FormAction
                    handleCancel={handleCancelButton}
                    isDisabled={isSubmitting}
                    isLoading={isLoading}
                    isUpdating
                />
            </form>
        </EditContactContainer>
    )
}

export default EditContact
