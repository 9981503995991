import { List, Stack } from "@mui/material"
import { useMemo } from "react"
import { SkeletonHeaderBtn } from "../header-btn-left-right"
import { SkeletonNumberItemStat } from "./bloc-number-stat"
import { SkeletonChart } from "./chart"
import { useStyles } from "./styles"
import { v4 as uuidv4 } from "uuid"
import { SkeletonTable } from "./table"

export const SkeletonStat = ({ type }: { type: string }) => {
    const { classes } = useStyles()

    const colors = useMemo(() => {
        if (type === "local_presence") {
            return ["#2D97D3", "#296ED7", "#2B44CB"]
        }
        if (type === "reputation") {
            return ["#FBC02C", "#F9A824", "#F57F17"]
        }
        return []
    }, [type])

    const chartNumber = useMemo(() => {
        switch (type) {
            case "reputation":
            case "local_presence":
                return 3
            case "request_opinion":
                return 2
            default:
                break
        }
        return 0
    }, [type])

    return (
        <Stack gap={2} className={classes.statistic}>
            <SkeletonHeaderBtn />
            {type !== "request_opinion" && (
                <List className="bloc-number-stat">
                    {[...Array(3)].map((val, key: number) => (
                        <SkeletonNumberItemStat key={uuidv4()} color={colors[key]} />
                    ))}
                </List>
            )}
            <SkeletonTable />
            {[...Array(chartNumber)].map(() => (
                <SkeletonChart key={uuidv4()} />
            ))}
        </Stack>
    )
}
