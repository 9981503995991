import { SxProps, Theme } from "@mui/material/styles"

export const modalContainer: SxProps<Theme> = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    bgcolor: "rgba(0, 0, 0, 0.5)", // Background semi-transparent
}

export const paperStyle: SxProps<Theme> = {
    width: "500px",
    borderRadius: 2,
    position: "relative",
}

export const closeButton: SxProps<Theme> = {
    position: "absolute",
    top: 8,
    right: 8,
}

export const titleStyle: SxProps<Theme> = {
    fontWeight: 500,
}

export const subtitleStyle: SxProps<Theme> = {
    color: "text.secondary",
}

export const textFieldStyle: SxProps<Theme> = {}

export const cancelButton: SxProps<Theme> = {
    bgcolor: "#0180FF1F",
    "&:hover": { bgcolor: "action.hover" },
    border: "none",
}

export const saveButton: SxProps<Theme> = {
    flex: 1,
}
