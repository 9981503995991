import { Paper, Skeleton, Stack } from "@mui/material"
import { v4 as uuidv4 } from "uuid"
import SkeletonCampaignOverviewsItem from "./item"
import useStyles from "./styles"

const SkeletonCampaignOverviews = () => {
    const { classes } = useStyles()
    return (
        <Paper elevation={0} className={classes.blocReviews}>
            <Skeleton variant="rectangular" width={280} height={32} sx={{ minWidth: "72px" }} />

            <Stack className="bloc-review">
                {[...Array(9)].map(() => (
                    <SkeletonCampaignOverviewsItem key={uuidv4()} />
                ))}
            </Stack>
            <Stack alignItems={"end"}>
                <Skeleton
                    variant="rectangular"
                    width={"200px"}
                    height={34}
                    sx={{ minWidth: "72px", marginTop: "16px" }}
                />
            </Stack>
        </Paper>
    )
}

export default SkeletonCampaignOverviews
