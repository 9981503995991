import BaseInput from "@/components/base/input"
import { MAX_INPUT } from "@/data/limiter"
import useLogic from "@/hooks/users/form/useLogic"
import { SelectType } from "@/types/select"
import { Box, Paper, Typography } from "@mui/material"
import { Controller } from "react-hook-form"
import BusinessAutocomplete from "@/components/widget/autocomplete/business-autocomplete"
import FormAction from "@/components/base/form-action"
import SelectBaseController from "@/components/widget/select/select-base-controller"
import SelectCheckboxController from "@/components/widget/select/select-checkbox-controller"

const UserForm = ({ callApi, onCancelForm, isUpdate = false }) => {
    const {
        control,
        MESSAGE_TEXT,
        mailError,
        roles,
        isAutoEdit,
        formValue,
        listItemsAutocomplete,
        loading,
        profile,
        errors,
        isValid,
        handleSubmit,
        onSubmit,
        register,
        handeCancelButton,
        setMailError,
        setValue,
    } = useLogic({
        isUpdate,
        callApi,
        onCancelForm,
    })

    return (
        <form onSubmit={handleSubmit(onSubmit)} id="create-user">
            <Box className="inputs-container container-row">
                <Paper className="inputs-wrapper">
                    <Typography component="h2">{MESSAGE_TEXT.MAIN_INFO}</Typography>
                    <Box>
                        <Controller
                            render={({ field }) => (
                                <BaseInput
                                    max={MAX_INPUT.TEXT}
                                    showLabelStar
                                    data-testid="firstname"
                                    label={MESSAGE_TEXT.USER_FIRST_NAME}
                                    register={register("firstname")}
                                    required
                                    error={Boolean(errors?.firstname)}
                                    helperText={errors?.firstname?.message}
                                    {...field}
                                />
                            )}
                            control={control}
                            name="firstname"
                        />
                        <Controller
                            render={({ field }) => (
                                <BaseInput
                                    max={MAX_INPUT.TEXT}
                                    showLabelStar
                                    data-testid="lastname"
                                    label={MESSAGE_TEXT.USER_LAST_NAME}
                                    register={register("lastname")}
                                    required
                                    error={Boolean(errors?.lastname)}
                                    helperText={errors?.lastname?.message}
                                    {...field}
                                />
                            )}
                            control={control}
                            name="lastname"
                        />
                        <Controller
                            render={({ field }) => (
                                <BaseInput
                                    max={MAX_INPUT.TEXT}
                                    showLabelStar
                                    label={MESSAGE_TEXT.USER_EMAIL}
                                    register={register("email")}
                                    error={!!mailError || Boolean(errors?.email)}
                                    data-testid="email"
                                    helperText={mailError || errors?.email?.message}
                                    type="email"
                                    required
                                    multiline
                                    disabled={isUpdate}
                                    onChange={() => {
                                        setMailError("")
                                    }}
                                    {...field}
                                />
                            )}
                            control={control}
                            name="email"
                        />
                    </Box>
                </Paper>
                <Paper className="inputs-wrapper">
                    <Typography component="h2">{MESSAGE_TEXT.ROLE_TITLE}</Typography>
                    <Box>
                        <SelectBaseController
                            name="role"
                            control={control}
                            options={roles}
                            label={MESSAGE_TEXT.ROLE}
                            error={Boolean(errors?.role)}
                            disabled={isAutoEdit}
                            required
                            onSelectionChange={() => {
                                setValue("roleByAdminType", [])
                            }}
                        />
                        {formValue?.role === "admin_company" && (
                            <SelectCheckboxController
                                onClear={() => {
                                    setValue("roleByAdminType", [])
                                }}
                                control={control}
                                value={formValue?.roleByAdminType || []}
                                name="roleByAdminType"
                                required
                                label={MESSAGE_TEXT.COMPANY_ROLE}
                                options={
                                    listItemsAutocomplete?.map((item: any) => ({
                                        value: item.value,
                                        label: item.label,
                                        description: item?.shortAddress,
                                    })) || []
                                }
                                error={Boolean(errors.roleByAdminType)}
                                disabled={isAutoEdit}
                            />
                        )}
                        {formValue?.role === "admin_location" && (
                            <Controller
                                render={({ field }) => (
                                    <BusinessAutocomplete
                                        defaultValue={field?.value}
                                        label={MESSAGE_TEXT.BUSINESS_ROLE}
                                        selectionChange={(ids: SelectType[]) => {
                                            field.onChange(ids.map((item) => item.value))
                                        }}
                                    />
                                )}
                                control={control}
                                name="roleByAdminType"
                            />
                        )}
                    </Box>
                </Paper>
            </Box>
            <FormAction
                handleCancel={handeCancelButton}
                isDisabled={loading || !profile || !isValid}
                isLoading={loading}
                isUpdating={isUpdate}
            />
        </form>
    )
}

export default UserForm
