import { SxProps } from "@mui/material"
import { makeStyles } from "tss-react/mui"

export const menuSx: SxProps = {
    "& .MuiBackdrop-root": { opacity: "0 !important" },
    "& .MuiPaper-root": {
        padding: 1,
        "& ul": {
            display: "flex",
            flexDirection: "column",
            gap: 1,
            padding: 0,
            "& li": {
                padding: 0,
                gap: 1,
                fontSize: 15,
                minHeight: 26,
                "& span": { height: 26 },
            },
        },
    },
}

export const useStyles = makeStyles()((theme) => ({
    boxBtn: {
        right: "16px",
        "@media (max-width: 767px)": {
            position: "absolute",
        },
    },
}))
