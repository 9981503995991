import { CookiesService } from "@/services"
import { ResponseMetadata } from "@/types/api"
import { LocationType } from "@/types/businesses"
import { CompanyInfo } from "@/types/companies"
import { ApiService, DispatchrService } from "../"
import { ErrorResponse } from "../base"

interface FetchLocationsProps {
    page_number?: number
    page_size?: number
    user_uid: string
    fields?: string[]
    search?: string
    completion?: string
    sort?: string
    order?: "asc" | "desc"
    missing_info_required?: boolean
}

interface FetchLocationsList {
    metadata?: ResponseMetadata
    data?: LocationType[] & CompanyInfo[]
}

interface FetchLocationsWithFieldsProps {
    user_uid: string
    fields: string[]
}

interface FetchLocationsStatusProps {
    user_uid: string
}

interface FetchLocationsStatusResponse {
    data?: {
        missingRequiredInfo: number
        lowScore: number
        midScore: number
        highScore: number
    }
}

interface FetchIgAccountsResponse {
    ig_id: string
    ig_username: string
    access_id: string
}

class LocationsApi {
    fetchLocations = async ({
        page_number,
        page_size,
        user_uid,
        sort = "name",
        order = "asc",
        search,
        missing_info_required,
        completion,
        fields,
    }: FetchLocationsProps): Promise<FetchLocationsList & Partial<ErrorResponse>> => {
        const query: any = {
            _page: page_number,
            _limit: page_size,
            _sort: sort,
            _order: order,
            user_uid,
            name: search,
            reseller_uid: CookiesService.get("resellerUid"),
        }
        if (completion) {
            query.completion = completion
        }
        if (missing_info_required) {
            query.missing_info_required = missing_info_required
        }
        if (fields) {
            query.fields = fields.join(",")
        }
        return ApiService.get(`/locations`, query)
    }

    getALocation = async ({ id, user_uid }) => {
        return DispatchrService.get(`/location/${id}`, { user_uid })
    }

    fetchLocationsWithFields = async ({
        user_uid,
        fields,
    }: FetchLocationsWithFieldsProps): Promise<FetchLocationsList & Partial<ErrorResponse>> => {
        return ApiService.get(`/locations`, {
            user_uid,
            reseller_uid: CookiesService.get("resellerUid"),
            fields: fields?.join(","),
        })
    }

    fetchLocationsStatus = async ({
        user_uid,
    }: FetchLocationsStatusProps): Promise<FetchLocationsStatusResponse & Partial<ErrorResponse>> => {
        return ApiService.get(`/locations/status`, {
            user_uid,
            reseller_uid: CookiesService.get("resellerUid"),
        })
    }

    fetchIgAccounts = async ({
        company_uid,
        extract = false,
    }: {
        company_uid: string
        extract?: boolean
    }): Promise<FetchIgAccountsResponse[] & Partial<ErrorResponse>> => {
        return ApiService.get(`/ig-account`, {
            company_uid,
            ...(extract ? { isExtract: true } : {}),
        })
    }

    deleteLocation = async (location_id: string): Promise<Partial<ErrorResponse>> => {
        return DispatchrService.delete(`/location/${location_id}`)
    }
}

export default new LocationsApi()
