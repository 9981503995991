import { SocialService } from "@/services"
import CampaignStore from "@/store/campaign"
import useLocationStore from "@/store/location"
import UiPageSessionStore from "@/store/ui-page-session"
import { zodResolver } from "@hookform/resolvers/zod"
import dayjs from "dayjs"
import { useMemo } from "react"
import { useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"

const useCampaignForm = () => {
    const { t } = useTranslation()
    const { previousItem } = UiPageSessionStore()
    const { businesses } = useLocationStore()
    const { contacts } = CampaignStore()

    const MESSAGE_TEXT = useMemo(() => {
        return {
            FIELD_REQUIRED: t("USERS.FIELD_REQUIRED"),
            INVALID_FORMAT_EMAIL: t("USERS.INVALID_FORMAT_EMAIL"),
        }
    }, [t])

    const schema = z
        .object({
            business: z.string().nonempty(MESSAGE_TEXT.FIELD_REQUIRED),
            type: z.string(),
            firstname: z.string().optional(),
            lastname: z.string().optional(),
            email: z.string().optional(),
            phone: z.string().optional(),
            confirmation: z.boolean(),
            subject: z.string().optional(),
            message: z.string().optional(),
            step: z.number(),
            feedback: z.boolean(),
            country: z.string().nullable().optional(),
            lang: z.string(),
            hour: z.string().optional(),
            date: z.string().optional(),
            isScheduled: z.boolean().optional(),
        })
        .superRefine(({ type, phone, email, isScheduled, hour, date, business }, refinementContext) => {
            const isScheduledAtDateNotValid = isScheduled && (!date || !hour)

            if (contacts.length === 0) {
                if (type === "sms" && !phone) {
                    return refinementContext.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: MESSAGE_TEXT.FIELD_REQUIRED,
                        path: ["phone"],
                    })
                }
                if (type === "email" && !email) {
                    return refinementContext.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: MESSAGE_TEXT.FIELD_REQUIRED,
                        path: ["email"],
                    })
                }
            }

            if (isScheduledAtDateNotValid) {
                refinementContext.addIssue({
                    code: "custom",
                    path: ["date", "hour"],
                    message: "hour and date is required if add date is checked",
                })
                return true
            }

            if (isScheduled && date !== "") {
                const dateToPublish = dayjs(`${date}T${hour}:00`).format("YYYY-MM-DDTHH:mm:ss")
                const scheduledAtWithUtc = dayjs(dateToPublish)

                const today = SocialService.getMaxDateByLocationIds([business], businesses)
                const formattedDay = dayjs(today).format("YYYY-MM-DDTHH:mm:ss")

                if (scheduledAtWithUtc.isBefore(formattedDay, "day")) {
                    refinementContext.addIssue({
                        code: "custom",
                        path: ["date"],
                        message: "date is anterior to today",
                    })
                    return true
                }
                if (scheduledAtWithUtc.isBefore(formattedDay)) {
                    refinementContext.addIssue({
                        code: "custom",
                        path: ["hour"],
                        message: "hour is anterior",
                    })
                    return true
                }
            }
        })

    type SchemaValuesType = z.infer<typeof schema>
    const defaultValues = useMemo(() => {
        let data = {
            business: "",
            type: "sms",
            message: "",
            step: 0,
            feedback: true,
            country: "CA",
            firstname: "",
            lastname: "",
            lang: "en",
            date: dayjs().format("YYYY-MM-DD"),
            hour: "",
        }

        if (previousItem?.campaign) {
            data = previousItem?.campaign
        }

        return data
    }, [previousItem])

    const { handleSubmit, formState, control, setValue, register, reset, trigger } = useForm<SchemaValuesType>({
        defaultValues,
        resolver: zodResolver(schema),
        mode: "onTouched",
    })

    const formValue = useWatch({ control })

    return {
        formValue,
        formState,
        control,
        handleSubmit,
        setValue,
        register,
        reset,
        trigger,
    }
}

export default useCampaignForm
