import { FeedbackService } from "@/api"
import Icon from "@/components/base/Icon"
import { QrCodeComponent } from "@/components/base/qr-code"
import CustomTooltip from "@/components/base/Tooltip"
import { Box, Button, Dialog, Divider, FormLabel, IconButton, Stack, Switch, Typography } from "@mui/material"
import { PDFDownloadLink } from "@react-pdf/renderer"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { BlocMessage } from "../../bloc-message"
import { LocationLogo } from "../../location-logo"
import { modalQR } from "../styles"
import { PDFDocument } from "./pdf-qr-code"

export const QRCodeLinkDetails = ({ open, handleClose, qrValue, handleDownloadQRCode, location, fileName }) => {
    const { t } = useTranslation()
    const [activeFilter, setActiveFilter] = useState<boolean>(false)
    const [text, setText] = useState<string>(t("QR_CODE_LINK.MODAL.DEFAULT_MESSAGE"))
    const [qrCode, setQrCode] = useState<string>("")

    useEffect(() => {
        if (qrValue) setActiveFilter(qrValue.feedback)
    }, [qrValue])

    const generateQRCode = useCallback((value: string) => {
        setQrCode(value)
    }, [])

    const handleChangeFilter = () => {
        const linkValue: string = qrValue?.value ? qrValue.value.split("/").pop() || "" : ""

        FeedbackService.updateFeedBack({ status: !activeFilter, link: linkValue })
            .then(() => {
                setActiveFilter((prev) => !prev)
            })
            .catch(console.error)
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-remove"
            scroll="body"
            PaperProps={{
                elevation: 6,
                sx: { ...modalQR },
            }}
            data-testid="modal-qr-code"
        >
            <Stack className="bloc-modal">
                <Stack padding={2} gap={1}>
                    <Box className="title-wrapper">
                        <Typography id="modal-modal-title" variant="h2">
                            {t("QR_CODE_LINK.MODAL.TITLE_QR")}
                        </Typography>
                        <CustomTooltip title={t("USERS.CLOSE")}>
                            <IconButton data-testid="close-modal-delete" onClick={handleClose}>
                                <Icon name="x" />
                            </IconButton>
                        </CustomTooltip>
                    </Box>
                    <Stack
                        className="bloc-switch"
                        flexDirection={"row"}
                        alignItems={"flex-start"}
                        justifyContent={"flex-start"}
                        gap={1}
                    >
                        <Switch checked={activeFilter} onChange={handleChangeFilter} />
                        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                            <FormLabel sx={{ color: "action.active" }}>
                                {t("QR_CODE_LINK.MODAL.ACTIVE_FILTER_REVIEW")}
                            </FormLabel>
                            <CustomTooltip title={t("CAMPAIGN.FILTER")}>
                                <IconButton className="edit-btn">
                                    <Icon name="info" sx={{ color: "action.active" }} />
                                </IconButton>
                            </CustomTooltip>
                        </Stack>
                    </Stack>
                </Stack>
                <Divider />
                <Stack padding={2} justifyContent={"center"} alignItems={"center"} gap={4}>
                    <BlocMessage maxLength={100} text={text} setText={setText} />
                    <QrCodeComponent qrValue={qrValue?.value ?? ""} onGenerateQrCode={generateQRCode} />
                    <LocationLogo src={location?.logo || ""} width={76} height={76} />
                </Stack>
                <Divider />
                <Stack gap={2} padding={2}>
                    <PDFDownloadLink
                        document={<PDFDocument qrDataUrl={qrCode} text={text} logo={location?.logo} />}
                        fileName={`${fileName}.pdf`}
                    >
                        <Button variant="text" sx={{ fontSize: "16px", width: "100%" }} data-testid="btn-download-pdf">
                            {t("QR_CODE_LINK.MODAL.DOWNLOAD_QR_CODE_AND_MESSAGE")}
                        </Button>
                    </PDFDownloadLink>
                    <Button variant="contained" onClick={handleDownloadQRCode} data-testid="btn-download-image">
                        <Typography data-testid="btn-qr-code" component="span" variant="body1">
                            {t("QR_CODE_LINK.MODAL.DOWNLOAD_QR_CODE")}
                        </Typography>
                    </Button>
                </Stack>
            </Stack>
        </Dialog>
    )
}
