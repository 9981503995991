import useBusinessLogic from "@/hooks/businesses/useBusinessLogic"
import { Alert, AlertTitle, Paper, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import OverviewTitle from "../title"
import SerializedString from "@/components/widget/serialized-string"

const OverviewAttribute = ({ attributRef, data }: { attributRef?: any; data: any }) => {
    const { navigateTo } = useBusinessLogic()
    const { t } = useTranslation()

    return (
        <Paper
            ref={attributRef}
            elevation={2}
            className="clickable"
            id="attributs"
            data-testid="attributs"
            data-e2e="attribute-business"
            onClick={() => navigateTo("edit-attribute")}
        >
            <OverviewTitle
                title={t("BUSINESSES.ATTRIBUTE_TITLE")}
                // icon="bulletList"
                data-testid="title"
                isSecondTitle
                tooltipText={t("BUSINESSES.EDIT_ATTRIBUTES.TITLE_TOOLTIP")}
            />

            {data && (
                <>
                    {data === "ATTRIBUTE_UNAVAILABLE" && (
                        <Alert severity="error" variant="standard" sx={{ flexDirection: "row", gap: 1 }}>
                            <Stack>
                                <AlertTitle
                                    sx={{
                                        marginBottom: 0,
                                    }}
                                >
                                    {t("BUSINESSES.EDIT_ATTRIBUTES.DISPLAY_IMPOSSIBLE")}
                                </AlertTitle>
                                <Stack gap={1}>
                                    <Typography variant="body2">
                                        {t("BUSINESSES.EDIT_ATTRIBUTES.DISPLAY_IMPOSSIBLE_DESC")}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Alert>
                    )}
                    {data !== "ATTRIBUTE_UNAVAILABLE" && <SerializedString text={data} />}
                </>
            )}
        </Paper>
    )
}

export default OverviewAttribute
