import logoDilypse from "@/assets/images/logo-dilypse.png"
import Icon from "@/components/base/Icon"
import BaseInput from "@/components/base/input"
import useStyles from "./styles"
import useAuthGoogle from "@/hooks/oauth02/useAuthGoogle"
import useDeviceCheck from "@/hooks/useDeviceCheck"
import useNotification from "@/hooks/useNotification"
import { NotificationService, UserSessionService } from "@/services"
import UiService from "@/services/ui"
import ResellerStore from "@/store/reseller"
import UiStore from "@/store/ui"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { zodResolver } from "@hookform/resolvers/zod"
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material"
import { GoogleLogin } from "@react-oauth/google"
import { useCallback, useEffect, useState } from "react"
import { Controller, useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { z } from "zod"

const LoginPage = () => {
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const { classes } = useStyles()
    const { notif } = useNotification()
    const [showMailAdornment, setShowMailAdornment] = useState(false)
    const [showPasswordAdornment, setShowPasswordAdornment] = useState(false)
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { preferredLanguage } = UiStore()
    const { resellerLogo, resellerName, googleIdentityClientId } = ResellerStore()
    const { isConnected } = UserSessionStore()
    const { updateState } = UiPageSessionStore()
    const [searchParams] = useSearchParams()

    const schema = z.object({
        email: z.string().email(t("USERS.INVALID_FORMAT_EMAIL")),
        password: z.string().min(8, "").max(1000, ""),
    })

    const { control, handleSubmit } = useForm({
        defaultValues: {
            email: "",
            password: "",
        },
        resolver: zodResolver(schema),
        mode: "onSubmit",
    })

    const formValue = useWatch({ control })
    const loginFlow = useCallback(
        async (email = "", password = "", authType = "default") => {
            const { success, error } = await UserSessionService.login(email, password, authType)
            if (error) {
                if (error === "LOGIN_ERROR.NEED_RESET_PASSWORD") {
                    sessionStorage.setItem("first_time_login_email", email)
                    window.location.href = "/forgot-password?is_first_time_login=1"
                } else {
                    setError(t(`${error}`))
                }
            }
            setLoading(false)
            if (success) {
                const redirectToValue = searchParams.get("redirectTo")
                const redirectTo = redirectToValue ? decodeURIComponent(redirectToValue) : null

                NotificationService.showNewVersion(false)

                if (redirectTo) {
                    window.location.href = decodeURIComponent(redirectTo)
                } else {
                    window.location.href = "/reputation"
                }
            }
        },
        [navigate, searchParams, t, updateState]
    )

    const onSubmit = async () => {
        setLoading(true)
        setError("")
        if (!isConnected) {
            setError(t("SYSTEM_ERROR.INVALID_REQUEST"))
            setLoading(false)
        } else {
            loginFlow(formValue?.email, formValue?.password)
        }
    }

    const handleLoginRedirectGoogle = useCallback(
        async (access_token: string) => {
            if (googleIdentityClientId && access_token) {
                setLoading(true)
                setError("")
                loginFlow(googleIdentityClientId, access_token, "google")
            }
        },
        [loginFlow, googleIdentityClientId]
    )

    const { openGoogleAccount } = useAuthGoogle()
    const { isMobile, isTablet } = useDeviceCheck()

    useEffect(() => {
        UiService.setState({ title: "LOGIN.TITLE" })
        const access_token = searchParams.get("access_token")
        if (access_token) {
            handleLoginRedirectGoogle(access_token)
        }
    }, [handleLoginRedirectGoogle, searchParams])

    return (
        <Box className={classes.loginWrapper}>
            {resellerLogo && (
                <img data-testid="logo" src={resellerLogo || logoDilypse} alt={resellerName || "Dilypse"} />
            )}
            <Stack gap={1}>
                <Typography data-testid="title" variant="h2" color="text.primary" maxWidth={362} align="center">
                    {t("LOGIN.WELCOME_TO_NEW_VERSION")}
                </Typography>
                <Typography data-testid="title" variant="body1" color="text.secondary">
                    {t("LOGIN.TITLE")}
                    {""}
                </Typography>
            </Stack>
            {preferredLanguage &&
                (isMobile || isTablet ? (
                    <Button
                        onClick={() => {
                            openGoogleAccount()
                        }}
                        fullWidth
                        sx={{
                            border: "1px solid #dadce0",
                            display: "flex",
                            justifyContent: "start",
                            maxWidth: "360px",
                            backgroundColor: "white",
                            color: "#3c4043",
                            "&:hover": {
                                backgroundColor: "white",
                            },
                            padding: "8px 22px",
                        }}
                    >
                        <Icon name="google" />
                        <Box sx={{ mx: "auto" }}>{t("LOGIN.SIGNIN_WITH_GOOGLE")}</Box>
                    </Button>
                ) : (
                    <GoogleLogin
                        onSuccess={(credential) => {
                            handleLoginRedirectGoogle(credential.credential)
                        }}
                        onError={() => {
                            notif({
                                type: "ERROR",
                                message: t(`LOGIN.GOOGLE_AUTH_FAILED`),
                            })
                        }}
                        size="large"
                        auto_select={false}
                        context="signin"
                        locale={preferredLanguage}
                        width={"342"}
                    />
                ))}

            <Stack
                className="separator"
                flexDirection={"row"}
                justifyContent={"center"}
                sx={{
                    maxWidth: "360px",
                    width: "360px",
                    position: "relative",
                    "&:before": {
                        content: '""',
                        position: "absolute",
                        top: "12px",
                        left: 0,
                        height: "2px",
                        width: "100%",
                        backgroundColor: "rgba(91, 125, 216, 0.16)",
                        zIndex: 0,
                    },
                    "& p": {
                        width: "57px",
                        backgroundColor: "white",
                        display: "block",
                        position: "relative",
                        zIndex: 2,
                        color: "action.active",
                    },
                    "@media(max-width: 425px)": {
                        width: "100%",
                    },
                }}
            >
                <Typography>{t("LOGIN.OR")}</Typography>
            </Stack>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Controller
                    name="email"
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field}
                            type="email"
                            label={t("LABEL.EMAIL")}
                            data-testid="email"
                            error={!!fieldState?.invalid}
                            helperText={fieldState?.error?.message}
                            onFocus={() => setShowMailAdornment(true)}
                            onBlur={() => {
                                setShowMailAdornment(false)
                                field.onBlur()
                            }}
                            required
                            startAdornment={
                                (showMailAdornment || field.value) && (
                                    <Icon name="envelope" sx={{ color: "action.active" }} />
                                )
                            }
                        />
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                        <BaseInput
                            {...field}
                            label={t("LABEL.PASSWORD")}
                            type="password"
                            value={formValue?.password}
                            data-testid="password"
                            onFocus={() => setShowPasswordAdornment(true)}
                            onBlur={() => {
                                setShowPasswordAdornment(false)
                                field.onBlur()
                            }}
                            required
                            startAdornment={(showPasswordAdornment || field.value) && <Icon name="lock" />}
                        />
                    )}
                />

                <Link to={"/forgot-password"}>
                    <Typography component={"span"} className="primary">
                        {t("LOGIN.FORGOT")}
                    </Typography>
                </Link>
                {error && (
                    <Typography data-testid="error" component={"span"} className="error">
                        {error}
                    </Typography>
                )}

                <Button
                    variant="contained"
                    data-testid="connect"
                    type="submit"
                    disabled={!(formValue.email.length > 0 && formValue.password.length > 7) || loading}
                >
                    {loading && <CircularProgress size={16} />}
                    {t("BUTTON.BUTTON_CONNECT")}
                </Button>
            </form>
        </Box>
    )
}

export default LoginPage
