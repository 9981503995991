import { FetchKpiInput } from "@/api/statistics"
import { StatisticCampaignService } from "@/services"

import KpiCampaignStore from "@/store/kpi-request-opinion"
import useLocationStore from "@/store/location"
import UiStore from "@/store/ui"
import UserSessionStore from "@/store/user-session"
import { useCallback, useEffect, useMemo } from "react"
import useUiPageSession from "../useUiPageSession"
import { groupByKey } from "@/utils"

const useLogic = () => {
    const {
        graph,
        kpi,
        payloadFilter,
        update,
        selectedBusinesses,
        selectedPeriod,
        loading,
        updatePayload,
        updatePeriod,
    } = KpiCampaignStore()
    const { profile } = UserSessionStore()
    const { businesses } = useLocationStore()
    const { preferredLanguage } = UiStore()
    useUiPageSession({
        title: "REQUEST_OPINION.TITLE",
        page: "request_opinion",
    })

    const handleSelectionChange = useCallback((items) => {
        const filteredLocationIds = items?.map((item) => item.value) || []
        if (filteredLocationIds?.length > 0) {
            update({
                selectedBusinesses: items,
            })
            updatePayload({ locationsId: filteredLocationIds })
        } else {
            updatePayload({ locationsId: [] })
            update({ selectedBusinesses: [] })
        }
    }, [])

    const handleSelectionPeriodChange = useCallback((item) => {
        let type = "year"
        if (item.value === 1) {
            type = "month"
        } else if (item.value === 0) {
            type = "all"
        }
        updatePayload({ type: type })
        updatePeriod(item)
    }, [])

    const defaultIds = useMemo(async () => {
        if (businesses) {
            return businesses?.map((data) => String(data.value)) || []
        }
        return []
    }, [businesses])

    const businessById = useMemo(() => (businesses ? groupByKey(businesses, "value") : {}), [businesses])

    const fetchKpi = useCallback(
        async (filterParams: Partial<FetchKpiInput>) => {
            update({ loading: true })

            const ids = payloadFilter.locationsId.length > 0 ? payloadFilter.locationsId : await defaultIds
            if (ids?.length > 0) {
                const locationsId = filterParams?.locationsId?.length > 0 ? filterParams.locationsId : ids
                const response = await StatisticCampaignService.fetchKpi({
                    ...payloadFilter,
                    ...filterParams,
                    locationsId,
                })
                update({ graph: response?.graph })
            }

            update({ loading: false })
        },
        [payloadFilter, defaultIds]
    )

    const graphs = useMemo(() => {
        if (graph)
            return {
                feedback: StatisticCampaignService.resolveGraphsFeedback(graph, payloadFilter.type, preferredLanguage),
                appreciationClient: StatisticCampaignService.resolveAppreciationClient(
                    graph,
                    payloadFilter.type,
                    preferredLanguage
                ),
            }
        return {
            feedback: {},
            appreciationClient: {
                totalDislike: 0,
                totalLike: 0,
            },
        }
    }, [graph, payloadFilter.type, preferredLanguage])

    useEffect(() => {
        fetchKpi({})
    }, [fetchKpi])

    return {
        handleSelectionChange,
        handleSelectionPeriodChange,
        kpi,
        profile,
        graph,
        payloadFilter,
        graphs,
        loading,
        selectedBusinesses,
        selectedPeriod,
        listBusinessToCompare: graph?.details,
        businessById,
    }
}

export default useLogic
