import { Card, CardActions, CardContent, CardHeader, styled, SxProps } from "@mui/material"
import { PropsWithChildren, ReactNode } from "react"

type AdvancedCardProps = PropsWithChildren<{
    header?: ReactNode
    subHeader?: ReactNode
    footer?: ReactNode
    sx?: SxProps
    onClick?: () => void
}>

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
}))

const StyledCardActions = styled(CardActions)(({ theme }) => ({
    padding: theme.spacing(0),
    borderTop: `1px solid ${theme.palette.divider}`,
}))

/**
 * An general component for displaying cards
 */
export const AdvancedCard: React.FC<AdvancedCardProps> = ({ header, subHeader, children, footer, sx, onClick }) => {
    return (
        <Card sx={{ padding: 0, gap: 0, ...sx }} variant="elevation" onClick={onClick} data-testid="card-item">
            {header && <StyledCardHeader title={header} subheader={subHeader} />}

            {children && <CardContent>{children}</CardContent>}

            {footer && <StyledCardActions>{footer}</StyledCardActions>}
        </Card>
    )
}
