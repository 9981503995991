import Icon from "@/components/base/Icon"
import BaseInput from "@/components/base/input"
import { TitleWrapper } from "@/components/base/title-wrapper"
import FormContainer from "@/components/features/business/form-container/edit"
import FormAction from "@/components/features/business/form-container/edit/form-action"
import useLogic from "@/hooks/businesses/edit-description/useLogic"
import { PageProps } from "@/types/page"
import { Box, Paper, Typography } from "@mui/material"
import { ReactNode } from "react"
import { Controller } from "react-hook-form"

const EditDescription = ({ onClose, open }: PageProps) => {
    const {
        handlePreviousButton,
        handleSubmit,
        onSubmit,
        onErrors,
        t,
        control,
        handleCancelButton,
        loading,
        description,
    } = useLogic()

    return (
        <FormContainer open={open} onClose={onClose}>
            <TitleWrapper
                handlePreviousButton={handlePreviousButton}
                data-testid="title"
                title={t("BUSINESSES.EDIT_DESCRIPTION.TITLE")}
            />
            <form onSubmit={handleSubmit(onSubmit, onErrors)}>
                <Box className="inputs-container">
                    <Paper className="inputs-wrapper">
                        <Typography variant="h2">
                            <Icon name="file" />
                            {t("BUSINESSES.LONG_DESCRIPTION")}
                        </Typography>
                        <Box className="wrapper">
                            <Controller
                                render={({ field }) => (
                                    <BaseInput
                                        multiline
                                        showLabelStar
                                        required
                                        error={field?.value?.trim()?.length < 10}
                                        helperText={
                                            (
                                                <Box
                                                    component={"span"}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <Typography
                                                        component={"span"}
                                                        color={"action.active"}
                                                        sx={{ fontSize: 12 }}
                                                    >
                                                        {field?.value?.trim()?.length < 10 &&
                                                            t("BUSINESSES.EDIT_DESCRIPTION.MIN_LENGTH")}
                                                    </Typography>

                                                    <Typography
                                                        component={"span"}
                                                        color={"action.active"}
                                                        sx={{ fontSize: 12 }}
                                                    >{`${field?.value?.trim()?.length || "0"}/750`}</Typography>
                                                </Box>
                                            ) as ReactNode & string
                                        }
                                        label={t("BUSINESSES.LONG_DESCRIPTION")}
                                        max={750}
                                        {...field}
                                    />
                                )}
                                control={control}
                                name="description"
                            />
                        </Box>
                    </Paper>
                </Box>
                <FormAction
                    handleCancel={handleCancelButton}
                    isDisabled={loading || (description?.trim()?.length < 10 ? true : false)}
                    isLoading={loading}
                    isUpdating
                />
            </form>
        </FormContainer>
    )
}

export default EditDescription
