import media from "@/services/media"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(() => ({
    blocSection: {
        display: "flex",
        flexDirection: "column",
        "@media (min-width: 768px)": {
            flexDirection: "row",
        },
    },
}))

export default useStyles
