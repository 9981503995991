import { SxProps } from "@mui/material"
import { makeStyles } from "tss-react/mui"
interface StyleSinglePostProps {
    postHeight: string
    hasCover: boolean
    imageCover: number
    status: string
}

interface StylePostProps {
    hasProvider: boolean
}

export const useStyles = makeStyles()((theme) => ({
    imageWrapper: {
        width: 98,
        height: 98,
        position: "relative",
        "img, video": {
            borderRadius: 8,
            objectFit: "cover",
        },
        "& .btn-close": {
            position: "absolute",
            right: "-8px",
            top: "-8px",
            background: "#000000",
            padding: 4,
            svg: {
                width: 16,
                height: 16,
                color: "white",
            },
        },
    },
    btnAction: {
        display: "flex",
        gap: 8,
        flexDirection: "column",

        "@media (min-width: 768px)": {
            flexDirection: "row",
            gap: 16,
        },
        button: {
            width: "100%",
            padding: "8px 22px",
        },
    },
    list: {
        padding: 0,
        display: "flex",
        flexDirection: "column",
        gap: 8,
        "@media (min-width: 1024px)": {
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(calc(100% / 6), 1fr));",
        },
        "@media (min-width: 768px) and (max-width: 1023px)": {
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(calc(100% / 3), 1fr));",
        },
        "& .MuiListItemButton-root": {
            "&:hover": {
                backgroundColor: theme.palette.common.white,
                boxShadow: "0px 4px 5px 0px rgba(0, 4, 31, 0.08), 0px 1px 10px 0px rgba(0, 4, 31, 0.08)",
            },
            "& .text-ellipsis": {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: "100%",
                display: "block",
            },
            "& .text-address": {
                color: theme.palette.action.active,
            },
            "& .content": {
                overflow: "hidden",
                gap: 8,
                display: "flex",

                "& .name": {
                    fontSize: 20,
                },
                "& .address": {
                    display: "flex",
                    gap: 8,
                    "& .eye": {
                        color: theme.palette.action.active,
                    },
                },
                "& .social": {
                    display: "flex",
                    gap: 8,
                    alignItems: "center",
                    flexWrap: "nowrap",
                    "& .not-connected": {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.common.white,
                        padding: "4px 8px 4px 4px",
                        display: "flex",
                        gap: 8,
                        alignItems: "center",
                        width: "fit-content",
                        borderRadius: 40,
                        "& span": {
                            color: theme.palette.common.white,
                        },
                    },
                    "& .reconnected": {
                        backgroundColor: theme.palette.error.main,
                    },
                },
            },
        },
        "& .item-draft": {
            maxHeight: 240,
            padding: 0,
            overflow: "hidden",
            borderRadius: 16,
            gap: 0,
            "& .bloc-description, & .header-content": {
                "&:hover": {
                    cursor: "pointer",
                },
            },
            "& .draft-content": {
                height: "100%",
                justifyContent: "space-between",
                "&:hover": {
                    cursor: "pointer",
                },
            },
        },
    },
    pagination: {
        display: "flex",
        justifyContent: "flex-end",
        "& .bloc-review": {
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 16,
            "&:hover": {
                cursor: "pointer",
            },
        },
        "& .MuiToolbar-root": {
            gap: 26,
        },
        "& .table-pagination-actions-wrapper": {
            display: "flex",
            "& button": {
                padding: 12,
            },
        },
    },
    contentAlert: {
        gap: 16,
        "& .btn-action": {
            width: "100%",
            gap: 16,
            button: {
                width: "100%",
            },
        },
    },
    headerPost: {
        flexDirection: "column",
        justifyContent: "flex-start",
        gap: 8,
        "@media (min-width: 768px)": {
            flexDirection: "row",
            alignItems: "center",
            gap: 16,
        },
        "& .input-business": {
            width: "100%",
            "@media (min-width: 768px)": {
                width: 269,
                "& .MuiAutocomplete-root": {
                    maxWidth: "100%",
                },
            },
            "& .MuiAutocomplete-root": {
                maxWidth: "100%",
            },
        },
    },
    blocTextarea: {
        "& textarea": {
            minHeight: "240px !important",
        },
    },
    contentSuccess: {
        height: "380px",
        textAlign: "center",
        justifyContent: " center",
        gap: 24,
    },
}))

export const useStylesBtnProvider = makeStyles<{ props?: StylePostProps }>()((theme, { props }) => ({
    btnProvider: {
        borderRadius: "100px",
        padding: 8,
        background: props.hasProvider ? theme.palette.grey[50] : "transparent",
    },
}))

export const useStylesSinglePost = makeStyles<{ props?: StyleSinglePostProps }>()((theme, { props }) => ({
    singlePost: {
        flexGrow: 1,
        maxHeight: props.postHeight,
        height: props.postHeight,
        borderRadius: 8,
        overflow: "hidden",
        backgroundSize: "cover",
        cursor: "pointer",
        ...(props.hasCover
            ? { background: `url(${props.imageCover}) no-repeat center center / cover` }
            : { background: theme.palette.grey[50] }),
        "& .draft-title": {
            background: props.status === "DRAFT" ? "#2EAFF7" : "#F46259",
            borderRadius: "8px 8px 0px 0px",
            display: "flex",
            padding: "2px 0px",
            alignItems: "center",
            gap: "8px",
            alignSelf: "stretch",
        },
        "& .bloc-description": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            gap: "6px",
            padding: "8px 4px 2px 4px",
            borderRadius: "0px 0px 8px 8px",
            ...(props.hasCover
                ? { background: "linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 100%)", height: "27px" }
                : {}),
        },
        "& .three-dots": {
            color: props.hasCover ? "white" : "#68738D",
        },
    },
}))

export const modalPost = (maxWidth?: string, type?: string): SxProps => ({
    width: "100%",
    maxWidth: "calc(100% - 16px)",
    marginY: 2,
    marginX: 0,
    display: type === "" ? "none" : "inline-block",
    "@media (max-width: 663.95px)": {
        "&.MuiDialog-paperScrollBody": {
            maxWidth: "calc(100% - 16px)",
        },
    },
    "@media (min-width: 768px)": {
        maxWidth: maxWidth,
    },
    "& .bloc-modal": {
        gap: 2,
        justifyContent: type === "success" ? "center" : "flex-start",
        alignItems: type === "success" ? "center" : "flex-start",
        "& .title-wrapper": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 1,
            width: "100%",
            alignItems: "baseline",
            "& h2": {
                width: "100%",
                fontSize: 24,
                fontWeight: 500,
            },
            "& p": {
                color: "action.active",
                textAlign: "center",
            },
            "& button": {
                padding: 0,
                color: "primary.main",
                top: "4px",
            },
            "& .description": {
                whiteSpace: "wrap",
            },
        },
    },
})
