import { useCallback, useMemo, useState } from "react"
import Icon from "@/components/base/Icon"
import { Box, CircularProgress, IconButton, Menu, MenuItem } from "@mui/material"
import "dayjs/locale/en"
import "dayjs/locale/fr"
import { menuSx, useStyles } from "./styles"
import { useTranslation } from "react-i18next"

export const ActionBtnRight = ({ handleModify, handleCancel, isLoading }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const { t } = useTranslation()
    const { classes } = useStyles()
    const open = useMemo(() => Boolean(anchorEl), [anchorEl])
    const handleClose = useCallback(() => {
        setAnchorEl(null)
    }, [setAnchorEl])

    const toggleMenu = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(!open ? event.currentTarget : null)
        },
        [setAnchorEl, open]
    )

    return (
        <Box className={classes.boxBtn}>
            <IconButton onClick={toggleMenu} sx={{ color: "primary.main", padding: 0 }} data-testid="edit-button">
                <Icon name="threeDotsHorizontal" />
            </IconButton>
            <Menu elevation={6} open={open} onClose={handleClose} anchorEl={anchorEl} sx={menuSx}>
                <MenuItem
                    onClick={() => {
                        handleClose()
                        handleModify(true)
                    }}
                    className="clickable"
                    sx={{ color: "primary.main" }}
                >
                    <Icon name="edit" /> {t("CAMPAIGN.MODIFY_SCHEDULE")}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleCancel()
                    }}
                    className="clickable"
                    sx={{ color: "error.main" }}
                    disabled={isLoading}
                >
                    {isLoading && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                    {!isLoading && <Icon name="delete1" />}
                    {t("CAMPAIGN.CANCEL_CAMPAGNE")}
                </MenuItem>
            </Menu>
        </Box>
    )
}
