import env from "@/config/env"
import { ApiService } from "../base"

class ShortlinkService extends ApiService {
    constructor() {
        super({
            baseURL: `${env.SHORTLINK_API_PATH}`,
        })
    }
}

export default new ShortlinkService()
