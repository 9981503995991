import QRCode from "qrcode"
import { useCallback, useEffect, useState } from "react"

type QrCodeProps = {
    qrValue: string
    width?: number | string
    height?: number | string
    margin?: number | string
    onGenerateQrCode?: (value: string) => void
}

/**
 * A React functional component to generate and display a QR code.
 *
 * @param {QrCodeProps} props - The properties passed to the component.
 * @returns {JSX.Element} A QR code image wrapped in a container.
 *
 * @example
 * ```jsx
 * <QrCodeComponent
 *   qrValue="https://example.com"
 *   width={200}
 *   height={200}
 *   margin={10}
 *   onGenerateQrCode={(dataUrl) => console.log('Generated QR Code:', dataUrl)}
 * />
 * ```
 */
export const QrCodeComponent: React.FC<QrCodeProps> = ({ qrValue, width, height, margin, onGenerateQrCode }) => {
    const [qr, setQr] = useState<string>("")

    /**
     * Generates the QR code as a data URL and updates the state.
     * Also calls the `onGenerateQrCode` callback if provided.
     */
    const generateQRCode = useCallback(async () => {
        const dataUrl = await QRCode.toDataURL(qrValue, {
            width: 269,
            height: 269,
            margin: 1,
        })
        setQr(dataUrl)
        onGenerateQrCode?.(dataUrl)
    }, [qrValue, onGenerateQrCode])

    useEffect(() => {
        generateQRCode()
    }, [generateQRCode])

    if (qrValue === "") return <div></div>

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img
                src={qr}
                alt="qrCode"
                className="qr-code"
                width={width || 195}
                height={height || 195}
                style={{ margin: margin || 0 }}
                data-testid="qr-code-image"
            />
        </div>
    )
}
