import useStyles from "@/components/features/business/schedule/exceptional-item/styles"
import UiStore from "@/store/ui"
import UiPageSessionStore from "@/store/ui-page-session"
import { validateTimes } from "@/utils"
import { validateDay } from "@/utils/validateDay"
import dayjs, { Dayjs } from "dayjs"
import cloneDeep from "lodash.clonedeep"
import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"

interface LogicProps {
    scheduleIndex: number
}

const useLogic = ({ scheduleIndex }: LogicProps) => {
    const { t } = useTranslation()
    const { classes } = useStyles()
    const { preferredLanguage } = UiStore()
    const { previousItem, updatePreviousItem } = UiPageSessionStore()

    const showAa = useMemo(() => {
        let value = false
        if (preferredLanguage?.toLowerCase() == "en") {
            value = true
        }
        return value
    }, [preferredLanguage])

    const onChangeDate = useCallback(
        (value: Dayjs) => {
            const newSpecialDays = cloneDeep(previousItem?.specialDays)
            newSpecialDays[scheduleIndex].startDate = dayjs(value).format("YYYY-MM-DD")
            newSpecialDays[scheduleIndex].endDate = dayjs(value).format("YYYY-MM-DD")

            const isValid = validateDay(newSpecialDays, newSpecialDays[scheduleIndex])

            if (!isValid) newSpecialDays[scheduleIndex]["dateValid"] = false
            else newSpecialDays[scheduleIndex]["dateValid"] = true

            updatePreviousItem({
                specialDays: newSpecialDays,
            })
        },
        [previousItem?.specialDays]
    )

    const duplicateCurrentItem = useCallback(
        (value, key) => {
            const newDay = cloneDeep(value)
            newDay.dateValid = false

            const newSpecialDays = cloneDeep(previousItem?.specialDays)
            newSpecialDays.splice(key + 1, 0, newDay)

            updatePreviousItem({
                specialDays: newSpecialDays,
            })
        },
        [previousItem?.specialDays]
    )

    const deleteCurrentItem = useCallback(
        (key) => {
            const newSpecialDays = cloneDeep(previousItem?.specialDays)
            newSpecialDays.splice(key, 1)

            updatePreviousItem({
                specialDays: newSpecialDays,
            })
        },
        [previousItem?.specialDays]
    )

    const onChangeSchedule = useCallback(
        (actionType: "add" | "delete", index) => {
            const newDays = cloneDeep(previousItem?.specialDays)
            const hasTomorrow = newDays[scheduleIndex]?.times?.some((time) => time.tomorrow)
            if (actionType === "add" && !hasTomorrow) {
                newDays[scheduleIndex].times.push({
                    start: null,
                    end: null,
                    startValid: false,
                    endValid: false,
                })
            } else if (actionType === "delete") {
                newDays[scheduleIndex].times.splice(index, 1)
            }
            const newSpecialDays = previousItem?.specialDays.map((item, key) => {
                if (key === scheduleIndex) return { ...newDays[scheduleIndex] }
                else return item
            })

            updatePreviousItem({
                specialDays: newSpecialDays,
            })
        },
        [scheduleIndex, previousItem?.specialDays]
    )

    const handleSelectTimefield = useCallback(
        (value: Dayjs, index: number, type: string) => {
            const newSpecialDays = cloneDeep(previousItem?.specialDays)
            newSpecialDays[scheduleIndex].times[index][type] = value

            const validated = validateTimes({ ...newSpecialDays[scheduleIndex] }, showAa)

            newSpecialDays[scheduleIndex] = { ...newSpecialDays[scheduleIndex], ...validated }
            updatePreviousItem({
                specialDays: newSpecialDays,
            })
        },
        [scheduleIndex, previousItem?.specialDays]
    )

    const handleChangeClose = useCallback(() => {
        const newSpecialDays = cloneDeep(previousItem?.specialDays)
        if (
            !newSpecialDays[scheduleIndex].isOpen &&
            newSpecialDays[scheduleIndex].endDate == null &&
            newSpecialDays[scheduleIndex].times?.length === 0
        ) {
            newSpecialDays[scheduleIndex].endDate = newSpecialDays[scheduleIndex].startDate
            newSpecialDays[scheduleIndex].times = [{ start: null, end: null, startValid: false, endValid: false }]
        }
        newSpecialDays[scheduleIndex].isOpen = !newSpecialDays[scheduleIndex].isOpen
        newSpecialDays[scheduleIndex].isAvailableAllDay = false
        updatePreviousItem({
            specialDays: newSpecialDays,
        })
    }, [scheduleIndex, previousItem?.specialDays])

    const handleChange24 = useCallback(
        (key) => {
            const newSpecialDays = cloneDeep(previousItem?.specialDays)

            newSpecialDays[key].times = !newSpecialDays[key].isAvailableAllDay
                ? [
                      {
                          start: null,
                          end: null,
                          startValid: false,
                          endValid: false,
                      },
                  ]
                : newSpecialDays[key].times
            newSpecialDays[key].isAvailableAllDay = !newSpecialDays[key].isAvailableAllDay
            updatePreviousItem({
                specialDays: newSpecialDays,
            })
        },
        [previousItem?.specialDays]
    )

    return {
        classes,
        t,
        preferredLanguage,
        showAa,
        duplicateCurrentItem,
        onChangeDate,
        deleteCurrentItem,
        handleChangeClose,
        handleChange24,
        handleSelectTimefield,
        onChangeSchedule,
        validateTimes,
    }
}

export default useLogic
