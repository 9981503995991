import { StatisticsApi } from "@/api"
import { FetchKpiInput } from "@/api/statistics"
import { AnalyticsCampaignData } from "@/types/statistic"
import { checkFormatOfDateMonthDay } from "@/utils"
import dayjs from "dayjs"
import { t } from "i18next"
import { ChartService } from ".."

interface PropsSerie {
    name?: string
    data: number[]
    type?: string
}

class StatisticCampaignService {
    fetchKpi = async (filterParams: FetchKpiInput) => {
        return await StatisticsApi.fetchKpiCampaign(filterParams)
    }

    resolveGraphsFeedback = (graph: AnalyticsCampaignData, type: string, locale: string) => {
        const isMonthFormat: boolean = type === "month"
        const categories = []
        const period = graph.period
        const previousPeriod = graph?.previous_period ?? []
        const format = isMonthFormat ? checkFormatOfDateMonthDay(locale) : "MMM YY"
        const lengthTotal = isMonthFormat ? 31 : 13
        const minKey = period.length === lengthTotal ? 1 : 0
        const pourcentages = []
        const totalCurrent = []
        let maxPeriod = 0

        const feedback_sms = {
            name: t("REQUEST_OPINION.SMS".toUpperCase()),
            data: [],
            type: "column",
            color: "#FFD700",
        }
        const feedback_email = {
            name: t("REQUEST_OPINION.EMAIL".toUpperCase()),
            data: [],
            type: "column",
            color: "#FFA000",
        }

        const feedback_qrcode = {
            name: t("REQUEST_OPINION.CODE_QR".toUpperCase()),
            data: [],
            type: "column",
            color: "#F57C00",
        }

        const feedback_nfc = {
            name: t("REQUEST_OPINION.NFC_CARD".toUpperCase()),
            data: [],
            type: "column",
            color: "#BF360C",
        }

        const feedback_link = {
            name: t("REQUEST_OPINION.LINK".toUpperCase()),
            data: [],
            type: "column",
            color: "#E65100",
        }

        const total = {
            name: t("REQUEST_OPINION.TOTAL".toUpperCase()),
            data: [],
            type: "line",
            color: "#68738D",
        }

        for (let i = minKey; i < period.length; i++) {
            // https://ubiweb.atlassian.net/browse/DLP-4001
            // Display one of two days while month format
            categories.push(i % 2 === 0 || !isMonthFormat ? dayjs(period[i].date).locale(locale).format(format) : "")
            feedback_sms.data.push(period[i].feedback_sms)
            feedback_email.data.push(period[i].feedback_email)
            feedback_qrcode.data.push(period[i].feedback_qrcode)
            feedback_link.data.push(period[i].feedback_link)
            feedback_nfc.data.push(period[i].feedback_nfc)
            totalCurrent.push(period[i].feedback_total)
        }

        for (let i = minKey; i < previousPeriod.length; i++) {
            let valueTotal = previousPeriod[i].feedback_total
            if (previousPeriod[i].feedback_total === maxPeriod && maxPeriod === 0) {
                valueTotal = null
            }
            if (previousPeriod[i].feedback_total_variation !== 0) {
                maxPeriod = previousPeriod[i].feedback_total_variation
            }

            total.data.push(valueTotal)
            pourcentages.push({
                feedback_nfc: maxPeriod === 0 ? null : previousPeriod[i].feedback_nfc_variation,
                feedback_link: maxPeriod === 0 ? null : previousPeriod[i].feedback_link_variation,
                feedback_qrcode: maxPeriod === 0 ? null : previousPeriod[i].feedback_qrcode_variation,
                feedback_email: maxPeriod === 0 ? null : previousPeriod[i].feedback_email_variation,
                feedback_sms: maxPeriod === 0 ? null : previousPeriod[i].feedback_sms_variation,
                total: maxPeriod === 0 ? null : previousPeriod[i].feedback_total_variation,
            })
        }

        const maxYaxis = Math.max(...totalCurrent, ...total.data)
        const series: PropsSerie[] = [feedback_nfc, feedback_link, feedback_qrcode, feedback_email, feedback_sms]

        if (previousPeriod?.length > 0) {
            series.push(total)
        }

        const stroke = {
            width: 0,
            curve: "straight",
            dashArray: 0,
        }

        const graphs = { series, categories, pourcentages, totalCurrent, stroke, maxYaxis: maxYaxis > 0 ? maxYaxis : 5 }

        const yFormatters = [
            ChartService.createFormatter("feedback_nfc", graphs),
            ChartService.createFormatter("feedback_link", graphs),
            ChartService.createFormatter("feedback_qrcode", graphs),
            ChartService.createFormatter("feedback_email", graphs),
            ChartService.createFormatter("feedback_sms", graphs),
            ChartService.createFormatter("total", graphs),
        ]

        return {
            ...graphs,
            yFormatters,
            tooltip: { enabledOnSeries: [0, 1, 2, 3, 4, 5] },
            markers: {
                hover: {
                    size: 0,
                },
            },
            legend: {
                formatter: (seriesName, opts) => {
                    if (opts.seriesIndex === 5) return "" // hides first label
                    return seriesName
                },
                markers: {
                    height: 12,
                    radius: 10,
                    width: [12, 12, 12, 12, 12, 0], // hides some marker
                },
            },
        }
    }

    resolveAppreciationClient = (graph: AnalyticsCampaignData, type: string, locale: string) => {
        const isMonthFormat: boolean = type === "month"
        const categories = []
        const period = graph.period
        const previousPeriod = graph?.previous_period ?? []
        const format = isMonthFormat ? checkFormatOfDateMonthDay(locale) : "MMM YY"
        const lengthTotal = isMonthFormat ? 31 : 13
        const minKey = period.length === lengthTotal ? 1 : 0
        const pourcentages = []
        const totalCurrent = []
        let totalLike = 0
        let totalDislike = 0
        let maxPeriod = 0

        const like = {
            name: t("REQUEST_OPINION.SATISFIED".toUpperCase()).replace("!", ""),
            data: [],
            type: "column",
            color: "#03CC6C",
        }
        const dislike = {
            name: t("REQUEST_OPINION.NOT_SATISFIED".toUpperCase()),
            data: [],
            type: "column",
            color: "#FFC431",
        }

        const total = {
            name: t("REQUEST_OPINION.TOTAL".toUpperCase()),
            data: [],
            type: "line",
            color: "#68738D",
        }

        for (let i = minKey; i < period.length; i++) {
            // https://ubiweb.atlassian.net/browse/DLP-4001
            // Display one of two days while month format
            categories.push(i % 2 === 0 || !isMonthFormat ? dayjs(period[i].date).locale(locale).format(format) : "")
            totalLike += period[i].like
            totalDislike += period[i].dislike
            like.data.push(period[i].like)
            dislike.data.push(period[i].dislike)
            totalCurrent.push(period[i].like_dislike_total)
        }

        for (let i = minKey; i < previousPeriod.length; i++) {
            let valueTotal = previousPeriod[i].like_dislike_total
            if (previousPeriod[i].like_dislike_total === maxPeriod && maxPeriod === 0) {
                valueTotal = null
            }
            if (previousPeriod[i].like_dislike_total_variation !== 0) {
                maxPeriod = previousPeriod[i].like_dislike_total_variation
            }

            total.data.push(valueTotal)
            pourcentages.push({
                like: maxPeriod === 0 ? null : previousPeriod[i].like_variation,
                dislike: maxPeriod === 0 ? null : previousPeriod[i].dislike_variation,
                total: maxPeriod === 0 ? null : previousPeriod[i].like_dislike_total_variation,
            })
        }

        const maxYaxis = Math.max(...totalCurrent, ...total.data)
        const series: PropsSerie[] = [dislike, like]

        if (previousPeriod?.length > 0) {
            series.push(total)
        }

        const stroke = {
            width: 0,
            curve: "straight",
            dashArray: 0,
        }

        const graphs = {
            series,
            categories,
            pourcentages,
            totalCurrent,
            stroke,
            maxYaxis: maxYaxis > 0 ? maxYaxis : 5,
            totalLike,
            totalDislike,
        }

        const yFormatters = [
            ChartService.createFormatter("dislike", graphs),
            ChartService.createFormatter("like", graphs),
            ChartService.createFormatter("total", graphs),
        ]

        return {
            ...graphs,
            yFormatters,
            tooltip: { enabledOnSeries: [0, 1] },
            markers: {
                hover: {
                    size: 0,
                },
            },
            legend: {
                formatter: (seriesName, opts) => {
                    if (opts.seriesIndex === 2) return "" // hides first label
                    return seriesName
                },
                markers: {
                    height: 12,
                    radius: 10,
                    width: [12, 12, 0], // hides some marker
                },
            },
        }
    }
}
export default new StatisticCampaignService()
