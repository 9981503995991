import Icon from "@/components/base/Icon"
import BaseInput from "@/components/base/input"
import { ChangeEvent, useCallback, useState } from "react"
import { Button, IconButton, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

export const BlocMessage = ({ maxLength, setText, text }) => {
    const { t } = useTranslation()
    const [tempText, setTempText] = useState(text)
    const [isEdit, setIsEdit] = useState<boolean>(false)

    const handleSaveMessage = useCallback(() => {
        setText(tempText)
        setIsEdit(false)
    }, [setText, tempText])

    return (
        <>
            {!isEdit && (
                <Stack
                    flexDirection="row"
                    justifyContent={"center"}
                    alignItems={"flex-start"}
                    maxWidth={"270px"}
                    gap={0.5}
                    position={"relative"}
                >
                    <Typography variant="h2" textAlign={"center"}>
                        {text}
                    </Typography>
                    <IconButton className="btn-edit-message" onClick={() => setIsEdit(true)}>
                        <Icon name="edit" sx={{ color: "action.active" }} />
                    </IconButton>
                </Stack>
            )}
            {isEdit && (
                <Stack gap={1} width={"100%"} maxWidth={"260px"} alignItems={"center"}>
                    <BaseInput
                        multiline
                        hiddenLabel={true}
                        helperText={`${tempText?.length || "0"}/${maxLength || 4096}`}
                        helperTextPosition="right"
                        value={tempText}
                        max={maxLength}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setTempText(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        data-testid="input-message"
                        className="input-message"
                    />
                    <Button variant="contained" sx={{ width: "fit-content" }} onClick={() => handleSaveMessage()}>
                        {t("USERS.SAVE")}
                    </Button>
                </Stack>
            )}
        </>
    )
}
