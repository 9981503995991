import useStyles from "@/components/features/business/list/styles"
import { SummaryItem } from "@/components/features/listings/summary-list"
import { ListingService } from "@/services"
import { ListingData, PlatformLocationMap } from "@/services/listing"
import UserSessionStore from "@/store/user-session"
import { paginate } from "@/utils/paginate"
import cloneDeep from "lodash.clonedeep"
import deburr from "lodash.deburr"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import useUiPageSession from "../useUiPageSession"

const useLogic = () => {
    useUiPageSession({
        title: "LISTINGS.TITLE",
        page: "listing",
    })
    const [isLoading, setIsLoading] = useState(true)
    const { classes } = useStyles()
    const { profile } = UserSessionStore()
    const [items, setItems] = useState([])
    const [page, setPage] = useState(0)
    const { t } = useTranslation()
    const [viewTypeActive, setViewTypeActive] = useState<"platform" | "location">("platform")
    const [dataMap, setDataMap] = useState<ListingData>({
        locationMap: {},
        platformMap: {},
        summaryCount: {
            inProgress: 0,
            listings: 0,
            synchronised: 0,
        },
    })
    const [summaryList, setSummaryList] = useState<SummaryItem[]>([
        {
            data: 0,
            icon: "profile",
            name: "BUSINESSES.TITLE",
            color: "primary",
        },
        {
            data: 0,
            icon: "bulletList",
            name: "LISTINGS.SUMMARY_TITLE",
            color: "primary",
        },
        {
            data: 0,
            icon: "checkCircle",
            name: "LISTINGS.SYNCHRONIZED",
            color: "success",
        },
        {
            data: 0,
            icon: "refreshCircle",
            name: "LISTINGS.ONGOING",
            color: "warning",
        },
    ])

    const refreshData = useCallback(async () => {
        setIsLoading(true)

        const { count: locationCount, ids } = await ListingService.getLocationMap({
            user_uid: profile?.uid,
        })
        const { locationMap, platformMap, summaryCount } = await ListingService.getListingsMap(ids)
        setSummaryList((current) => {
            // location count
            current[0].data = locationCount
            // listings count
            current[1].data = summaryCount.listings
            // Synchronised count
            current[2].data = summaryCount.synchronised
            // In Progress count
            current[3].data = summaryCount.inProgress
            return [...current]
        })
        setDataMap({
            locationMap: cloneDeep(locationMap),
            platformMap: cloneDeep(platformMap),
            summaryCount: cloneDeep(summaryCount),
        })
        setItems([...Object.values(platformMap)])
        setIsLoading(false)
    }, [profile?.uid])

    const handleViewChange = useCallback(
        (active: number) => {
            // 0 : viewType == "platform"
            // 1 : viewType == "location"
            const viewType = active === 1 ? "location" : "platform"
            setViewTypeActive(viewType)
            if (viewType === "location") {
                setItems([...Object.values(dataMap.locationMap)])
            } else {
                setItems([...Object.values(dataMap.platformMap)])
            }
        },
        [dataMap]
    )

    const handleSearchValueChange = useCallback(
        (searchValue: string) => {
            // 0 : viewType == "platform"
            // 1 : viewType == "location"
            const data = viewTypeActive === "location" ? dataMap.locationMap : dataMap.platformMap

            const filtered = (Object.values(data) || []).filter((item: PlatformLocationMap) => {
                if (searchValue) {
                    const res =
                        deburr(item.name?.toLowerCase())?.indexOf(deburr(searchValue?.toLowerCase())) > -1 ||
                        deburr(item.address?.toLowerCase())?.indexOf(deburr(searchValue?.toLowerCase())) > -1

                    return res
                }
                return true
            })
            setPage(0)
            setItems(filtered)
        },
        [dataMap, viewTypeActive]
    )

    const filteredData = useMemo(() => {
        const response = paginate(items, page === 0 ? 1 : page + 1, 12)
        return response.items || []
    }, [items, page])

    useEffect(() => {
        refreshData()
    }, [refreshData])

    return {
        t,
        filteredData,
        summaryList,
        viewTypeActive,
        classes,
        page,
        items,
        isLoading,
        handleViewChange,
        handleSearchValueChange,
        setPage,
    }
}

export default useLogic
