import { CookiesService } from "@/services"
import { LocationType } from "@/types/businesses"
import { SelectType } from "@/types/select"
import { SxProps, Theme } from "@mui/material"
import dayjs, { Dayjs } from "dayjs"
import "dayjs/locale/en"
import "dayjs/locale/fr"
import minMax from "dayjs/plugin/minMax"
import timezone from "dayjs/plugin/timezone"

import utc from "dayjs/plugin/utc"
import parseRegularHours from "./parseRegularHours"
import validateHours from "./validateHours"
import validateTimes from "./validateTimes"
export type ColorType = "primary" | "success" | "warning" | "error"

dayjs.extend(minMax)
dayjs.extend(utc)
dayjs.extend(timezone)

export const decode64AndParse = (base64: string) => {
    try {
        // Check if the input is a valid Base64 string
        if (typeof base64 !== "string" || base64.trim() === "") {
            throw new Error("Invalid input: Input is not a valid Base64 string")
        }

        const decodedString = window.atob(base64)

        return JSON.parse(decodedString)
    } catch (error) {
        return null
    }
}

export const calculateHeight = (list: NodeListOf<HTMLDivElement>, isTransparent: boolean, width: number) => {
    list.forEach((parent) => {
        const element = parent.querySelector(".title-wrapper")
        const elementHeight = element.clientHeight
        const formElement = element.nextElementSibling as HTMLFormElement
        if (width >= 768) {
            formElement.style.height = `calc(100% - 48px ${!isTransparent ? "- 32px" : ""} - 24px - ${elementHeight}px)`
        } else {
            formElement.style.cssText = `height: calc(100% - 72px - 8px ${
                !isTransparent ? "- 16px" : ""
            } - 16px - ${elementHeight}px);`
        }
    })
}

export const isValidURL = (url: string) => {
    const pattern = new RegExp(
        "^(https?:\\/\\/)?([A-Za-z0-9-]+\\.)+[A-Za-z]{2,6}(:\\d{1,5})?(\\/.*)?$", // fragment locator
        "i"
    )
    return pattern.test(url)
}

export const isValidUrlForSocial = (url: string) => {
    const urlRegex =
        /^(https?:\/\/)?(www\.)?([a-zA-Z0-9_-]+\.[a-zA-Z]{2,})(:[0-9]{1,5})?(\/[^\s?#]*)?(\?[^\s#]*)?(#[^\s]*)?$/
    return urlRegex.test(url)
}

export const isValidEmail = (email: string) => {
    if (!email || email === undefined) return true
    // const pattern = new RegExp("[a-z0-9]+@[a-z]+\\.[a-z]{2,3}")
    const pattern = /^(?!.*\.\.)(?!.*\.$)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/
    return pattern.test(email)
}
const isValidURLFacebook = (url: string) => {
    if (!isValidURL(url)) {
        return false
    }

    // Normalize URL (ignore http/https and subdomains like m.facebook.com)
    const normalizedUrl = url.replace(/^https?:\/\/(www\.)?|^https?:\/\/(m\.)?/, "")

    // facebook.com/profile.php?id=[numId]
    if (normalizedUrl.includes("facebook.com/profile.php")) {
        const pattern = new RegExp("facebook.com/profile.php\\?id=\\d+", "i")
        return pattern.test(normalizedUrl)
    }

    // facebook.com/group.php?gid=[numId]
    if (normalizedUrl.includes("facebook.com/group.php")) {
        const pattern = new RegExp("facebook.com/group.php\\?gid=\\d+", "i")
        return pattern.test(normalizedUrl)
    }

    // facebook.com/groups/[numId]
    if (normalizedUrl.includes("facebook.com/groups/")) {
        const pattern = new RegExp("facebook.com/groups/\\d+", "i")
        return pattern.test(normalizedUrl)
    }

    // facebook.com/people/[Name]/[numId]
    // facebook.com/pages/[Name]/[numId]
    if (normalizedUrl.includes("facebook.com/pages/") || normalizedUrl.includes("facebook.com/people/")) {
        const pattern = new RegExp("facebook.com/(pages|people)/[a-zA-Z0-9_.-]+/\\d+", "i")
        return pattern.test(normalizedUrl)
    }

    // facebook.com/p/[PageName]-[ID]
    if (normalizedUrl.includes("facebook.com/p/")) {
        const pattern = new RegExp("facebook.com/p/[a-zA-Z0-9_.-]+-\\d+/?$", "i")
        return pattern.test(normalizedUrl)
    }

    // facebook.com/[Name] (for user profiles or pages with custom URLs)
    if (normalizedUrl.includes("facebook.com/")) {
        const pattern = new RegExp("facebook.com/[a-zA-Z0-9_.-]+/?$", "i")
        return pattern.test(normalizedUrl)
    }

    return false
}

export const isValidURLSocialNetwork = (url: string, types: string[]) => {
    for (const type of types) {
        if (type.includes("facebook.com")) {
            return isValidURLFacebook(url)
        }
        if (url.includes(type)) return true
    }

    return false
}
export const formatZipByCountry = (value: string, countryCode: string) => {
    if (countryCode === "CA") {
        if (value.length > 7) return value.slice(0, 7)
        if (/^[A-Z]\d[A-Z]\d[A-Z]\d$/i.test(value)) {
            return `${value.substring(0, 3)} ${value.substring(3)}`.toUpperCase()
        }
        if (
            value === "" ||
            // length === 1
            /^[A-Z]$/i.test(value) ||
            // length === 2
            /^[A-Z]\d$/i.test(value) ||
            // length === 3
            /^[A-Z]\d[A-Z]$/i.test(value) ||
            // length === 4
            /^[A-Z]\d[A-Z]\s$/i.test(value) ||
            /^[A-Z]\d[A-Z]\d$/i.test(value) ||
            // length === 5
            /^[A-Z]\d[A-Z]\s\d$/i.test(value) ||
            /^[A-Z]\d[A-Z]\d[A-Z]$/i.test(value) ||
            // length === 6
            /^[A-Z]\d[A-Z]\s\d[A-Z]$/i.test(value) ||
            /^[A-Z]\d[A-Z]\d[A-Z]\d$/i.test(value) ||
            // length === 7
            /^[A-Z]\d[A-Z]\s\d[A-Z]\d$/i.test(value)
        ) {
            return value.toUpperCase()
        }
        return value.toUpperCase()
    } else if (countryCode === "BE") {
        if (value.length > 4) return value.slice(0, 4)
        if (/^\d{4}$/i.test(value)) {
            return value
        }
        return value
    } else {
        const regex = /\D/g
        value = value.replace(regex, "")
        if (value.length > 5) return value.slice(0, 5)
        if (value === "") return ""
        if (/^(?:\d{1,5})?$/.test(value)) {
            return value
        }
        return value
    }
}

export const withSxProp = (...sxProps: SxProps<Theme>[]): SxProps<Theme> =>
    sxProps.reduce(
        (prev, currentValue) => [
            ...(Array.isArray(prev) ? prev : [prev]),
            ...(Array.isArray(currentValue) ? currentValue : [currentValue]),
        ],

        [] as SxProps<Theme>
    )

const checkDisplayOfDate = (initDate, lang, widthHour = false) => {
    const locale = lang === "en" ? "en" : "fr"
    const formatType =
        lang === "en" ? `MMM DD, YYYY ${widthHour ? "h:mm A" : ""}` : `DD MMM YYYY ${widthHour ? "HH:mm" : ""}`
    return `${dayjs.utc(initDate).locale(locale).format(formatType.trim())}`
}

const checkFormatOfDateMonthDay = (lang) => {
    const formatType = lang === "en" ? "MMM DD" : "DD MMM"
    return formatType
}

const sortDateException = (list: any) => {
    return list.sort((a, b) => (dayjs(a.startDate).isAfter(dayjs(b.startDate)) ? 1 : -1))
}

const getColor = (color: ColorType) => {
    switch (color) {
        case "primary":
            return "text.primary"
        case "success":
            return "success.main"
        case "warning":
            return "warning.main"
        case "error":
            return "error.main"
    }
}

const getScoreColor = (score: number) => {
    if (score <= 33) {
        return getColor("error")
    } else if (score > 33 && score <= 66) {
        return getColor("warning")
    } else {
        return getColor("success")
    }
}

export const hasOwn = (data: object, key: string): boolean => {
    return key in data
}

const isGoogleConnected = ({ locationState }: LocationType = {}): boolean => {
    if (!locationState) {
        return false
    }

    return !["NOT_CONNECTED", "DISCONNECTED"].includes(locationState?.status)
}

export const urlify = (text: string) => {
    const generatedUrl = CookiesService.get("feedbackUrl") + "/3MvoFeY"

    return text
        .replace(/@Url|@url/, generatedUrl)
        .replace(/@Url|@url/gi, "")
        ?.trim()
}

export const removeDuplicateUrl = (text: string) => {
    const generatedUrl = CookiesService.get("feedbackUrl") + "/3MvoFeY"

    return text
        .replace(/@Url|@url/, generatedUrl)
        .replace(/@Url|@url/gi, "")
        .replace(generatedUrl, "@url")
}

export const objectToQueryString = (obj) => {
    const params = new URLSearchParams()
    for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
            params.append(key, obj[key])
        }
    }
    return params.toString()
}

export const encodeBase64 = (base64: string) => {
    return window.btoa(base64)
}

export const isObject = (object) => {
    return object != null && typeof object === "object"
}

const omit = (keys, obj) => {
    const target = {}

    for (const i in obj) {
        if (keys.indexOf(i) >= 0) continue
        if (!Object.prototype.hasOwnProperty.call(obj, i)) continue

        target[i] = obj[i]
    }
    return target
}

const isDeepEqual = (object1, object2, excludeKeys = []) => {
    const objKeys1 = Object.keys(omit(excludeKeys, object1))
    const objKeys2 = Object.keys(omit(excludeKeys, object2))

    if (objKeys1.length !== objKeys2.length) return false

    for (const key of objKeys1) {
        if (!excludeKeys.includes(key)) {
            const value1 = object1[key]
            const value2 = object2[key]

            const isObjects = isObject(value1) && isObject(value2)

            if ((isObjects && !isDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
                return false
            }
        }
    }
    return true
}

const convertHtml = (html) => {
    let newHtml = html ?? ""
    if (newHtml) {
        newHtml = newHtml.replace(/<p><\/p>/g, "")
        newHtml = newHtml.replace(/<\/p><p>/g, "\n")
        newHtml = newHtml.replace(/<[^>]+>/g, "")
    }
    let temp = document.createElement("p")
    temp.innerHTML = newHtml
    const plainText = temp.textContent || temp.innerText
    temp = null

    return plainText
}

const isDecimalNumber = (value) => {
    return value % 1 > 0
}

const convertBigNumber = (value: number) => {
    let val: string | number = Math.abs(value)
    if (val >= 10 ** 3 && val < 10 ** 6) {
        val = (val / 10 ** 3).toFixed(1) + " K"
    } else if (val >= 10 ** 6) {
        val = (val / 10 ** 6).toFixed(1) + " M"
    } else {
        val = isDecimalNumber(val) ? val.toFixed(1).toString() : val.toString()
    }

    return val
}

const convertBigNumberSpace = (value: number, defaultText: string, unitText = "") => {
    let val: string | number = value
    if ((!value && value !== 0) || value === undefined) {
        return defaultText
    } else {
        val = Math.abs(value)
    }
    if (val >= 10 ** 6) {
        return `${(value / 10 ** 6).toFixed(1)} M${unitText}`
    }
    return `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}${unitText}`
}

const convertPourcentageString = (value: number, testKpi = true, noPourcent = false, roundTest = true) => {
    let val = value
    if (roundTest) {
        val = Math.round(value)
    }

    if (!testKpi || !value) {
        return null
    } else {
        return val > 0 ? `+${val} ${noPourcent ? "" : "%"}` : `${val} ${noPourcent ? "" : "%"}`
    }
}

const convertStyleString = (value: number) => {
    return value > 0 ? "superior" : "inferior"
}

const getIconStar = (index: number) => {
    if (index != 0 && index % 1 !== 0) {
        return "starHalfFilled"
    }
    if (index != 0 && index % 1 === 0) {
        return "starFilled"
    }
    return "starOutlineFilled"
}

const convertToStarArray = (number: number) => {
    return Array.from({ length: 5 }, (v, index) => {
        if (index < Math.floor(number)) {
            return 1
        } else if (index === Math.floor(number)) {
            return number % 1
        } else {
            return 0
        }
    })
}

const checkHavelink = (value: string) => {
    if (value.includes('data-id="url"')) {
        return true
    }
    return false
}

const groupByKey = (array: any[], key: string) => {
    return array.reduce((hash, obj) => {
        if (obj[key] === undefined) return hash

        // Format the date with zero-padding
        const formattedKey = dayjs(obj[key]).isValid() ? obj[key].slice(0, 10) : obj[key]

        if (!hash[formattedKey]) {
            hash[formattedKey] = []
        }
        hash[formattedKey].push(obj)

        return hash
    }, {} as Record<string, any[]>)
}

const generateTimeSlots = (startTime: string, endTime: string, interval: number) => {
    const times = []
    let current = dayjs(startTime, "HH:mm")

    while (current.isBefore(dayjs(endTime, "HH:mm"))) {
        times.push({
            label: current.format("HH:mm"),
            value: current.format("HH:mm"),
        })
        current = current.add(interval, "minute")
    }

    return times
}

const getNextAvailableTimes = (date: Dayjs) => {
    const now = dayjs(date)
    const startMinute = now.minute() >= 30 ? 30 : 0

    let startTime = now.minute(startMinute).second(0).millisecond(0)

    if (startTime.isBefore(now)) {
        startTime = startTime.add(30, "minute")
    }

    const times = []
    const nextMidnight = now.add(1, "day").startOf("day")

    while (startTime.isBefore(nextMidnight)) {
        const val = startTime.format("HH:mm")
        times.push({
            label: val,
            value: val,
        })
        startTime = startTime.add(30, "minute")
    }

    return times
}

const getUpdatedOptions = (options: SelectType[], optionsAvailable?: SelectType[]): SelectType[] => {
    const availableValues = new Set(optionsAvailable?.map((opt) => opt.value))

    return options.map((option) => ({
        ...option,
        disabled: optionsAvailable ? !availableValues.has(option.value) : false,
    }))
}

const ensureArray = <T>(value: T | T[]): T[] => {
    return Array.isArray(value) ? value : [value]
}

const formattedTimezone = (businesses) => {
    const res = {}
    businesses.forEach((item) => Object.assign(res, { [item.value]: item.timezone }))
    return res
}

const convertToDate = (obj: { day: number; month: number; year: number }) => {
    // Création de la date à partir de l'objet

    const date = dayjs(new Date(obj.year, obj.month, obj.day))

    // Formatage de la date (exemple : format français "DD/MM/YYYY" ou anglais "MM/DD/YYYY")
    return date.format("YYYY-MM-DD")
}

const isSubset = (array1: string[], array2: string[]) => {
    // Create a map to count occurrences of elements in A
    const countMap = new Map()
    for (const item of array1) {
        countMap.set(item, (countMap.get(item) || 0) + 1)
    }

    // Check each element in B
    for (const item of array2) {
        const count = countMap.get(item) || 0
        if (count === 0) {
            return false // If item is not available or exhausted
        }
        countMap.set(item, count - 1) // Decrease count
    }

    return true // All items in B are matched
}

export {
    checkDisplayOfDate,
    checkFormatOfDateMonthDay,
    checkHavelink,
    convertBigNumber,
    convertBigNumberSpace,
    convertHtml,
    convertPourcentageString,
    convertStyleString,
    convertToDate,
    convertToStarArray,
    ensureArray,
    formattedTimezone,
    generateTimeSlots,
    getColor,
    getIconStar,
    getNextAvailableTimes,
    getScoreColor,
    getUpdatedOptions,
    groupByKey,
    isDecimalNumber,
    isDeepEqual,
    isGoogleConnected,
    isSubset,
    parseRegularHours,
    sortDateException,
    validateHours,
    validateTimes,
}
