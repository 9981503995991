import Icon from "@/components/base/Icon"
import { Button, CircularProgress, Paper, Stack, SxProps } from "@mui/material"
import { useTranslation } from "react-i18next"
import useStyles from "./styles"
import IconNames from "@/types/icons"

type FormActionOptionalProps = {
    isDisabled: boolean
    isUpdating: boolean
    isCampaign: boolean
    buttonText: string
    sx: SxProps
    textBtnRight: string
    iconBtnRight: IconNames
    onSubmit: () => void
}

type FormActionProps = {
    step: number
    isLoading: boolean
    handleCancel: () => void
} & Partial<FormActionOptionalProps>

const FormAction = ({
    onSubmit,
    handleCancel,
    isDisabled,
    isCampaign,
    isLoading,
    sx,
    step,
    textBtnRight,
    iconBtnRight,
}: FormActionProps) => {
    const { t } = useTranslation()
    const { classes } = useStyles()

    return (
        <Paper className={classes.wrapper} elevation={6} sx={sx}>
            {step !== 0 && (
                <Button color="secondary" onClick={handleCancel}>
                    {!isCampaign ? t("USERS.CANCEL") : t("CAMPAIGN.BACK")}
                </Button>
            )}

            <Button variant={"contained"} data-testid="create" type="submit" disabled={isDisabled} onClick={onSubmit}>
                <Stack flexDirection={"row"} alignItems="center" gap={1}>
                    {isLoading && <CircularProgress data-testid="loading-progress" size={16} />}
                    {step !== 0 && !isLoading && <Icon name={iconBtnRight} />}
                    {t(textBtnRight)}
                </Stack>
            </Button>
        </Paper>
    )
}

export default FormAction
