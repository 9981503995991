import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps, Typography } from "@mui/material"
import React, { forwardRef } from "react"
import { useTranslation } from "react-i18next"

type SelectBaseProps = {
    id: string
    helperText?: string
    renderOptions?: (selected: any) => React.ReactNode
    options: {
        value: string | number
        label: string
    }[]
}

const SelectBase = forwardRef<any, SelectBaseProps & SelectProps>(
    ({ id, options, helperText, renderOptions, ...props }, ref) => {
        const { t } = useTranslation()

        return (
            <FormControl fullWidth={props?.fullWidth} variant="filled">
                <InputLabel
                    id={id}
                    required={props?.required}
                    sx={{
                        "&.MuiFormLabel-root": {
                            color: props?.error ? "error.main" : "action.active",
                        },
                        "& .MuiFormLabel-asterisk": {
                            color: props?.error ? "error.main" : "primary.main",
                        },
                    }}
                >
                    {props.label}
                </InputLabel>
                <Select
                    ref={ref} // Ajoutez cette ligne
                    labelId={id}
                    label={props.label}
                    defaultValue={""}
                    sx={{
                        backgroundColor: "#f3f4fb !important",
                        borderRadius: 1,
                        borderBottom: props.error ? "1px solid #f44336" : "1px solid rgba(91, 125, 216, 0.12)",
                        ":before,:after": {
                            borderBottom: "none !important",
                        },
                    }}
                    MenuProps={{
                        sx: {
                            "& .MuiPaper-root": {
                                padding: 0,
                                maxHeight: 200,
                            },
                        },
                    }}
                    {...props}
                >
                    {(options?.length === 0 || !options) && (
                        <MenuItem value="" sx={{ justifyContent: "center" }} disabled>
                            <Typography component={"span"} sx={{ color: "action.active" }}>
                                {t("AUTOCOMPLETE.NO_OPTIONS")}
                            </Typography>
                        </MenuItem>
                    )}
                    {options?.map((option) => (
                        <MenuItem value={option.value} key={option.value}>
                            {renderOptions && renderOptions(option)}
                            {!renderOptions && option.label}
                        </MenuItem>
                    ))}
                </Select>
                {helperText && (
                    <FormHelperText
                        sx={{
                            color: props.error ? "error.main" : "action.active",
                        }}
                    >
                        {helperText}
                    </FormHelperText>
                )}
            </FormControl>
        )
    }
)

export default SelectBase
