import { LocationsApi } from "@/api"
import useNotification from "@/hooks/useNotification"
import useUiPageSession from "@/hooks/useUiPageSession"
import useLocationStore from "@/store/location"
import UserSessionStore from "@/store/user-session"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

const useLogic = () => {
    const { profile } = UserSessionStore()
    const { isLoading: isLoadingBusiness, businesses } = useLocationStore()
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { notif } = useNotification()
    const [metadata, setMetadata] = useState({
        page_number: 0,
        page_size: 10,
        total_count: 0,
    })

    useUiPageSession({
        title: "MENU.QR_CODE_LINK",
        page: "qr",
    })
    const isSingleBusiness = useMemo(() => businesses?.length === 1, [businesses])
    const [filterValue, setFilterValue] = useState("")
    const [locations, setLocations] = useState([])

    const refreshLocationList = useCallback(
        async ({ page_number = 0, search = null, missing_info_required = false, completion = null }) => {
            setIsLoading(true)
            const response = await LocationsApi.fetchLocations({
                page_number: page_number + 1,
                page_size: 10,
                user_uid: profile.uid,
                search,
                missing_info_required,
                completion,
                fields: ["name", "address", "logo", "locationState", "links"],
            })

            if (response?.error) {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            } else if (response?.data) {
                setLocations(response?.data)
                setMetadata((prev) => ({
                    ...prev,
                    page_number: page_number,
                    total_count: response?.metadata?._count,
                    page_size: response?.metadata?._limit,
                }))
            }
            setIsLoading(false)
        },
        [profile.uid, notif, t]
    )

    const handleChangePage = useCallback(
        (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
            refreshLocationList({ page_number: newPage, search: filterValue })
        },
        [refreshLocationList, filterValue]
    )

    useEffect(() => {
        if (!isLoadingBusiness) {
            if (businesses?.length === 1) {
                navigate(`/qr/${businesses[0].value}`)
            } else {
                refreshLocationList({
                    search: filterValue,
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValue, businesses, isLoadingBusiness])

    return {
        metadata,
        isLoading: isLoadingBusiness || isLoading,
        locations,
        filterValue,
        alert,
        handleChangePage,
        setFilterValue,
        isSingleBusiness,
    }
}

export default useLogic
