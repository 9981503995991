import { Button } from "@mui/material"
import { useCallback } from "react"
import useNotification from "./useNotification"
import { useTranslation } from "react-i18next"

const useClipboard = () => {
    const { notif } = useNotification()
    const { t } = useTranslation()
    const handleClipboardCopy = useCallback(
        (uid: string) => {
            navigator.clipboard.writeText(uid)
            notif({ type: "SUCCESS", message: t("COMMON.TEXT_COPIED") })
        },
        [notif]
    )
    const ButtonClipboard = useCallback(
        ({ label, value, sx }: { label: string; value: string; sx?: any }) => {
            return (
                <Button variant="contained" sx={sx} onClick={() => handleClipboardCopy(value)} data-testid={"copy"}>
                    {label}
                </Button>
            )
        },
        [handleClipboardCopy]
    )

    return {
        ButtonClipboard,
        handleClipboardCopy,
    }
}

export default useClipboard
