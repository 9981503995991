import Icon from "@/components/base/Icon"
import CustomTable from "@/components/widget/table/partials/custom-table"
import { Box, SxProps, Typography, useTheme } from "@mui/material"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

type IconStateProps = {
    state: "FAILED" | "LIVE" | "SCHEDULED" | undefined
}

const IconState = ({ state }: IconStateProps) => {
    const { palette } = useTheme()
    return (
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            {state == "LIVE" && <Icon name="check" data-testid="icon-check" sx={{ color: palette.success.main }} />}
            {state == "SCHEDULED" && (
                <Icon name="clock" data-testid="icon-check" sx={{ color: palette.success.main }} />
            )}
            {state === "FAILED" && (
                <Icon name="alert-triangle" data-testid="icon-alert" sx={{ color: palette.error.main }} />
            )}
            {state === undefined && <Icon name="minus" data-testid="icon-minus" sx={{ color: palette.error.main }} />}
        </Box>
    )
}

type TableSocialCheckProps = {
    rows: {
        name: string
        address: string
        facebook?: IconStateProps
        instagram?: IconStateProps
        google?: IconStateProps
    }[]
    providers?: string[]
}

const SocialsModalTableCheck = ({ rows, providers }: TableSocialCheckProps) => {
    const { t } = useTranslation()
    const columns = useMemo(() => {
        const cols = [
            {
                label: "",
                id: "fiche",
                sx: {
                    padding: "8px 0 0 0",
                    maxWidth: "248px",
                } as SxProps,
                component: ({ row }) => {
                    return !row.name ? (
                        <Typography variant="body1" color="text.primary" whiteSpace={"nowrap"} overflow={"hidden"}>
                            {t("SOCIALS.HIDE_INFORMATION")}
                        </Typography>
                    ) : (
                        <>
                            <Typography
                                variant="body1"
                                color="text.primary"
                                whiteSpace={"nowrap"}
                                textOverflow={"ellipsis"}
                                overflow={"hidden"}
                            >
                                {row.name}
                            </Typography>
                            <Typography
                                variant="overline"
                                fontWeight={600}
                                color="text.secondary"
                                whiteSpace={"nowrap"}
                                textOverflow={"ellipsis"}
                                overflow={"hidden"}
                            >
                                {row.address}
                            </Typography>
                        </>
                    )
                },
            },
            {
                label: <Icon name="facebookSocial" />,
                id: "provider-facebook",
                width: "60px",
                component: ({ row }) => {
                    return <IconState state={row.facebook} />
                },
            },
            {
                label: <Icon name="instagramSocial" />,
                id: "provider-instagram",
                width: "60px",
                component: ({ row }) => {
                    return <IconState state={row.instagram} />
                },
            },
            {
                label: <Icon name="google" />,
                id: "provider-google",
                width: "60px",
                component: ({ row }) => {
                    return <IconState state={row.google} />
                },
            },
        ]

        return cols.filter((col) => {
            if (col.id.startsWith("provider-")) {
                return providers?.includes(col.id.replace("provider-", ""))
            }
            return true
        })
    }, [providers])

    return (
        <CustomTable
            tableCellRootSx={{
                backgroundColor: "unset",
                padding: 0,
                height: "40px",
                textAlign: "center",
            }}
            columns={columns}
            rows={rows}
        />
    )
}

export default SocialsModalTableCheck
