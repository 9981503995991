import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    qrCodeModal: {
        position: "fixed",
        top: 0,
        right: 0,
        width: "100%",
        height: "100%",
        zIndex: 999,
        background: theme.palette.secondary.main,

        "& h2": {
            color: "#FFF",
        },
    },
    headerQrCodeModal: {
        position: "absolute",
        top: 16,
        left: 16,
        right: 16,
        "& svg": {
            fill: theme.palette.primary.main,
            color: theme.palette.primary.main,
        },
    },
}))

export default useStyles
