import { DispatchrService, JarvisService } from ".."
import { ErrorResponse } from "../base"

export interface ReviewMetaType {
    gmb_id?: string
}

export interface LLModelResponse {
    "@context": string
    "@id": string
    "@type": string
    isLimitExceeded: boolean
}

export interface SuggestResponse {
    aiReply: string
}

export interface ReviewReplyType {
    content?: string
    createdAt?: string
    updatedAt?: string
}

export interface MetadataReputationType {
    total: number
    has_review_hidden?: boolean
}

export interface ReviewType {
    platformId?: any
    content?: string
    createdAt?: string
    id?: string
    locationId: string
    locationName: string
    state: string
    status: string
    tag: string
    updatedAt: string
    reply: ReviewReplyType
    meta: ReviewMetaType
    email_support?: string
}

export interface ReputationResponse {
    data: ReviewType[]
    metadata: MetadataReputationType
}

export interface ReputationResponse {
    data: ReviewType[]
    metadata: MetadataReputationType
}
export interface MetaSourceResponse {
    metadata?: any
    data?: {
        id?: string
        name?: string
    }[]
}

interface FetchPlatformSourceInput {
    platform?: string
}

interface ReviewAnwserBodyType {
    status: string
    lang: string
    reply: ReviewReplyType
    replyTo: string
}

interface PublishReviewType {
    success: string
}

class ReputationApi {
    fetchData = async (payload): Promise<ReputationResponse & Partial<ErrorResponse>> => {
        return await DispatchrService.post(`/review`, payload)
    }

    generateIA = async (id): Promise<SuggestResponse & Partial<ErrorResponse>> => {
        return await DispatchrService.get(`/review/${id}/ai-reply`)
    }

    checkLimit = async (model): Promise<LLModelResponse & Partial<ErrorResponse>> => {
        return await JarvisService.get(`/llm-model/${model}/check-limit`)
    }

    fetchPlatformSource = async ({ platform }: FetchPlatformSourceInput = {}): Promise<
        MetaSourceResponse & Partial<ErrorResponse>
    > => {
        const params = platform ? { "meta.source": platform } : {}
        return await DispatchrService.get(`/platform`, params)
    }

    answerReview = async ({
        id,
        body,
    }: {
        id: string
        body: ReviewAnwserBodyType
    }): Promise<ReviewType & Partial<ErrorResponse>> => {
        return await DispatchrService.put(`/review/${id}`, body)
    }

    deleteReview = async ({ id }: { id: string }): Promise<Partial<ErrorResponse>> => {
        return await DispatchrService.delete(`/review/${id}`)
    }

    publishReview = async (token: string): Promise<PublishReviewType & Partial<ErrorResponse>> => {
        return await DispatchrService.put(`/review/${token}`)
    }
}

export default new ReputationApi()
