import { create } from "zustand"

export interface ListEditedAttributes {
    title: string
    attributes: AttributeType[]
}

export interface AttributeType {
    id?: string
    title: string
    options: any
    selected?: any
    type: string
}

export interface AttributChecked {
    display: string
    value: string
    checked: boolean
}

export interface IValuesAttribute {
    value: boolean
    displayName: string
}
export interface ListAttributes {
    meta: {
        gmb_id: string
    }
    label: string
    groupLabel: string
    type: string
    values: IValuesAttribute[]
    id: string
    publishedAt: string
}

export interface IAttributesStore {
    editedAttributes: ListEditedAttributes[]
    listAttributes: ListAttributes[]
    attributIds: { [value: string]: boolean | string | string[] }
    loadedAttributes: boolean
    attributeUnavailable: boolean
}

const initalState: IAttributesStore = {
    editedAttributes: [],
    listAttributes: [],
    attributIds: {},
    loadedAttributes: false,
    attributeUnavailable: false,
}

export interface IAttributesSetter {
    update?: (update: Partial<IAttributesStore>) => void
    flush?: () => void
}

const useAttributesStore = create<IAttributesStore & IAttributesSetter>((set) => ({
    ...initalState,
    update: (update) => set(() => ({ ...update })),
    getInitialState: () => initalState,
    flush: () => set(initalState),
}))

export default useAttributesStore
