import { SkeletonBlocLink } from "@/components/widget/skeleton/bloc-link"
import { Stack, Paper, Skeleton } from "@mui/material"

export const SkeletonBusinessLink = () => {
    return (
        <Paper>
            <Stack height={50} justifyContent="center">
                <Skeleton variant="rectangular" width={300} height={20} />
            </Stack>
            <Paper elevation={2} sx={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <Skeleton variant="rectangular" width={200} height={20} />
                <Skeleton variant="rectangular" width={200} height={50} />
            </Paper>
            <Stack gap={2} flexDirection="row" flexWrap={"wrap"} data-testid="list-bloc-link">
                <SkeletonBlocLink />
                <SkeletonBlocLink />
            </Stack>
        </Paper>
    )
}
