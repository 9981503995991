import { useEffect, useState } from "react"
import Icon from "@/components/base/Icon"
import { Box, Typography } from "@mui/material"
import { withoutImageSx } from "@/components/features/business/list/logo/styles"

type LocationLogoProps = {
    src: string
    width?: string | number
    height?: string | number
    alt?: string
}

const checkImage = (path: string) =>
    new Promise<{ path: string; status: string }>((resolve) => {
        const img = new Image()
        img.onload = () => resolve({ path, status: "ok" })
        img.onerror = () => resolve({ path, status: "error" })
        img.src = path
    })

export const LocationLogo: React.FC<LocationLogoProps> = ({ width = 50, height = 50, src, alt = "Image" }) => {
    const [isValid, setIsValid] = useState<boolean>(true)

    useEffect(() => {
        if (src) {
            checkImage(src).then((result) => {
                setIsValid(result.status === "ok")
            })
        } else {
            setIsValid(false)
        }
    }, [src])

    if (!isValid) {
        return (
            <Box sx={withoutImageSx()}>
                <Icon name="imagePlaceholder" />
                <Typography component="span">Logo</Typography>
            </Box>
        )
    }

    return (
        <img
            src={src}
            alt={alt}
            width={width}
            height={height}
            style={{ objectFit: "cover", display: "block", borderRadius: 8 }}
        />
    )
}
