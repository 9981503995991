import { LocationsApi } from "@/api"
import UserSessionStore from "@/store/user-session"
import { useEffect, useState } from "react"

const useLogic = (id: string) => {
    const [location, setLocation] = useState<any | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const { profile } = UserSessionStore()

    useEffect(() => {
        LocationsApi.getALocation({ id, user_uid: profile?.uid })
            .then((response) => {
                setLocation(response)
            })
            .catch(console.error)
            .finally(() => setIsLoading(false))
    }, [id, profile?.uid])

    return {
        location,
        isLoading,
    }
}

export default useLogic
