import { TableRowsLoader } from "@/components/widget/skeleton/table-rows"
import { hasOwn } from "@/utils"
import { Box, SxProps, Table, TableCell, TableContainer, TableFooter, TablePagination, TableRow } from "@mui/material"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import TablePaginationActions from "../pagination-action"
import TableBodyWrapper from "../table-body-wrapper"
import TableHeadCellWrapper from "../table-head-cell-wrapper"
import TableHeadSort from "../table-head-sort"
import TableHeadWrapper from "../table-head-wrapper"

type Pagination = {
    rowsPerPage: number
    page: number
    totalCount: number
}

type CustomTableOptionalProps = {
    pagination: Pagination
    activeSort: {
        sort_by: string
        sort_order: "asc" | "desc"
    }
    showHeader: boolean
    className: string
    onPageChange: (newPage: number) => void
    onSort: (columnId: string, sorted: boolean) => void
}

type CustomTableProps = {
    columns: any
    rows: any[]
    isLoading?: boolean
    numberRowLoading?: number
    disablePagination?: boolean
    tableCellRootSx?: SxProps
    dataTestid?: string
} & Partial<CustomTableOptionalProps>

type ColumnOptionalProps = {
    width: number | string
    hidden: boolean
    sortable: boolean
    multiline: boolean
    component: ({ column, row, rowIndex }) => React.ReactNode
}

export type Column = Partial<ColumnOptionalProps> & {
    id: string
    label: string
}

const singleLineSx: SxProps = {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
}

const CustomTable = ({
    showHeader = true,
    columns,
    rows,
    pagination,
    onPageChange,
    onSort,
    activeSort,
    className = "",
    isLoading = false,
    numberRowLoading = 5,
    disablePagination = false,
    tableCellRootSx = {} as SxProps,
    dataTestid,
}: CustomTableProps) => {
    const { t } = useTranslation()
    const filteredColumns = useMemo(() => {
        return columns.filter((column) => {
            if (hasOwn(column, "hidden")) {
                return !column.hidden
            }
            return true
        })
    }, [columns])

    return (
        <Box
            sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            className={className}
            data-testid="custom-table"
        >
            <TableContainer>
                <Table aria-label="table" data-testid={dataTestid}>
                    {showHeader && (
                        <TableHeadWrapper tableCellRootSx={tableCellRootSx}>
                            {filteredColumns.map((column, index) => (
                                <TableHeadCellWrapper key={`${column.id}_${index}`} style={{ width: column.width }}>
                                    {column.sortable ? (
                                        <TableHeadSort
                                            label={column.label}
                                            column={column}
                                            onSort={onSort}
                                            activeSort={activeSort}
                                        />
                                    ) : (
                                        column.label
                                    )}
                                </TableHeadCellWrapper>
                            ))}
                        </TableHeadWrapper>
                    )}
                    <TableBodyWrapper>
                        {isLoading && <TableRowsLoader rowsNum={numberRowLoading} cols={filteredColumns.length} />}
                        {!isLoading &&
                            rows &&
                            rows.map((row, index) => {
                                return (
                                    <TableRow tabIndex={-1} key={index} role={`table-row-${index}`}>
                                        {filteredColumns.map((column, indexCol) => {
                                            const value = row[column.id]
                                            return (
                                                <TableCell
                                                    key={`${column.id}_${indexCol}`}
                                                    sx={{
                                                        maxWidth: column.width || "100%",
                                                        ...(!column.multiline && singleLineSx),
                                                        ...column.sx,
                                                    }}
                                                >
                                                    {column.component
                                                        ? column.component({ column, row, rowIndex: index })
                                                        : value}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })}
                    </TableBodyWrapper>

                    <TableFooter>
                        <TableRow>
                            {pagination && !isLoading && rows?.length > 0 && (
                                <TablePagination
                                    labelDisplayedRows={({ from, to, count }) => {
                                        return "" + from + "-" + to + " " + t("USERS.PAGING") + " " + count
                                    }}
                                    count={pagination?.totalCount}
                                    rowsPerPage={pagination?.rowsPerPage}
                                    page={pagination?.page}
                                    onPageChange={(__, newPage: number) => onPageChange(newPage)}
                                    rowsPerPageOptions={[]}
                                    ActionsComponent={(props) => (
                                        <TablePaginationActions {...props} disablePagination={disablePagination} />
                                    )}
                                    sx={{ border: "none" }}
                                />
                            )}
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default CustomTable
