import { ResellerApi, SocialsApi } from "@/api"
import usePageLogic from "@/hooks/social/usePageLogic"
import useQuerySocial from "@/hooks/social/useQuerySocial"
import useNotification from "@/hooks/useNotification"
import { MediaService, SocialService } from "@/services"
import useLocationStore from "@/store/location"
import SocialStore, { initSocialProvider } from "@/store/social"
import SocialPopupStore from "@/store/social-popup"
import { IMedia, IMediaPost, PayloadPost, PostData, PostValuesForm, Provider } from "@/types/socials"
import { isDeepEqual, isValidUrlForSocial } from "@/utils"
import { zodResolver } from "@hookform/resolvers/zod"
import dayjs from "dayjs"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { z } from "zod"

const usePostForm = () => {
    const { t } = useTranslation()
    const { notif } = useNotification()
    const { businesses: listBusiness, businessesTimezone } = useLocationStore()
    const [searchParams] = useSearchParams()
    const [isOpenMedia, setIsOpenMedia] = useState(false)
    const [addLink, setAddLink] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingDraft, setLoadingDraft] = useState(false)
    const { closeModal } = useQuerySocial()
    const { update, tempPost, updateTempPost, errorPost, post, selectedBusinesses, posts, draftPosts, originalPost } =
        SocialStore()
    const { fetchPosts } = usePageLogic()
    const [errorUploadMultipleFormat, setErrorUploadMultipleFormat] = useState<boolean>(false)
    const [errorUpload, setErrorUpload] = useState<boolean>(false)
    const [isInvalidMedias, setIsInvalidMedias] = useState(false)
    const { showPopupContent } = SocialPopupStore()
    const postId = searchParams.get("edit-post")
    const day = searchParams.get("day")
    const isNewPost = searchParams.get("create-post")
    const scheduled = searchParams.get("scheduled")

    const isPostError = useMemo(() => {
        return tempPost?.status === "LIVE_W_ERROR"
    }, [tempPost?.status])

    const isModify = useMemo(() => {
        return tempPost?.isModify
    }, [tempPost?.isModify])

    const locationIdSingle = useMemo(() => {
        if (listBusiness?.length === 1) {
            const locationIds = listBusiness.map((item) => String(item?.value))
            return locationIds
        }
        return []
    }, [listBusiness])

    const isDisableForm = useMemo(() => {
        return SocialService.isDisabledForm({
            postItems: tempPost?.postItems,
            listBusiness,
        })
    }, [tempPost?.postItems, listBusiness])

    const isSplittedPostScheduled = useMemo(() => {
        if (isNewPost) return false
        if (originalPost) {
            return SocialService.isSplittedPostScheduled({
                originalPostItems: originalPost?.postItems,
                postItems: tempPost?.postItems,
            })
        }
        return false
    }, [originalPost, isNewPost, tempPost?.postItems])

    const isEditable = useMemo(() => {
        if (isSplittedPostScheduled) {
            return false
        }

        if (["IN_PROGRESS", "LIVE", "LIVE_W_ERROR"].includes(tempPost?.status)) {
            return false
        }

        if (["SCHEDULED", "DRAFT", "NOW"].includes(tempPost?.status)) {
            return true
        }

        if (tempPost?.postItems?.length === 0) {
            return true
        }

        if (isNewPost) {
            return true
        }

        return false
    }, [tempPost, isNewPost, isSplittedPostScheduled])

    const totalMedia = useMemo(() => {
        return (tempPost?.mediasLocal?.length || 0) + (tempPost?.medias?.length || 0)
    }, [tempPost?.medias, tempPost?.mediasLocal])

    const haveVideo = useMemo(() => {
        return (
            tempPost?.medias?.find((item) => item?.type === "VIDEO") ||
            tempPost?.mediasLocal?.find((item) => item?.type === "VIDEO")
        )
    }, [tempPost?.medias, tempPost?.mediasLocal])

    const schema = z
        .object({
            provider: z.array(z.string()).nonempty(),
            locationIds: z.array(z.string()).nonempty(),
            description: z.string().optional(),
            canAddDate: z.boolean().optional(),
            hour: z.string().optional(),
            date: z.string().optional(),
            url: z.string().optional(),
        })
        .superRefine(({ description, canAddDate, hour, date, locationIds }, { addIssue }) => {
            const isMediaNotEmpty =
                (tempPost?.mediasLocal && tempPost?.mediasLocal.length > 0) ||
                (tempPost?.medias && tempPost.medias.length > 0)

            const isScheduledAtDateNotValid = canAddDate && (!date || !hour)

            if (!isMediaNotEmpty && description === "") {
                addIssue({
                    code: "custom",
                    path: ["description"],
                    message: "Description is required if there are no media items.",
                })
                return true
            }

            if (isScheduledAtDateNotValid) {
                addIssue({
                    code: "custom",
                    path: ["date", "hour"],
                    message: "hour and date is required if add date is checked",
                })
                return true
            }

            if (canAddDate && date !== "") {
                const dateToPublish = dayjs(`${date}T${hour}:00`).format("YYYY-MM-DDTHH:mm:ss")
                const scheduledAtWithUtc = dayjs(dateToPublish)

                const today = SocialService.getMaxDateByLocationIds(locationIds, listBusiness)
                const formattedDay = dayjs(today).format("YYYY-MM-DDTHH:mm:ss")

                if (scheduledAtWithUtc.isBefore(formattedDay, "day")) {
                    addIssue({
                        code: "custom",
                        path: ["date"],
                        message: "date is anterior to today",
                    })
                    return true
                }
                if (scheduledAtWithUtc.isBefore(formattedDay)) {
                    addIssue({
                        code: "custom",
                        path: ["hour"],
                        message: "hour is anterior",
                    })
                    return true
                }
            }
        })

    const { formState, control, setValue, trigger, handleSubmit, reset, getValues, setError } = useForm<PostValuesForm>(
        {
            defaultValues: {
                provider: tempPost?.provider || [],
                locationIds: locationIdSingle?.length > 0 ? locationIdSingle : tempPost?.locationIds ?? [],
                description: tempPost?.description || "",
                canAddDate: tempPost?.canAddDate || false,
                date: tempPost?.date || dayjs().format("YYYY-MM-DD"),
                hour: tempPost?.hour || "",
                url: tempPost?.url || tempPost?.callToAction?.value || "",
            },
            resolver: zodResolver(schema),
            mode: "onChange",
        }
    )

    const formValues = useWatch({ control })

    const validDraft = useMemo(() => {
        const checkMedia = tempPost?.mediasLocal?.length > 0 || tempPost?.medias?.length
        const checkDescription = formValues?.description !== ""
        const checkLocations = formValues?.locationIds?.length > 0
        if ((checkMedia || checkDescription) && checkLocations) {
            return true
        }
        return false
    }, [formValues, tempPost?.mediasLocal, tempPost?.medias])

    const isSingleLocation = useMemo(() => {
        return listBusiness?.length === 1
    }, [listBusiness])

    const isEditableDate = useMemo(() => {
        return !isEditable && !isPostError && !isSplittedPostScheduled
    }, [isEditable, isPostError, isSplittedPostScheduled])

    const minDate = useMemo(() => {
        if (tempPost?.status === "LIVE") {
            return null
        }
        if (formValues.locationIds.length > 0) {
            return SocialService.getMaxDateByLocationIds(formValues.locationIds, listBusiness)
        }
        if (isEditable) {
            return dayjs()
        }
        return null
    }, [isEditable, formValues?.locationIds, listBusiness, tempPost?.status])

    const handleModify = (value: boolean) => {
        updateTempPost({ isModify: value })
    }

    const getShowPostAlert = useCallback(
        (locationIds: string[]) => {
            if (locationIds.length === 0) {
                return false
            }
            return listBusiness?.some((item) => locationIds.includes(item.value as string) && item?.showPostAlert)
        },
        [listBusiness]
    )

    const checkProvider = useCallback(
        (filteredLocationIds: string[], providers) => {
            const providerToRemove = []
            const tempSocialProvider = { ...initSocialProvider }

            for (const business of filteredLocationIds) {
                const locationState = listBusiness?.find((item) => item.value === business)?.locationState || {}

                if (locationState.facebookStatus === "CONNECTED") {
                    tempSocialProvider.facebook = true
                    tempSocialProvider.numberFacebookConnected += 1
                } else if (locationState.facebookStatus === "DISCONNECTED") {
                    tempSocialProvider.oneOfFacebookNotConnected = true
                }
                if (locationState.instagramStatus === "CONNECTED") {
                    tempSocialProvider.instagram = true
                    tempSocialProvider.numberInstagramConnected += 1
                } else if (locationState.instagramStatus === "DISCONNECTED") {
                    tempSocialProvider.oneOfInstagramNotConnected = true
                }
                if (locationState.status !== "DISCONNECTED") {
                    tempSocialProvider.google = true
                    tempSocialProvider.numberGoogleConnected += 1
                } else if (locationState.status === "DISCONNECTED") {
                    tempSocialProvider.oneOfGoogleNotConnected = true
                }
            }
            if (tempSocialProvider && typeof tempSocialProvider === "object") {
                const providers = ["facebook", "google", "instagram"]
                providers.forEach((provider) => {
                    if (!tempSocialProvider[provider]) {
                        providerToRemove.push(provider)
                    }
                })
            } else {
                console.error("tempSocialProvider is invalid:", tempSocialProvider)
            }

            updateTempPost({ socialProvider: tempSocialProvider })
            //Change Form Provider
            const newProviders = providers.filter((provider) => !providerToRemove.includes(provider))

            setValue("provider", newProviders)
        },
        [listBusiness, updateTempPost, setValue]
    )

    const onBusinessChange = useCallback(
        (data) => {
            const filteredLocationIds = data?.map((item) => item.value) || []
            checkProvider(filteredLocationIds, formValues?.provider)
            setValue("locationIds", filteredLocationIds)
            trigger(["locationIds"])
            const showPostAlert = getShowPostAlert(filteredLocationIds)
            update({ showPostAlert, selectedBusinesses: filteredLocationIds })
        },
        [setValue, trigger, getShowPostAlert, checkProvider, formValues?.provider]
    )

    const setDescription = useCallback(
        (messageText) => {
            setValue("description", messageText)
            trigger("description")
        },
        [setValue, trigger]
    )

    const setUrl = useCallback(
        (url: string) => {
            setValue("url", url)
            trigger("url")
        },
        [setValue, trigger]
    )

    const handleModalMedia = useCallback((open: boolean) => {
        setIsOpenMedia(open)
    }, [])

    const handleDeletePost = () => {
        showPopupContent("alert_delete")
    }

    const handleProvider = useCallback(
        (provider: Provider) => {
            let providers = formValues?.provider ?? []
            if (providers.includes(provider)) {
                providers = providers.filter((item) => item !== provider)
            } else {
                providers.push(provider)
            }

            setValue("provider", providers)
            trigger("provider")
        },
        [setValue, trigger, formValues?.provider, tempPost]
    )

    const includeProvider = useCallback(
        (provider: Provider) => {
            return formValues?.provider?.includes(provider)
        },
        [formValues?.provider]
    )

    const isFormValidForInstagram = useMemo(() => {
        if (formValues?.provider?.includes("instagram")) {
            if (!formValues?.description || formValues?.locationIds?.length === 0) {
                return false
            }
            return true
        }
        return true
    }, [formValues?.description, formValues?.locationIds, formValues?.provider])

    const existingMedias = useMemo(
        () => MediaService.groupMediaByType([...(tempPost?.medias ?? []), ...(tempPost?.mediasLocal ?? [])]),
        [tempPost?.medias, tempPost?.mediasLocal]
    )

    const handleMedias = useCallback(
        async (mediaFiles: FileList) => {
            let formatToUpload =
                tempPost?.medias?.length > 0
                    ? tempPost?.medias[0]?.type
                    : tempPost?.mediasLocal?.length > 0
                    ? tempPost.mediasLocal[0]?.type
                    : null

            const resolutions = {
                image: {
                    minWidth: 250,
                    minHeight: 250,
                    maxSize: 10,
                },
                video: {
                    maxSize: 50,
                    minResolutionWidth: 1280,
                    minResolutionHeight: 720,
                },
            }

            if (mediaFiles?.length > 0) {
                setErrorUpload(false)
                setIsInvalidMedias(false)

                const res = await MediaService.mediaUploadList(tempPost?.mediasLocal, mediaFiles, resolutions)
                formatToUpload = formatToUpload ?? res.formatToUpload

                const photoNumber = existingMedias.images.length
                const videoNumber = existingMedias.video.length
                const totalExistingMedia = photoNumber + videoNumber

                if (res.error) {
                    setErrorUpload(true)
                } else {
                    let videos = []
                    let images = []
                    const haveMultipleFormatUploaded = res?.medias?.images?.length > 0 && res?.medias?.video?.length > 0

                    const medias = res.medias
                    if (formatToUpload === "VIDEO") {
                        videos = medias.video.slice(0, 1 - videoNumber)
                    } else {
                        images = medias.images.slice(0, 10 - photoNumber)
                    }

                    const totalMedias = {
                        images: [...res.medias.images, ...existingMedias.images],
                        video: [...res.medias.video, ...existingMedias.video],
                    }

                    if ((totalMedias.video.length > 0 && totalMedias.images.length > 0) || haveMultipleFormatUploaded) {
                        setErrorUploadMultipleFormat(true)
                    }

                    const mediaList = [...images, ...videos].slice(0, 10 - totalExistingMedia)

                    if (mediaList.length > 0) {
                        if (tempPost?.mediasLocal) {
                            const newTab = [...(tempPost?.mediasLocal ?? []), ...(mediaList as File[])]
                            updateTempPost({ mediasLocal: newTab, formHaveChanged: true })
                        } else {
                            updateTempPost({ mediasLocal: mediaList as File[], formHaveChanged: true })
                        }
                    }
                    setIsOpenMedia(false)
                }
            }
        },
        [existingMedias, tempPost?.mediasLocal, tempPost?.medias]
    )

    const haveMisingProvider = useMemo(() => {
        const providers = formValues?.provider || []
        return selectedBusinesses.filter((location) => {
            const business = listBusiness?.find((item) => item.value === location)
            const meta = business?.meta || {}

            return providers.some((provider) => {
                if (provider === "facebook" && !meta.facebook_id) {
                    return true
                }
                if (provider === "instagram" && !meta.instagram_id) {
                    return true
                }
                if (provider === "google" && !meta.gmb_id) {
                    return true
                }
                return false
            })
        })
    }, [formValues?.provider, selectedBusinesses, listBusiness])

    const postShouldHaveCallToAction = useMemo(() => {
        return formValues?.provider?.includes("facebook") || formValues?.provider?.includes("google")
    }, [formValues?.provider])

    const onSubmit = useCallback(
        async (data) => {
            let formInvalid = false
            const checkIfInstagramHaventImage =
                formValues?.provider?.includes("instagram") &&
                (tempPost?.mediasLocal === undefined || tempPost?.mediasLocal?.length === 0) &&
                (tempPost?.medias === undefined || tempPost?.medias?.length === 0)
            setIsInvalidMedias(false)
            setLoading(true)
            if (checkIfInstagramHaventImage) {
                setIsInvalidMedias(true)
                formInvalid = true
            }

            if (formValues?.url && !isValidUrlForSocial(formValues?.url)) {
                setError("url", {
                    type: "custom",
                    message: "url format invalid",
                })
                formInvalid = true
            }
            if (formInvalid) {
                setLoading(false)
                return
            }

            let responseFile = null
            let input: Partial<PayloadPost> = {
                locationIds: data.locationIds,
                description: data.description,
                medias: [],
                ...(data?.url
                    ? {
                          callToAction: {
                              key: "LEARN_MORE",
                              value: data?.url,
                          },
                      }
                    : {}),
            }

            const date = data?.canAddDate ? dayjs(`${data.date}T${data.hour}:00`) : dayjs()
            const dateToPublish = date.format("YYYY-MM-DDTHH:mm:ss")

            if (tempPost?.status !== "LIVE_W_ERROR") {
                const postItems = SocialService.formatPostItems({
                    locationIds: data?.locationIds,
                    dateToPublish,
                    status: tempPost?.status === "LIVE" ? "LIVE" : data?.canAddDate ? "SCHEDULED" : "NOW",
                    providersLocation: SocialService.mappedLocationProvider(data?.provider, listBusiness),
                    oldPostItems: tempPost?.repost ? [] : originalPost?.postItems,
                    listBusiness,
                })

                input.postItems = postItems
            } else {
                const newPostItems = SocialService.formatPostItemsError({
                    postItems: tempPost?.postItems,
                    publishDate: dateToPublish,
                    status: data?.canAddDate ? "SCHEDULED" : "NOW",
                })
                input.postItems = newPostItems
            }

            if (tempPost?.mediasLocal && tempPost?.mediasLocal?.length > 0) {
                const filesToUpload = tempPost?.mediasLocal.map((media) => media.file)
                responseFile = await ResellerApi.postFilesPost({ files: filesToUpload })
            }

            if (responseFile?.error) {
                const messageError =
                    responseFile?.error === "SYSTEM_ERROR.FACEBOOK_ACCOUNT_DISCONNECTED"
                        ? responseFile?.error
                        : "SYSTEM_ERROR.INVALID_REQUEST"
                notif({ message: t(messageError), type: "ERROR" })
            } else {
                let res = null
                let mediasUploaded = tempPost?.medias ?? []
                const formattedMedia = SocialService.formatMedia(responseFile)
                if (responseFile) mediasUploaded = mediasUploaded.concat(formattedMedia)

                input = {
                    ...input,
                    repost: tempPost?.repost,
                    medias: mediasUploaded,
                    postId: responseFile ? responseFile.postId : "",
                }

                if (tempPost?.id && !tempPost?.repost) {
                    res = await SocialsApi.updatePost(tempPost?.id, input)
                } else {
                    res = await SocialsApi.sendPost(input)
                }
                if (res?.id) {
                    await fetchPosts()
                    const status = SocialService.getPostStatus(input.postItems) === "SCHEDULED" ? "scheduled" : "normal"
                    showPopupContent(`success_${status}`)
                    update({
                        tempPost: null,
                        errorPost: false,
                    })
                } else {
                    update({
                        errorPost: true,
                    })
                }
            }
            setLoading(false)
        },
        [
            tempPost?.id,
            tempPost?.mediasLocal,
            tempPost?.medias,
            tempPost?.status,
            tempPost?.repost,
            tempPost?.postItems,
            originalPost,
            notif,
            t,
            fetchPosts,
            update,
            closeModal,
            businessesTimezone,
            listBusiness,
        ]
    )

    const handleSaveInDraft = useCallback(async () => {
        setLoadingDraft(true)
        let responseFile = null
        let dateToPublish = null

        let input: Partial<PayloadPost> = {
            locationIds: formValues.locationIds,
            description: formValues.description,
            medias: [],
            ...(formValues?.url
                ? {
                      callToAction: {
                          key: "LEARN_MORE",
                          value: formValues?.url,
                      },
                  }
                : {}),
        }

        if (formValues?.canAddDate) {
            dateToPublish = dayjs(`${formValues.date}T${formValues.hour}:00`).format("YYYY-MM-DDTHH:mm:ss")
        }

        const postItems = SocialService.formatPostItems({
            locationIds: formValues?.locationIds,
            dateToPublish,
            status: "DRAFT",
            providersLocation: SocialService.mappedLocationProvider(formValues?.provider, listBusiness),
            oldPostItems: tempPost?.postItems,
            listBusiness,
        })

        input.postItems = postItems

        //upload media if exist or get existing media
        if (tempPost?.mediasLocal && tempPost?.mediasLocal?.length > 0) {
            const filesToUpload = tempPost?.mediasLocal.map((media) => media.file)
            responseFile = await ResellerApi.postFilesPost({ files: filesToUpload })
        }

        if (responseFile?.error) {
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        } else {
            let res = null
            let mediasUploaded = tempPost?.medias ?? []
            const formattedMedia = SocialService.formatMedia(responseFile)
            if (responseFile) mediasUploaded = mediasUploaded.concat(formattedMedia)

            input = {
                ...input,
                repost: tempPost?.repost,
                medias: mediasUploaded,
                postId: responseFile ? responseFile.postId : "",
            }

            if (tempPost?.id && !tempPost?.repost) {
                res = await SocialsApi.updatePost(tempPost?.id, input)
            } else {
                res = await SocialsApi.sendPost(input)
            }
            if (res?.id) {
                await fetchPosts()
                reset()
                closeModal()
                update({
                    tempPost: null,
                    errorPost: false,
                })
                notif({ message: t("SOCIALS.SUCCESS_SAVE_DRAFT"), type: "SUCCESS" })
            }
        }
        setLoadingDraft(false)
    }, [
        formValues.locationIds,
        formValues.description,
        formValues.provider,
        tempPost?.mediasLocal,
        tempPost?.medias,
        tempPost?.id,
        tempPost?.repost,
        tempPost?.postItems,
        notif,
        t,
        fetchPosts,
        update,
        closeModal,
        listBusiness,
    ])

    const onChangeDate = useCallback(
        (date: any) => {
            setValue("date", date.format("YYYY-MM-DD"))
            trigger(["hour", "date"])
        },
        [setValue, trigger]
    )

    const handleRemoveMedia = useCallback(
        (media: Partial<IMedia>) => {
            if (media?.url) {
                updateTempPost({
                    medias: tempPost?.medias?.filter((item: IMediaPost) => item?.url !== media?.url),
                    formHaveChanged: true,
                })
            } else {
                const newMedias = tempPost?.mediasLocal?.filter((item) => item !== media)
                updateTempPost({ mediasLocal: newMedias, formHaveChanged: true })
            }
            setErrorUploadMultipleFormat(false)
        },
        [tempPost?.medias, tempPost?.mediasLocal]
    )

    const handleReusePost = (post: Partial<PostData>) => {
        setValue("date", "")
        setValue("canAddDate", false)
        setValue("hour", "")
        updateTempPost({ ...post, status: "NOW", date: "", hour: "", repost: true })
    }

    const getOnePost = useCallback(
        async (postId: string, day?: string) => {
            const originalPost = await SocialsApi.getPost(postId)
            let resp = null
            let otherPostParams = {}
            if (originalPost) {
                // get media and other post params via api by original post
                otherPostParams = SocialService.formatOtherPostParams(originalPost)
            }

            if (posts[day]) {
                resp = posts[day].find((post) => post.id === postId)
            } else {
                resp = draftPosts.find((post) => post.id === postId)
            }

            resp = { ...resp, ...otherPostParams }
            const post = SocialService.formatPostForm({ post: resp, locationIdSingle })
            reset(post)
            const showPostAlert = getShowPostAlert(post.locationIds)
            update({ showPostAlert, post, originalPost, selectedBusinesses: post?.locationIds, errorPost: false })
            updateTempPost({ ...resp, ...post, status: SocialService.getPostStatus(resp.postItems) })
            checkProvider(post?.locationIds, post?.provider)
        },
        [reset, locationIdSingle, getShowPostAlert, checkProvider, posts, draftPosts]
    )

    useEffect(() => {
        if (formValues) {
            const tempValues = { ...formValues }
            updateTempPost({ ...tempValues })
            trigger(["description", "locationIds", "canAddDate", "date", "hour"])
        }
    }, [formValues, trigger])

    useEffect(() => {
        if (locationIdSingle?.length > 0) {
            checkProvider(locationIdSingle, formValues?.provider)
            update({ selectedBusinesses: locationIdSingle })
            setValue("locationIds", locationIdSingle)
        }
        trigger("locationIds")
    }, [locationIdSingle])

    useEffect(() => {
        const isFormChanged = !isDeepEqual(getValues(), post, ["isLastPost"])

        //show input link if link is not empty
        if (formValues.url !== "") {
            setAddLink(true)
        }

        const isNewPostChanged =
            formValues.description !== "" || formValues.url !== "" || tempPost?.mediasLocal?.length > 0
        if (tempPost?.id) {
            if (isFormChanged) {
                updateTempPost({ formHaveChanged: true, ...formValues })
            } else {
                updateTempPost({ formHaveChanged: false })
            }
        } else if (isNewPostChanged) {
            updateTempPost({ formHaveChanged: true, ...formValues })
        } else {
            updateTempPost({ formHaveChanged: false })
        }
        update({ selectedBusinesses: formValues?.locationIds ?? [] })
    }, [formValues, updateTempPost, post, tempPost?.id, tempPost?.mediasLocal])

    useEffect(() => {
        trigger("description")
    }, [tempPost?.medias, tempPost?.mediasLocal, trigger])

    useEffect(() => {
        if (scheduled) {
            setValue("canAddDate", true)
            setValue("date", scheduled)
        }
    }, [scheduled])

    useEffect(() => {
        if (postId && postId !== tempPost?.id && listBusiness) {
            getOnePost(postId, day)
        }
    }, [postId, tempPost?.id, getOnePost, listBusiness, day])

    const handleRemoveLink = () => {
        setValue("url", "")
        setAddLink(false)
    }

    return {
        formState,
        formValues,
        haveMisingProvider,
        isEditable,
        postShouldHaveCallToAction,
        control,
        isOpenMedia,
        loading,
        loadingDraft,
        tempPost,
        errorUpload,
        isFormValidForInstagram,
        validDraft,
        isInvalidMedias,
        onBusinessChange,
        setDescription,
        setUrl,
        handleProvider,
        includeProvider,
        handleMedias,
        onChangeDate,
        handleSubmit,
        onSubmit,
        handleRemoveMedia,
        handleModalMedia,
        setErrorUpload,
        handleSaveInDraft,
        errorPost,
        handleModify,
        isModify,
        handleReusePost,
        isPostError,
        handleDeletePost,
        socialProvider: tempPost?.socialProvider ?? initSocialProvider,
        isSingleLocation,
        setAddLink,
        handleRemoveLink,
        addLink,
        minDate,
        locationIdSingle,
        isEditableDate,
        isDisableForm,
        isSplittedPostScheduled,
        totalMedia,
        haveVideo,
        errorUploadMultipleFormat,
    }
}

export default usePostForm
