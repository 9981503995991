import { QrCodeComponent } from "@/components/base/qr-code"
import { DetailsQrCodeLocation } from "@/components/features/qr-code-link/details"
import { DetailsCardContainer } from "@/components/features/qr-code-link/details/details-card-container"
import { LinkDetails } from "@/components/features/qr-code-link/modal/link-details"
import { QRCodeLinkDetails } from "@/components/features/qr-code-link/modal/qr-code-details"
import TitleWrapper from "@/components/widget/page-container/list-container/title-wrapper"
import { QrLinkDetailsSkeleton } from "@/components/widget/skeleton/details-qr-link"
import { SUSPENDED_LOCATION_STATUS } from "@/data/locationInvalidStatus"
import useClipboard from "@/hooks/useClipboard"
import { MediaService } from "@/services/index.js"
import { Alert, AlertTitle, Stack, Tooltip, Typography } from "@mui/material"
import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"
import { useStyles } from "../style"
import useLogic from "./useLogic"

import useUiPageSession from "@/hooks/useUiPageSession"
import useLocationStore from "@/store/location"

export const QrLinkDetails = () => {
    useUiPageSession({
        title: "MENU.QR_CODE_LINK",
        page: "qr",
    })
    const { locationId } = useParams()
    const { classes } = useStyles()
    const { t } = useTranslation()
    const { businesses } = useLocationStore()
    const [openQrParameters, setOpenQrParameters] = useState<boolean>(false)
    const [openLinkParameters, setOpenLinkParameters] = useState<boolean>(false)
    const { location, isLoading } = useLogic(locationId)
    const [qrCodeGenererated, setQrCodeGenererated] = useState<string>("")
    const { handleClipboardCopy } = useClipboard()
    const isUniqueLocation = useMemo(() => businesses?.length === 1, [businesses])

    const qrValue = useMemo(() => {
        return location !== null ? location?.links?.find((link: any) => link.type === "QRCODE") ?? null : null
    }, [location])

    const link = useMemo(
        () => (location !== null ? location?.links?.find((link: any) => link.type === "LINK") ?? null : null),
        [location]
    )

    const linkNotFound: boolean = useMemo(() => !link, [link])
    const invalidStatus: boolean = useMemo(
        () => SUSPENDED_LOCATION_STATUS.includes(location?.locationState?.status),
        [location?.locationState?.status, SUSPENDED_LOCATION_STATUS]
    )

    const fileName = useMemo(() => {
        const address = location?.address ?? "unknown-address"
        const name = location?.name ?? "unknown-name"

        const cleanValue = (value: string): string =>
            value
                .trim()
                .replace(/\s+/g, "-") // Remplace les espaces par des tirets
                .replace(/[^a-zA-Z0-9-]/g, "") // Supprime les caractères non alphanumériques, sauf les tirets

        const cleanedAddress = cleanValue(address)
        const cleanedName = cleanValue(name)

        return `QRcode-${cleanedAddress}-${cleanedName}`
    }, [location])

    const onGenerateQrCode = useCallback((value: string) => {
        setQrCodeGenererated(value)
    }, [])

    const handleDownloadQRCode = useCallback(() => {
        MediaService.downloadPNG(qrCodeGenererated, `${fileName}.png`)
    }, [qrCodeGenererated, fileName])

    if (isLoading) return <QrLinkDetailsSkeleton title={isUniqueLocation ? t("MENU.QR_CODE_LINK") : undefined} />

    return (
        <Stack gap={2} className={classes.containerQR}>
            {/* Modals begin */}
            <QRCodeLinkDetails
                open={openQrParameters}
                qrValue={qrValue}
                handleClose={() => setOpenQrParameters(false)}
                handleDownloadQRCode={handleDownloadQRCode}
                location={location}
                data-testid="qr-code-details-modal"
                fileName={fileName}
            />
            <LinkDetails
                open={openLinkParameters}
                handleClose={() => setOpenLinkParameters(false)}
                linkLocation={link}
                data-testid="link-details-modal"
            />
            {/* Modals end */}

            <Stack style={{ cursor: "pointer" }} data-testid="navigate-back">
                {isUniqueLocation ? (
                    <TitleWrapper title={t("MENU.QR_CODE_LINK")} />
                ) : (
                    <DetailsQrCodeLocation name={location?.name} />
                )}
            </Stack>
            {/* Closable Alert begin*/}
            {location && (linkNotFound || invalidStatus) && (
                <Alert
                    sx={{ flexDirection: "row", gap: 1 }}
                    severity="error"
                    variant="filled"
                    data-testid="alert-qr-code-not-exist"
                >
                    <AlertTitle>
                        {t(
                            linkNotFound
                                ? "REQUEST_OPINION.STANDALONE.STATUS_INVALID"
                                : "REQUEST_OPINION.STANDALONE.STATUS_INVALID_WARNING"
                        )}
                    </AlertTitle>
                    <Stack gap={1}>
                        <Typography variant="body2" color={"#FFF"}>
                            {t("REQUEST_OPINION.STANDALONE.STATUS_INVALID_DESC")}
                        </Typography>
                    </Stack>
                </Alert>
            )}
            {/* Closable Alert end */}
            <Stack className="section-qr-link" data-testid="details-container">
                <DetailsCardContainer
                    title={t("QR_CODE_LINK.QR")}
                    parameterAction={() => {
                        setOpenQrParameters(true)
                    }}
                    submitButtonLabel={t("GENERAL.ACTION.DOWNLOAD")}
                    submitButtonAction={handleDownloadQRCode}
                    disableSubmitButton={!qrValue}
                    data-testid="qr-details-card"
                >
                    <QrCodeComponent qrValue={qrValue?.value ?? ""} onGenerateQrCode={onGenerateQrCode} />
                </DetailsCardContainer>
                <DetailsCardContainer
                    title={t("QR_CODE_LINK.DETAILS.SHORT_LINK")}
                    parameterAction={() => {
                        setOpenLinkParameters(true)
                    }}
                    submitButtonLabel={t("GENERAL.ACTION.COPY")}
                    submitButtonAction={() => handleClipboardCopy(link?.value)}
                    disableSubmitButton={!link}
                    data-testid="short-link-card"
                >
                    <Link
                        to={link?.value ?? ""}
                        style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: "#0180FF",
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                        data-testid="short-link"
                    >
                        <Tooltip title={link?.value}>
                            <Typography
                                component="span"
                                color="primary"
                                sx={{ textOverflow: "ellipsis", display: "block", overflow: "hidden" }}
                            >
                                {link?.value ?? ""}
                            </Typography>
                        </Tooltip>
                    </Link>
                </DetailsCardContainer>
            </Stack>
        </Stack>
    )
}
