import { ListItem, Skeleton, Stack } from "@mui/material"

export const SkeletonListingItem = () => {
    return (
        <ListItem sx={{ flexDirection: "row", gap: 2 }} data-testid="item-listing">
            <Skeleton variant="circular" width={60} height={60} sx={{ minWidth: "60px" }} />
            <Skeleton variant="rectangular" width={"80%"} height={24} />
            <Stack gap={2}>
                <Skeleton variant="rectangular" width={60} height={26} />
                <Skeleton variant="rectangular" width={60} height={26} />
            </Stack>
        </ListItem>
    )
}
