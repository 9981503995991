import useLogic from "@/hooks/select-time-field/useLogic"
import { Box, ButtonBase, ClickAwayListener, Popper, Typography } from "@mui/material"
import { TimeValidationError, TimeView } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { TimeField } from "@mui/x-date-pickers/TimeField"
import { Dayjs } from "dayjs"
import { ButtonBaseSx, PopperSx, TimeFieldSx } from "./style"

interface SelectTimeFieldInput {
    defaultValue?: string
    dataE2e?: string
    onChange?: (value: Dayjs) => void
    onError?: (error: TimeValidationError, value: Dayjs) => void
    shouldDisableTime?: (value: Dayjs, view: TimeView) => boolean
    onClickOutside?: (value: Dayjs) => void
}

const SelectTimeField = (props: SelectTimeFieldInput) => {
    const {
        anchorEl,
        open,
        showAa,
        value,
        inputRef,
        options,
        field_uid,
        handleOnChange,
        handleClickAway,
        handleTimeClick,
        handleOnFocus,
        handleOnInput,
    } = useLogic({
        onChange: props?.onChange,
        defaultValue: props?.defaultValue,
        onClickOutside: props?.onClickOutside,
    })

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimeField
                        ref={anchorEl}
                        onChange={handleOnChange}
                        onFocus={handleOnFocus}
                        onInput={handleOnInput}
                        sx={TimeFieldSx}
                        ampm={showAa}
                        value={value || null}
                        variant="filled"
                        inputRef={(input: HTMLInputElement | null) => {
                            if (input && typeof inputRef?.current?.focus === "function") inputRef.current = input
                        }}
                        hiddenLabel
                        inputProps={{
                            placeholder: "",
                        }}
                        data-e2e={props?.dataE2e || ""}
                        onError={props?.onError}
                        shouldDisableTime={(value: any, view) => {
                            if (typeof props?.shouldDisableTime === "function") {
                                return props.shouldDisableTime(value, view)
                            }
                            return false
                        }}
                    />
                    {anchorEl?.current && options?.length > 0 && (
                        <Popper disablePortal open={open} anchorEl={anchorEl.current} sx={PopperSx}>
                            {options?.map((time: string, index: number) => (
                                <ButtonBase
                                    component="div"
                                    key={`${String(index)}`}
                                    sx={ButtonBaseSx}
                                    onClick={() => handleTimeClick(time)}
                                    className={`time_${time.replace(":", "_").replace(" ", "_")}_${field_uid}`}
                                >
                                    <Typography fontSize={"16px"} lineHeight={"24px"}>
                                        {time}
                                    </Typography>
                                </ButtonBase>
                            ))}
                        </Popper>
                    )}
                </LocalizationProvider>
            </Box>
        </ClickAwayListener>
    )
}

export default SelectTimeField
