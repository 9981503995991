import BaseSelect from "@/components/base/select"
import { useTranslation } from "react-i18next"
import { styleSx } from "./style"

const SelectPeriodeType = ({ lists, selected, selectionChange }) => {
    const { t } = useTranslation()
    return (
        <BaseSelect
            options={lists || []}
            label={t("LOCAL_PRESENCE.FILTER.TIME")}
            onItemClicked={(item) => selectionChange(item)}
            selected={selected}
            defaultValue={selected?.value}
            sx={styleSx}
            id="select-business-reputation"
        />
    )
}

export default SelectPeriodeType
