import { DatePickerCustom } from "@/components/base/date-picker"
import BaseSelect from "@/components/base/select"
import UiStore from "@/store/ui"
import { generateTimeSlots, getNextAvailableTimes } from "@/utils"
import { Stack } from "@mui/material"
import dayjs from "dayjs"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import useStyles from "./style"
export const ProgrammingDateBloc = ({ onChangeDate, control, errors, disabled, minDate, locationIds, date, hour }) => {
    const { t } = useTranslation()
    const { classes } = useStyles()
    const { preferredLanguage } = UiStore()

    const selectedDate = useMemo(() => {
        if (date && locationIds.length > 0) {
            const isSameAsToday = dayjs(date).isSame(minDate, "day")

            if (isSameAsToday) {
                return minDate
            } else {
                return dayjs(date)
            }
        }
        return null
    }, [date, minDate, locationIds])

    return (
        <Stack className={classes.blocSection} gap={2} width={"100%"} data-testid="date-hour">
            <DatePickerCustom
                desktopModeMediaQuery="@media anypointer:fine"
                label={t("INPUT.DATE_LABEL")}
                preferredLanguage={preferredLanguage}
                sx={{ width: "100%" }}
                defaultValue={date ? dayjs(date) : null}
                readOnly={true}
                shrink={date ? true : false}
                showLabelStar={true}
                value={date ? dayjs(date) : null}
                onChange={(value) => onChangeDate(value)}
                minDate={minDate}
                disabled={disabled}
                noBottomBorder={false}
                closeOnSelect={true}
                error={!!errors?.date}
            />
            <BaseSelect
                optionsAvailable={getNextAvailableTimes(selectedDate) || []}
                options={generateTimeSlots("00:00", "24:00", 30)}
                controlName="hour"
                label={t("INPUT.HOUR_LABEL")}
                defaultValue={hour}
                control={control}
                disabled={disabled}
                formStateError={!!errors?.hour}
            />
        </Stack>
    )
}
