import { KpiCampaignResponse, KpiReputationResponse, KpiResponse } from "@/types/statistic"
import { DispatchrService } from ".."

export type FetchKpiInput = {
    locationsId?: string[]
    start: string
    type: string
    previous_period: boolean
}

class StatisticsApi {
    fetchKpiLocal = async (payload: FetchKpiInput): Promise<KpiResponse> => {
        return await DispatchrService.post(`/kpi/local-profil`, payload)
    }

    fetchKpiReview = async (payload: FetchKpiInput): Promise<KpiReputationResponse> => {
        return await DispatchrService.post(`/kpi/review`, payload)
    }

    fetchKpiCampaign = async (payload: FetchKpiInput): Promise<KpiCampaignResponse> => {
        return await DispatchrService.post(`/kpi/campaign`, payload)
    }
}

export default new StatisticsApi()
