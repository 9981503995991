import AddMediaModal from "@/components/widget/add-media-modal"
import Icon from "@/components/base/Icon"
import { Box, Stack, Typography } from "@mui/material"
import { ChangeEvent, useCallback, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useStyles } from "./styles"

export const BoxUploadImage = ({
    mediaNumber = 0,
    handleMedias,
    errorUpload,
    isOpen,
    handleModalMedia,
    setErrorUpload,
    error = false,
}: {
    isOpen: boolean
    mediaNumber: number
    handleMedias: (files: FileList) => void
    errorUpload?: boolean
    handleModalMedia: (open: boolean) => void
    setErrorUpload: (value: boolean) => void
    error?: boolean
}) => {
    const { t } = useTranslation()
    const { classes } = useStyles({ props: { error, unique: Number(mediaNumber) === 0 } })
    const inputRef = useRef<HTMLInputElement>()
    const handleImage = useCallback(
        async (mediaFiles: FileList) => {
            if (mediaFiles?.length > 0) {
                handleMedias(mediaFiles)
            }
        },
        [handleMedias]
    )

    const inputChangeHandler = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            handleImage(e.target.files)
        },
        [handleImage]
    )

    return (
        <>
            <Box
                className={classes.imageFrame}
                data-testid="add-media"
                onClick={() => handleModalMedia(true)}
                sx={{ cursor: "pointer" }}
            >
                <Stack flexDirection={"row"} gap={1}>
                    <Icon name="imagePlus" />
                    {mediaNumber === 0 && <Typography component={"span"}>{t("MEDIA.ADD")}</Typography>}
                </Stack>
            </Box>

            <AddMediaModal
                handleImage={handleImage}
                open={isOpen}
                onClose={() => {
                    setErrorUpload(false)
                    handleModalMedia(false)
                }}
                inputRef={inputRef}
                inputChangeHandler={inputChangeHandler}
                error={errorUpload}
                type="social"
            />
        </>
    )
}
