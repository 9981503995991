import GooglePlaceAutocomplete from "@/components/widget/autocomplete/google-place-autocomplete"
import Icon from "@/components/base/Icon"
import { TitleWrapper } from "@/components/base/title-wrapper"
import EditZoneContainer from "@/components/features/business/form-container/edit/edit-zone"
import FormAction from "@/components/features/business/form-container/edit/form-action"
import useLogic from "@/hooks/businesses/edit-zone/useLogic"
import { PageProps } from "@/types/page"
import { Box, Paper, Typography } from "@mui/material"

const EditZone = ({ onClose, open }: PageProps) => {
    const { t, handlePreviousButton, onSubmit, handleSelect, handleCancel, data, loading } = useLogic()
    return (
        <EditZoneContainer open={open} onClose={onClose}>
            <TitleWrapper handlePreviousButton={handlePreviousButton} title={t("BUSINESSES.EDIT_AREAS_SERVED.TITLE")} />
            <form onSubmit={onSubmit}>
                <Box className="inputs-container">
                    <Paper className="inputs-wrapper">
                        <Typography variant="h2">
                            <Icon name="map1" />
                            {t("BUSINESSES.EDIT_AREAS_SERVED.SERVICE_AREA")}
                        </Typography>
                        <Box className="wrapper">
                            <GooglePlaceAutocomplete
                                label={t("BUSINESSES.EDIT_AREAS_SERVED.SERVICE_AREA")}
                                onSelect={handleSelect}
                                field={data}
                                // types={[
                                //     "locality",
                                //     "point_of_interest",
                                //     "country",
                                //     "administrative_area_level_1",
                                //     "administrative_area_level_2",
                                // ]}
                                types={["geocode"]}
                                maxItems={20}
                                helperText={`${data?.length}/20`}
                            />
                        </Box>
                    </Paper>
                </Box>
                <FormAction handleCancel={handleCancel} isLoading={loading} isUpdating isDisabled={loading} />
            </form>
        </EditZoneContainer>
    )
}

export default EditZone
