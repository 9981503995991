import Icon from "@/components/base/Icon"
import { CookiesService } from "@/services"
import IconNames from "@/types/icons"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Accordion, AccordionDetails, AccordionSummary, Box, SxProps, Typography } from "@mui/material"
import { Fragment } from "react"
import useStyles from "./styles"

interface TitleWrapperProps {
    title: string
    pageIcon?: IconNames
    alert?: {
        title: string
        contents: string
    }
    loading?: boolean
    iconSx?: SxProps
}

const TitleWrapper = ({ title, pageIcon, alert, loading, iconSx }: TitleWrapperProps) => {
    const { classes } = useStyles()

    return (
        <Box className={classes.wrapper}>
            <Box className="title">
                <Icon name={pageIcon} sx={iconSx} />
                <Typography data-testid="user-title" variant="h1">
                    {title}
                </Typography>
            </Box>
            {!loading && (
                <Fragment>
                    {alert && (
                        <Accordion
                            className={classes.MuiAccordion}
                            defaultExpanded={CookiesService.get("expandGoogleBanner") ?? true}
                            onChange={(event: React.SyntheticEvent, expanded: boolean) => {
                                CookiesService.set({ expandGoogleBanner: expanded })
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: "#5F2120" }} />}
                                className="summary"
                                aria-controls="panel-content"
                                id="panel-header"
                            >
                                <Icon size={22} sx={{ marginRight: "18px" }} name="google" />
                                <Typography className="title-accordion">{alert.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails className="detail">
                                <Typography
                                    className="subtitle-accordion"
                                    dangerouslySetInnerHTML={{ __html: alert.contents }}
                                />
                            </AccordionDetails>
                        </Accordion>
                    )}
                </Fragment>
            )}
        </Box>
    )
}

export default TitleWrapper
