import CampaignSuccess from "@/components/widget/success/campaign"
import { PageProps } from "@/types/page"
import { Drawer } from "@mui/material"
import useStyles from "../styles"

interface SuccessPageProps extends PageProps {
    icon?: string
    message?: string
}

const SuccessPage = ({ onClose, open, icon, message }: SuccessPageProps) => {
    const { classes } = useStyles()

    return (
        <Drawer
            disableScrollLock
            hideBackdrop
            anchor={"right"}
            open={open}
            onClose={onClose}
            transitionDuration={600}
            disableEscapeKeyDown
            PaperProps={{
                className: classes.containerSuccess,
            }}
            sx={{
                zIndex: 1,
                ".campaign-success": {
                    margin: " auto",
                    display: "flex",
                },
            }}
        >
            <CampaignSuccess onClose={onClose} icon={icon} message={message} />
        </Drawer>
    )
}

export default SuccessPage
