import Icon from "@/components/base/Icon"
import useResponsive from "@/hooks/use-responsive"
import { AdvancedCard } from "@/components/widget/advanced-card"
import { Button, Stack, Typography } from "@mui/material"
import { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"

type DetailsCardContainerProps = PropsWithChildren<{
    title: string
    parameterAction: () => void
    submitButtonLabel: string
    submitButtonAction: () => void
    disableSubmitButton?: boolean
}>

export const DetailsCardContainer: React.FC<DetailsCardContainerProps> = (props) => {
    const { t } = useTranslation()
    const { isMobile } = useResponsive()
    const header = (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h2">{props.title}</Typography>
            <Button
                type="submit"
                sx={{
                    color: "text.secondary",
                    textTransform: "none",
                }}
                startIcon={<Icon name="setting" />}
                onClick={props.parameterAction}
                disabled={props.disableSubmitButton}
                data-testid="parameter-button"
            >
                {t("GENERAL.ACTION.PARAMETERS")}
            </Button>
        </div>
    )

    const footer = (
        <Stack
            alignItems={"center"}
            justifyContent={"center"}
            style={{
                padding: 16,
                width: "100%",
            }}
        >
            <Button
                variant="contained"
                style={{ width: "100%" }}
                onClick={props.submitButtonAction}
                disabled={props.disableSubmitButton}
                data-testid="submit-button"
            >
                {props.submitButtonLabel}
            </Button>
        </Stack>
    )

    return (
        <Stack data-testid="details-card-container">
            <AdvancedCard
                sx={{
                    width: isMobile ? "100%" : 364.33,
                    height: "fit-content",
                }}
                header={header}
                footer={footer}
            >
                {props.children}
            </AdvancedCard>
        </Stack>
    )
}
