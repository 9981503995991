import BusinessAutocomplete from "@/components/widget/autocomplete/business-autocomplete"
import Icon from "@/components/base/Icon"
import { QrCodeComponent } from "@/components/base/qr-code"
import { SUSPENDED_LOCATION_STATUS } from "@/data/locationInvalidStatus"
import useLocationStore from "@/store/location"
import { SelectType } from "@/types/select"
import { Alert, AlertTitle, IconButton, Paper, Stack, Typography } from "@mui/material"
import classNames from "classnames"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import useStyles from "./styles"

const QRcodeStandaloneModal = ({ open = false, onClose }) => {
    const { businesses } = useLocationStore()
    const [selectedBusiness, setSelectedBusiness] = useState("")
    const { classes } = useStyles()
    const { t } = useTranslation()
    // Obtenir l'entreprise sélectionnée ou la première disponible
    const defaultValue = useMemo(() => {
        return selectedBusiness || businesses?.[0]?.value || ""
    }, [businesses, selectedBusiness])

    // Filtrer l'entreprise courante selon le selectedBusiness
    const currentBusiness = useMemo(() => {
        return businesses?.find((business) => business?.value === selectedBusiness)
    }, [businesses, selectedBusiness])
    // Vérifier si le QR code n'existe pas
    const qrCodeDoNotExist = useMemo(() => {
        if (!currentBusiness) return false
        return !currentBusiness.links?.length || currentBusiness.links === undefined
    }, [currentBusiness])

    // Vérifier si le QR code pourrait provoquer une erreur
    const qrCodeCouldMakeError = useMemo(() => {
        if (!currentBusiness) return false
        return SUSPENDED_LOCATION_STATUS.includes(currentBusiness.locationState?.status)
    }, [currentBusiness, SUSPENDED_LOCATION_STATUS])

    const qrCodeValue = useMemo(() => {
        if (!currentBusiness) return ""

        const url = currentBusiness.links.find((link) => link.type === "QRCODE")
        if (url) return url.value

        return ""
    }, [currentBusiness])

    useEffect(() => {
        if (!selectedBusiness) {
            setSelectedBusiness(String(defaultValue))
        }
    }, [defaultValue])

    return (
        <>
            {open && (
                <Stack className={classNames(classes.qrCodeModal)} flexDirection={"column"} justifyContent={"center"}>
                    <Stack
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        className={classNames(classes.headerQrCodeModal)}
                    >
                        <Typography variant="h2">Code QR</Typography>
                        <IconButton onClick={onClose}>
                            <Icon name="close" />
                        </IconButton>
                    </Stack>
                    <Stack padding={2} gap={2}>
                        <Paper
                            sx={{
                                padding: "16px!important",
                                minHeight: 494,
                                justifyContent: businesses.length > 1 ? "flex-start" : "center",
                            }}
                        >
                            <Stack
                                gap={businesses?.length > 1 ? 1 : 0}
                                sx={{
                                    height: "100%",
                                }}
                            >
                                <Stack
                                    gap={0}
                                    sx={{
                                        visibility: businesses?.length > 1 ? "visible" : "hidden",
                                        height: businesses?.length > 1 ? "auto" : "0",
                                        minHeight: businesses?.length > 1 ? "auto" : "0!important",
                                    }}
                                ></Stack>

                                <BusinessAutocomplete
                                    defaultValue={String(defaultValue)}
                                    label={t("CAMPAIGN.BUSINESS")}
                                    selectionChange={(ids: SelectType) => {
                                        setSelectedBusiness(String(ids?.value))
                                    }}
                                    multiple={false}
                                />

                                <Stack gap={2} justifyContent={!qrCodeValue ? "flex-start" : "center"} height={"100%"}>
                                    {(qrCodeDoNotExist || qrCodeCouldMakeError) && (
                                        <>
                                            <Alert
                                                sx={{ flexDirection: "row", gap: 1 }}
                                                severity="error"
                                                variant="filled"
                                            >
                                                <AlertTitle>
                                                    {t(
                                                        qrCodeDoNotExist
                                                            ? "REQUEST_OPINION.STANDALONE.STATUS_INVALID"
                                                            : "REQUEST_OPINION.STANDALONE.STATUS_INVALID_WARNING"
                                                    )}
                                                </AlertTitle>
                                                <Stack gap={1}>
                                                    <Typography variant="body2" color={"#FFF"}>
                                                        {t("REQUEST_OPINION.STANDALONE.STATUS_INVALID_DESC")}
                                                    </Typography>
                                                </Stack>
                                            </Alert>
                                        </>
                                    )}

                                    {!qrCodeDoNotExist && !!qrCodeValue && (
                                        <Stack
                                            gap={2}
                                            justifyContent={"center"}
                                            sx={{
                                                marginTop: "12px",
                                            }}
                                        >
                                            <QrCodeComponent qrValue={qrCodeValue} width={250} height={250} />
                                            {/* {currentBusiness?.logo && (
                                                <img
                                                    src={currentBusiness?.logo}
                                                    alt={currentBusiness?.label}
                                                    style={{
                                                        maxWidth: 116,
                                                        margin: "0 auto",
                                                    }}
                                                />
                                            )} */}
                                        </Stack>
                                    )}
                                </Stack>
                            </Stack>
                        </Paper>
                    </Stack>
                </Stack>
            )}
        </>
    )
}

export default QRcodeStandaloneModal
