import { SkeletonBlocChip } from "@/components/widget/skeleton/bloc-chip"
import { Paper, Skeleton, Stack } from "@mui/material"
import { useStyles } from "./styles"

export const SkeletonBusinessServices = () => {
    const { classes } = useStyles()
    return (
        <Paper className={classes.container}>
            <Skeleton variant="rectangular" width={200} height={20} />
            <Stack className="list-bloc" data-testid="list-bloc-skeleton">
                <SkeletonBlocChip />
                <SkeletonBlocChip />
            </Stack>
            <SkeletonBlocChip />
        </Paper>
    )
}
