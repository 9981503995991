import Icon from "@/components/base/Icon"
import { SUSPENDED_LOCATION_STATUS } from "@/data/locationInvalidStatus"
import useResponsive from "@/hooks/use-responsive"
import { AdvancedCard } from "@/components/widget/advanced-card"
import { Box, Button, Chip, Stack, Typography, styled } from "@mui/material"
import { ReactNode, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { LocationLogo } from "../location-logo"

type LocationCardProps = {
    location: any
    onClick?: () => void
    isMobile?: boolean
    showWarning?: boolean
}

const QrCodeButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.main,
    textTransform: "none",
}))

const TruncatedTypography = styled(Typography)({
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%",
})

export const LocationCard: React.FC<LocationCardProps> = ({ onClick, location }) => {
    const link = useMemo(
        () => (location !== null ? location?.links?.find((link: any) => link.type === "LINK") ?? null : null),
        [location]
    )
    const linkNotFound: boolean = useMemo(() => !link, [link])

    const invalidStatus: boolean = useMemo(
        () => SUSPENDED_LOCATION_STATUS.includes(location?.locationState?.status),
        [location?.locationState?.status, SUSPENDED_LOCATION_STATUS]
    )
    const { isMobile } = useResponsive()

    const { t } = useTranslation()
    const footer: ReactNode = (
        <Stack
            gap={isMobile ? 1 : 2}
            justifyContent={
                isMobile && (invalidStatus || linkNotFound)
                    ? "end"
                    : !invalidStatus && !linkNotFound
                    ? "end"
                    : "space-between"
            }
            flexDirection={!isMobile ? "row" : "column"}
            alignItems={isMobile ? "flex-end" : "center"}
            sx={{
                padding: 2,
                width: "100%",
            }}
        >
            <>
                {(linkNotFound || invalidStatus) && (
                    <Chip
                        color="error"
                        variant="filled"
                        icon={<Icon name="warning" fill="white" />}
                        label={t(
                            linkNotFound
                                ? "QR_CODE_LINK.ERRORS.ERROR_STATUS_LABEL.UNAVALAIBLE"
                                : "QR_CODE_LINK.ERRORS.ERROR_STATUS_LABEL.ATTENTION_REQUIRED"
                        )}
                        sx={{
                            padding: "4px 8px",
                        }}
                        data-testid="chip"
                    />
                )}
            </>
            <QrCodeButton startIcon={<Icon name="setting" />}>{t("MENU.QR_CODE_LINK")}</QrCodeButton>
        </Stack>
    )

    return (
        <AdvancedCard
            footer={footer}
            sx={{
                height: "auto",
                width: "100%",
                cursor: "pointer",
                gap: 0,
                "&:hover": {
                    boxShadow: 4,
                },
                "@media (max-width: 767px)": {
                    gap: 0,
                    padding: 0,
                },
            }}
            onClick={onClick}
        >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, height: "80px" }}>
                <LocationLogo src={location?.logo} alt={location?.name} width={72} height={72} />
                <Box sx={{ flex: 1 }}>
                    <TruncatedTypography variant="subtitle1" sx={{ fontWeight: "bold", mb: 0.5 }}>
                        {location?.name}
                    </TruncatedTypography>
                    <TruncatedTypography variant="body2" color="text.secondary" className="ellipse">
                        {location?.address}
                    </TruncatedTypography>
                </Box>
            </Box>
        </AdvancedCard>
    )
}
