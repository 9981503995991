import Icon from "@/components/base/Icon"
import { TitleWrapper } from "@/components/base/title-wrapper"
import EditModelContainer from "@/components/features/campaign/edit-model"
import useStyles from "@/components/features/campaign/styles"
import { PageProps } from "@/types/page"
import { Box, Chip, Paper, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import SmsPreview from "@/components/features/campaign/view/SmsPreview"
import CustomTable, { Column } from "@/components/widget/table/partials/custom-table"
import { useCallback, useEffect, useMemo, useState } from "react"
import OverviewsStatistique from "@/components/features/campaign/overviews/statistique"
import { IReviews } from "@/types/campaign"
import { checkDisplayOfDate } from "@/utils"
import UiStore from "@/store/ui"
import { CampaignApi } from "@/api"
import CampaignReviewsStore from "@/store/campaign-reviews"
import EmailPreview from "@/components/features/campaign/view/EmailPreview"
import { iconStatus } from "@/data/constants"
import { ActionBtnRight } from "@/components/features/campaign/btn-edit-right"
import { SocialService } from "@/services"
import useLocationStore from "@/store/location"
import { BlocEditProgramming } from "../../../components/features/campaign/bloc-edit-programming"
import useNotification from "@/hooks/useNotification"
import useCampaignCredit from "@/hooks/campaign/useCampaignCredit"

type ColumnProps = Column & {
    id: string
}

interface ListRecipientProps extends PageProps {
    review: IReviews
    handleGetAllReviews: (page: number) => void
}

const ListRecipient = ({ onClose, open, review, handleGetAllReviews }: ListRecipientProps) => {
    const { t } = useTranslation()
    const { classes } = useStyles()
    const { notif } = useNotification()
    const { businesses } = useLocationStore()
    const [loading, setLoading] = useState<boolean>(false)
    const [isLoadingCancel, setIsLoadingCancel] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const { reviewDetails, update, pagination, disablePagination } = CampaignReviewsStore()
    const { preferredLanguage } = UiStore()
    const { fetchCredit } = useCampaignCredit()

    const minDate = useMemo(() => {
        return SocialService.getMaxDateByLocationIds([review.location_id], businesses)
    }, [review?.location_id, businesses])

    const handleModify = (value) => {
        setIsEdit(value)
    }

    const handleCancel = useCallback(async () => {
        setIsLoadingCancel(true)
        const res = await CampaignApi.cancelCampaign({ campaignId: review.campaign_id, type: review.type })
        await fetchCredit(review?.location?.companyId)
        if (res?.data === "success") {
            await handleGetAllReviews(0)
            onClose()
        } else {
            notif({ message: t("SYSTEM_ERROR.CAMPAIGN_ALREADY_PUBLISHED"), type: "ERROR" })
        }
        setIsLoadingCancel(false)
    }, [review?.campaign_id, review?.type, review?.location])

    const handleGetSingleReview = useCallback(async () => {
        if (!reviewDetails?.pagination?._page) {
            setLoading(true)
        } else {
            update({
                disablePagination: true,
            })
        }
        const res = await CampaignApi.getSingleReview({
            campaignId: review.campaign_id,
            type: review?.type,
            _page: reviewDetails?.pagination?._page ?? 0,
            _limit: 10,
        })

        if (res?.data) {
            update({
                reviewDetails: {
                    recipients: res.data.details,
                    message: res.data.message,
                    pagination: { ...res.metadata, _page: res.metadata._page },
                },
            })
        }
        update({
            disablePagination: false,
        })
        setLoading(false)
    }, [review, reviewDetails?.pagination?._page])

    useEffect(() => {
        handleGetSingleReview()
    }, [handleGetSingleReview])

    const handleChangePage = useCallback(
        (newPage: number) => {
            update({
                reviewDetails: {
                    recipients: reviewDetails.recipients,
                    message: reviewDetails.message,
                    pagination: { ...reviewDetails.pagination, _page: newPage + 1 },
                },
            })
        },
        [pagination, reviewDetails]
    )

    const date = useMemo(() => {
        if (review?.scheduled_at) return reviewDetails?.recipients[0]?.scheduled_at ?? review.scheduled_at
        return review?.created
    }, [reviewDetails, review?.created, review?.scheduled_at, review?.status])

    const columns: readonly ColumnProps[] = useMemo(() => {
        return [
            {
                id: "contact",
                label: t("CAMPAIGN.RECIPIENTS"),

                component: ({ row, column }) => {
                    return <Box key={`${row.uid}_${column.id}`}>{row?.contact}</Box>
                },
            },
            {
                id: "status",
                label: "",
                width: "144px",
                component: ({ row, rowIndex }) => {
                    return (
                        <Stack key={rowIndex} gap={1} flexDirection={"row"} p={0}>
                            <Chip
                                label={t(`CAMPAIGN.${row?.status === "FAILED" ? "SENT" : row?.status}`)}
                                color="default"
                                variant="outlined"
                                data-testid="chip-status"
                                icon={
                                    <Icon
                                        name={iconStatus?.[row?.status === "FAILED" ? "SENT" : row?.status]?.key}
                                        sx={{
                                            width: 24,
                                            color: iconStatus?.[row?.status === "FAILED" ? "SENT" : row?.status]?.color,
                                        }}
                                    />
                                }
                                sx={{ p: 0.5, "& .MuiChip-label": { p: "3px 6px" } }}
                            />
                        </Stack>
                    )
                },
            },
        ]
    }, [])

    return (
        <EditModelContainer open={open} onClose={onClose}>
            <TitleWrapper handlePreviousButton={onClose} title={t("CAMPAIGN.NEW_OPINION")} className="title-overview" />
            <Stack className={classes.blocRecipient}>
                <Stack className="bloc-left" width={"50%"}>
                    <Paper className="paper-header-campagne">
                        <Stack className="info-avis">
                            <Stack className="bloc-header" data-testid="location-info">
                                <Typography variant="body1">{t("CAMPAIGN.BUSINESS")}</Typography>
                                <Stack>
                                    <Typography variant="body1" component="span" sx={{ color: "action.active" }}>
                                        {review.location.name}
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        component="span"
                                        fontWeight="600"
                                        fontFamily={"Poppins Medium"}
                                        sx={{ color: "action.active" }}
                                    >
                                        {`${review.location.address}, ${review.location.city}`}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack className="bloc-header" data-testid="sending-date">
                                <Typography variant="body1">
                                    {t(review?.scheduled_at ? "CAMPAIGN.SCHEDULED_TIME" : "CAMPAIGN.SENDING_DATE")}
                                </Typography>
                                <Stack>
                                    <Typography variant="body2" component="span" sx={{ color: "action.active" }}>
                                        {checkDisplayOfDate(date, preferredLanguage, true)}
                                    </Typography>
                                </Stack>
                            </Stack>
                            {review.status === "SCHEDULED" && (
                                <ActionBtnRight
                                    isLoading={isLoadingCancel}
                                    handleModify={handleModify}
                                    handleCancel={handleCancel}
                                />
                            )}
                        </Stack>
                        {isEdit && (
                            <BlocEditProgramming
                                setIsEdit={setIsEdit}
                                minDate={minDate}
                                review={{
                                    ...review,
                                    scheduled_at: reviewDetails?.recipients[0]?.scheduled_at ?? review.scheduled_at,
                                }}
                                businesses={businesses}
                                handleGetAllReviews={handleGetAllReviews}
                                handleGetSingleReview={handleGetSingleReview}
                                onClose={onClose}
                            />
                        )}
                    </Paper>
                    <Paper className="paper-stat">
                        <OverviewsStatistique
                            stats={{
                                sent: review.stats.sent,
                                received: review.stats.total,
                                opened: review.stats.clicked,
                            }}
                            status={review?.status}
                        />
                    </Paper>
                    <Paper sx={{ marginBottom: 2 }}>
                        <CustomTable
                            isLoading={loading}
                            columns={columns}
                            rows={reviewDetails?.recipients}
                            pagination={{
                                page: reviewDetails?.pagination._page - 1,
                                rowsPerPage: reviewDetails?.pagination._limit,
                                totalCount: reviewDetails?.pagination._count,
                            }}
                            className={"table-contact"}
                            onPageChange={handleChangePage}
                            numberRowLoading={10}
                            disablePagination={disablePagination}
                        />
                    </Paper>
                </Stack>
                {review?.type === "sms" && (
                    <SmsPreview
                        isLoading={loading}
                        html={reviewDetails?.message?.content}
                        contacts={{
                            ...review.location,
                            subject: reviewDetails?.message?.subject,
                            lang: reviewDetails?.message?.lang,
                        }}
                        businessName={review.location.name}
                    />
                )}
                {review?.type === "email" && (
                    <EmailPreview
                        isLoading={loading}
                        html={reviewDetails?.message?.content}
                        contacts={{
                            ...review.location,
                            subject: reviewDetails?.message?.subject,
                            lang: reviewDetails?.message?.lang,
                        }}
                        businessName={review.location.name}
                    />
                )}
            </Stack>
        </EditModelContainer>
    )
}

export default ListRecipient
