import { makeStyles } from "tss-react/mui"

export const useStyles = makeStyles()((theme) => ({
    blocQR: {
        gap: 24,
        "@media(max-width: 1023px)": {
            gap: 16,
        },
        "& .list-qr": {
            display: "flex",
            flexDirection: "row",
            gap: 24,
            "@media(max-width: 1023px)": {
                flexDirection: "column",
                gap: 16,
            },
            "& .bloc-qr": {
                width: "345px",
                height: "360px",
                "@media(max-width: 1023px)": {
                    width: "100%",
                },
            },
            "& .bloc-link": {
                width: "345px",
                height: "180px",
                "@media(max-width: 1023px)": {
                    width: "100%",
                },
            },
        },
    },
}))
