import BusinessAutocomplete from "@/components/widget/autocomplete/business-autocomplete"
import Icon from "@/components/base/Icon"
import TitleWrapper from "@/components/widget/page-container/list-container/title-wrapper"
import SchedulerComponent from "@/components/widget/scheduler"
import SocialsCampaignCard from "@/components/features/socials/campaign/card"
import { SocialsCampaignItem } from "@/components/features/socials/campaign/item"
import { SocialsDraftPost } from "@/components/features/socials/draft"
import SocialsModalPost from "@/components/features/socials/modal/post"
import usePageLogic from "@/hooks/social/usePageLogic"
import useQuerySocial from "@/hooks/social/useQuerySocial"
import useUiPageSession from "@/hooks/useUiPageSession"
import { SocialService } from "@/services"
import useLocationStore from "@/store/location"
import SocialStore from "@/store/social"
import SocialPopupStore from "@/store/social-popup"
import { SelectType } from "@/types/select"
import { convertToDate } from "@/utils"
import { Box, Button, CardContent, Container, Paper, Stack, Typography } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { useStyles } from "./styles"

const SocialPage = () => {
    useUiPageSession({
        title: "Socials",
        page: "socials",
    })
    const { classes } = useStyles()
    const { t } = useTranslation()
    const { businesses: listBusiness } = useLocationStore()
    const { fetchPosts } = usePageLogic()
    const { createPost, closeModal, openPosts, editPost, openCalendar } = useQuerySocial()
    const { posts, draftPosts, update, flushTempPost, flush: flushSocialStore } = SocialStore()
    const { openPopup, flush: flushSocialPopupStore } = SocialPopupStore()
    const [selectedBusinesses, setSelectedBusiness] = useState([])
    const [searchParams] = useSearchParams()
    const currentMonth = searchParams.get("month")
    const onPostClick = useCallback(
        (postId: string, date?: string) => {
            editPost(postId, date)
        },
        [editPost]
    )

    const updateCalendarMonth = useCallback(
        (value) => {
            update({ currentMonth: value })
            openCalendar(value)
        },
        [openCalendar, update]
    )

    const renderPost = useCallback(
        ({ description, thumbnail, provider, id, otherPosts = 0, openPosts, status, publishAt, postItems }) => {
            const date = convertToDate(publishAt)
            const isDisabledForm = SocialService.isDisabledForm({
                postItems: postItems,
                listBusiness,
            })
            return (
                <>
                    <SocialsCampaignCard
                        description={description}
                        imageCover={thumbnail}
                        facebook={provider.includes("facebook")}
                        google={provider.includes("google")}
                        instagram={provider.includes("instagram")}
                        postId={id}
                        status={status}
                        date={date}
                        onPostClick={(postId, date) => {
                            onPostClick(postId, date)
                        }}
                        isDisabledForm={isDisabledForm}
                    />

                    {otherPosts > 0 && (
                        <Stack className="btn-plus">
                            <Typography
                                component={"span"}
                                sx={{
                                    cursor: "pointer",
                                }}
                                textAlign={"center"}
                                onClick={() => {
                                    openPosts()
                                }}
                            >
                                + {otherPosts}
                            </Typography>
                        </Stack>
                    )}
                </>
            )
        },
        [onPostClick, listBusiness]
    )

    const convertPosts = useCallback(
        (postsByDate) => {
            const result = {}
            for (const date in postsByDate) {
                if (postsByDate[date] && postsByDate !== undefined) {
                    const posts = postsByDate[date]
                    const firstPost = posts?.[0]
                    if (firstPost) {
                        result[date] = {
                            render: renderPost,
                            renderParams: {
                                ...firstPost,
                                otherPosts: posts?.length - 1,
                                openPosts: () => {
                                    openPosts(date)
                                },
                            },
                        }
                    } // Get the first post of the array
                }
            }
            return result
        },
        [renderPost, openPosts]
    )

    useEffect(() => {
        if (listBusiness?.length) {
            update({ businesses: listBusiness?.map((item) => item?.value) })
        }
    }, [listBusiness, update])

    useEffect(() => {
        fetchPosts()
    }, [fetchPosts])

    useEffect(() => {
        return () => {
            flushSocialPopupStore()
            flushSocialStore()
        }
    }, [flushSocialPopupStore, flushSocialStore])

    return (
        <Container className={classes.containerSocial}>
            <TitleWrapper pageIcon="social" title={t("MENU.SOCIAL")} />
            <Paper elevation={1} sx={{ padding: "0 !important", borderRadius: 2, gap: 0 }}>
                <Stack className="header-social" gap={1} alignItems={"center"}>
                    <Box className="select-business">
                        <BusinessAutocomplete
                            defaultValue={selectedBusinesses}
                            selectionChange={(ids: SelectType[]) => {
                                const business =
                                    ids?.length === 0
                                        ? listBusiness?.map((item) => item?.value)
                                        : [...ids]?.map((item) => item?.value)
                                update({ businesses: business })
                                setSelectedBusiness([...ids]?.map((item) => item?.value))
                            }}
                        />
                    </Box>
                    <Button
                        variant="contained"
                        className="btn-post"
                        startIcon={<Icon name="plus" />}
                        onClick={() => {
                            flushTempPost()
                            createPost()
                        }}
                        data-testid="btn-new-post"
                    >
                        {t("SOCIALS.NEW_POST")}
                    </Button>
                </Stack>
                <CardContent sx={{ padding: "0 !important", overflow: "auto" }}>
                    <SchedulerComponent
                        defaultMonth={currentMonth}
                        data={convertPosts(posts)}
                        onMonthChange={updateCalendarMonth}
                        cellRenderer={({ date, events }) => (
                            <SocialsCampaignItem key={date.day} date={date} events={events} />
                        )}
                    />
                </CardContent>
            </Paper>
            {draftPosts?.length > 0 && <SocialsDraftPost posts={draftPosts} onPostClick={onPostClick} />}
            <SocialsModalPost open={openPopup} onClose={closeModal} />
        </Container>
    )
}

export default SocialPage
