import { Control, Controller } from "react-hook-form"
import SelectBase from "../select-base"
import { SelectProps } from "@mui/material"

type SelectBaseControllerProps = {
    control: Control<any>
    onSelectionChange?: (value: string | string[]) => void
    options: {
        value: string | number
        label: string
    }[]
}

const SelectBaseController = ({
    control,
    onSelectionChange,
    options,
    ...props
}: SelectBaseControllerProps & SelectProps) => {
    return (
        <Controller
            name={props.name}
            control={control}
            render={({ field, fieldState }) => (
                <SelectBase
                    {...field}
                    value={field.value || ""} // Assurez-vous d'avoir une valeur par défaut
                    onChange={(event) => {
                        const newValue: string = String(event.target.value)
                        field.onChange(newValue) // Mise à jour via react-hook-form
                        if (onSelectionChange) {
                            onSelectionChange(newValue) // Votre gestionnaire personnalisé
                        }
                    }}
                    error={!!fieldState.error}
                    id={props.name}
                    options={options}
                    {...props}
                />
            )}
        />
    )
}

export default SelectBaseController
