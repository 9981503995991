import TitleWrapper from "@/components/widget/page-container/list-container/title-wrapper"
import TablePaginationActions from "@/components/base/table/PaginationActions"
import ListingFilter from "@/components/features/listings/filter"
import SummaryList from "@/components/features/listings/summary-list"
import WebsitesList from "@/components/features/listings/websites-list"
import { SkeletonListing } from "@/components/widget/skeleton/listing"
import useLogic from "@/hooks/listings/useLogic"
import { Container, TablePagination } from "@mui/material"
import { Fragment } from "react"

const ListingsPage = () => {
    const {
        t,
        filteredData,
        summaryList,
        handleViewChange,
        handleSearchValueChange,
        viewTypeActive,
        classes,
        page,
        items,
        setPage,
        isLoading,
    } = useLogic()

    return (
        <Container className={classes.container}>
            <TitleWrapper pageIcon="bulletList" title={t("LISTINGS.TITLE")} loading={isLoading} />
            {isLoading && <SkeletonListing />}
            {!isLoading && (
                <Fragment>
                    <SummaryList list={summaryList} t={t} />
                    <ListingFilter t={t} onChange={handleViewChange} onSearchValueChange={handleSearchValueChange} />
                    <WebsitesList items={filteredData} viewType={viewTypeActive} />
                    <TablePagination
                        className={classes.pagination}
                        component={"div"}
                        labelDisplayedRows={({ from, to, count }) => {
                            return `${from}-${to} ${t("USERS.PAGING")} ${count}`
                        }}
                        count={items.length}
                        rowsPerPage={12}
                        page={page}
                        onPageChange={(__, pageActive) => setPage(pageActive)}
                        rowsPerPageOptions={[]}
                        ActionsComponent={TablePaginationActions}
                    />
                </Fragment>
            )}
        </Container>
    )
}

export default ListingsPage
