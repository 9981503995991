import { Paper, Skeleton, Stack } from "@mui/material"

export const SkeletonTable = () => {
    return (
        <Paper data-testid="table-compare">
            <Skeleton variant="rectangular" width={200} height={30} />
            <Skeleton variant="rectangular" width={"100%"} height={440} />
            <Stack justifyContent={"end"} flexDirection={"row"}>
                <Skeleton variant="rectangular" width={100} height={30} />
            </Stack>
        </Paper>
    )
}
