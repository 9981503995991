import { makeStyles } from "tss-react/mui"

export const useStyles = makeStyles()((theme) => ({
    containerQR: {
        "& .title-qr-code": {
            padding: 0,
        },
        "& .section-qr-link": {
            display: "flex",
            flexDirection: "row",
            gap: 24,
            "@media (max-width: 1023px)": {
                flexDirection: "column",
                gap: 16,
            },
        },
    },
}))
