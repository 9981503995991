import { Skeleton, Stack, Box } from "@mui/material"
import { ReactNode } from "react"
import TitleWrapper from "@/components/widget/page-container/list-container/title-wrapper"
import { useTranslation } from "react-i18next"
import { useStyles } from "./styles"

type QRCodeLinkDetailsSkeletonProps = {
    title: ReactNode
}
export const QrLinkDetailsSkeleton: React.FC<QRCodeLinkDetailsSkeletonProps> = ({ title }) => {
    const { t } = useTranslation()
    const { classes } = useStyles()

    return (
        <Stack className={classes.blocQR}>
            {/* Header Skeleton */}
            {title ? (
                <TitleWrapper title={t("MENU.QR_CODE_LINK")} />
            ) : (
                <Box>
                    <Skeleton variant="rectangular" width={300} height={48} />
                </Box>
            )}

            <Stack className="list-qr">
                {/* QR Code Details Skeleton */}
                <Skeleton className="bloc-qr" variant="rectangular" />
                <Skeleton className="bloc-link" variant="rectangular" />
            </Stack>
        </Stack>
    )
}
