import { SxProps, Theme } from "@mui/material"

export const modalQR: SxProps<Theme> = {
    padding: 0,
    width: 513,
    "@media (max-width: 767px)": {
        padding: 0,
        maxWidth: "calc(100% - 16px)",
    },
    "& .bloc-modal": {
        width: "100%",
        "& .title-wrapper": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            "& button": {
                color: "primary.main",
            },
        },
        "& .btn-edit-message": {
            position: "absolute",
            right: -40,
            top: -5,
        },
        "& .input-message": {
            width: "100%",
            textarea: {
                textAlign: "center",
            },
        },
    },
}
