import FormAction from "@/components/base/form-action"
import BaseSelect from "@/components/base/select"
import { TitleWrapper } from "@/components/base/title-wrapper"
import FormContainer from "@/components/features/business/form-container/create"
import useLogic from "@/hooks/businesses/create/useLogic"
import { PageProps } from "@/types/page"
import { Paper } from "@mui/material"
export interface FormValues {
    company: string
}

const CreateBusiness = ({ onClose, open }: PageProps) => {
    const {
        MESSAGE_TEXT,
        handlePreviousButton,
        handleSubmit,
        onSubmit,
        companies,
        t,
        control,
        handleCancelButton,
        loading,
        profile,
        formState,
    } = useLogic()

    return (
        <FormContainer open={open} onClose={onClose}>
            <TitleWrapper title={MESSAGE_TEXT.BUSINESS_CREATE_TITLE} handlePreviousButton={handlePreviousButton} />
            <form onSubmit={handleSubmit(onSubmit)} id="create-etablissment" className="full-height">
                <Paper className="inputs-container">
                    <BaseSelect
                        required
                        data-testid="company"
                        options={companies}
                        controlName="company"
                        label={t("BUSINESSES.LINKED_COMPANY")}
                        control={control}
                    />
                </Paper>

                <FormAction
                    handleCancel={handleCancelButton}
                    isDisabled={loading || !profile || !formState.isValid}
                    isLoading={loading}
                    buttonText={MESSAGE_TEXT.BUSINESS_CREATE_SAVE}
                />
            </form>
        </FormContainer>
    )
}

export default CreateBusiness
