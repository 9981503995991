import UiStore from "@/store/ui"
import { convertBigNumber } from "@/utils"
import { Box } from "@mui/material"
import { useMemo } from "react"
import ReactApexChart from "react-apexcharts"
import { useTranslation } from "react-i18next"
import { localesChart } from "./config"
import { sx } from "./style"

interface IChart {
    graphs: any
}

export const ChartView = ({ graphs }: IChart) => {
    const { t } = useTranslation()
    const { preferredLanguage } = UiStore()

    const state: any = useMemo(() => {
        const res = {
            series: [...graphs.series],
            options: {
                chart: {
                    locales: [...localesChart],
                    height: 300,
                    stacked: true,
                    zoom: false,
                    fontFamily: "Poppins",
                    defaultLocale: preferredLanguage?.split("-")[0],
                    toolbar: {
                        show: false,
                    },
                    animations: {
                        enabled: false,
                    },
                },
                markers: {
                    seriesIndex: 0,
                    fillColor: "#e3e3e3",
                    strokeColor: "#e3e3e3",
                    size: 0,
                    shape: "circle",
                    hover: {
                        size: 7,
                    },
                    ...graphs?.markers,
                },
                dataLabels: {
                    enabled: false,
                },
                noData: {
                    align: "center",
                    text: t("REQUEST_OPINION.NO_DATA"),
                    verticalAlign: "middle",
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            legend: {
                                position: "bottom",
                                offsetX: -10,
                                offsetY: 0,
                            },
                        },
                    },
                ],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: "55%",
                    },
                },
                stroke: graphs.stroke,
                xaxis: {
                    categories: graphs.categories,
                    tickPlacement: "on",
                    labels: {
                        rotate: 0,
                        showDuplicates: false,
                    },
                    crosshairs: {
                        show: false,
                    },
                },
                yaxis: {
                    min: graphs?.minXaxis ?? 0,
                    max: graphs.maxYaxis,
                    type: "value",
                    position: "left",
                    forceNiceScale: true,

                    labels: {
                        showDuplicates: false,
                        formatter: function (value) {
                            return convertBigNumber(value)
                        },
                    },
                    crosshairs: {
                        show: false,
                    },
                },
                tooltip: {
                    shared: true,
                    enabled: true,
                    intersect: false,
                    hideEmptySeries: false,
                    x: {
                        show: false,
                    },
                    y: graphs.yFormatters.map((formatter) => ({ formatter })),
                    ...graphs?.tooltip,
                },
                legend: {
                    position: "bottom",
                    horizontalAlign: "left",
                    offsetX: 8,
                    offsetY: 8,
                    markers: {
                        width: 12,
                        height: 12,
                        radius: 10,
                    },
                    formatter: function (seriesName) {
                        if (seriesName.toLowerCase() === "total") {
                            return t("LOCAL_PRESENCE.TOTAL_LAST_PERIOD")
                        }
                        return seriesName
                    },
                    ...graphs?.legend,
                },
                fill: {
                    opacity: 1,
                },
                states: {
                    active: {
                        filter: {
                            type: "none",
                        },
                    },
                    hover: {
                        filter: {
                            type: "none",
                        },
                    },
                },
            },
        }
        return res
    }, [graphs, preferredLanguage, localesChart])

    return (
        <div>
            <Box id="chart" sx={sx}>
                <ReactApexChart options={state.options} series={state.series} type="bar" height={280} />
            </Box>
            <div id="html-dist"></div>
        </div>
    )
}
