import Icon from "@/components/base/Icon"
import BaseInput from "@/components/base/input"
import { TitleWrapper } from "@/components/base/title-wrapper"
import FormContainer from "@/components/features/business/form-container/edit"
import FormAction from "@/components/features/business/form-container/edit/form-action"
import useLogic from "@/hooks/businesses/edit-link/useLogic"
import { PageProps } from "@/types/page"
import { Box, Paper, Typography } from "@mui/material"
import classNames from "classnames"
import { useCallback } from "react"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"

const EditLink = ({ onClose, open, mandatoryCheck = false }: PageProps) => {
    const { t } = useTranslation()
    const {
        watch,
        handleSubmit,
        handleCancelButton,
        onSubmit,
        onErrors,
        setShowAdornment,
        urlRequired,
        isUrlInvalid,
        control,
        loading,
        showAdornment,
        handlePreviousButton,
        defaultSocialNetwork,
        isSubmitting,
        setValueChanged,
        linksNetwork,
        errorLinks,
        valueChanged,
    } = useLogic({ mandatoryCheck })

    const invalidUrl = useCallback(
        (isUrlInvalid, valueChanged) => {
            return isUrlInvalid.websiteUrl && !valueChanged ? t("BUSINESSES.EDIT_LINKS.INVALID_URL_FORMAT") : ""
        },
        [t]
    )
    return (
        <FormContainer open={open} onClose={onClose}>
            <TitleWrapper
                handlePreviousButton={handlePreviousButton}
                data-testid="title"
                title={t("BUSINESSES.EDIT_LINKS.TITLE")}
            />
            <form onSubmit={handleSubmit(onSubmit, onErrors)}>
                <Box className="inputs-container">
                    <Paper className="inputs-wrapper">
                        <Typography variant="h2">
                            <Icon name="globe" sx={{ width: 24 }} />
                            {t("BUSINESSES.EDIT_LINKS.INTERNET_SHORT_INFOS")}
                        </Typography>
                        <Box className="wrapper">
                            <Controller
                                render={({ field }) => (
                                    <BaseInput
                                        {...field}
                                        inputRef={(input: HTMLInputElement) => {
                                            if (
                                                input &&
                                                (urlRequired || isUrlInvalid.websiteUrl) &&
                                                isSubmitting &&
                                                errorLinks[0] === "websiteUrl"
                                            ) {
                                                input.focus()
                                            }
                                        }}
                                        onChange={(e) => {
                                            field.onChange(e)
                                            setValueChanged(false)
                                        }}
                                        label={
                                            (showAdornment.websiteUrl || watch("websiteUrl") !== "") &&
                                            t("BUSINESSES.EDIT_LINKS.INTERNET_LONG_INFOS")
                                        }
                                        placeholder={
                                            !showAdornment.websiteUrl
                                                ? t("BUSINESSES.EDIT_LINKS.INTERNET_LONG_INFOS")
                                                : ""
                                        }
                                        onFocus={() => setShowAdornment((prev) => ({ ...prev, websiteUrl: true }))}
                                        onBlur={() => setShowAdornment((prev) => ({ ...prev, websiteUrl: false }))}
                                        startAdornment={<Icon name="globe" sx={{ color: "action.active" }} />}
                                        error={urlRequired || isUrlInvalid.websiteUrl}
                                        className={classNames(
                                            `input-adorment-fixed ${
                                                (showAdornment.websiteUrl || watch("websiteUrl") !== "") &&
                                                "has-adornment"
                                            }`
                                        )}
                                        helperText={
                                            urlRequired
                                                ? t("BUSINESSES.WEBSITE_OR_PHONE_REQUIRED")
                                                : invalidUrl(isUrlInvalid, valueChanged)
                                        }
                                    />
                                )}
                                control={control}
                                name="websiteUrl"
                            />
                        </Box>
                    </Paper>
                    <Paper className="inputs-wrapper">
                        <Typography variant="h2">
                            <Icon name="socialNetwork" sx={{ width: 24 }} />
                            {t("BUSINESSES.SOCIAL_NETWORK")}
                        </Typography>

                        <Box className="wrapper">
                            {defaultSocialNetwork?.map((data, key) => (
                                <Controller
                                    key={`${String(key)}`}
                                    render={({ field }) => (
                                        <BaseInput
                                            {...field}
                                            inputRef={(input: HTMLInputElement) => {
                                                if (
                                                    input &&
                                                    isUrlInvalid[data.name] &&
                                                    isSubmitting &&
                                                    errorLinks[0] === data.name
                                                ) {
                                                    input.focus()
                                                }
                                            }}
                                            label={t(`BUSINESSES.EDIT_LINKS.${data.title}_INFOS`)}
                                            onFocus={() =>
                                                setShowAdornment((prev) => ({ ...prev, [data?.name]: true }))
                                            }
                                            onBlur={() => {
                                                setShowAdornment((prev) => ({ ...prev, [data?.name]: false }))
                                            }}
                                            onChange={(e) => {
                                                field.onChange(e)
                                                setValueChanged(true)
                                            }}
                                            startAdornment={<Icon name={data.title.toLowerCase()} />}
                                            placeholder={data?.placeholder}
                                            error={isUrlInvalid[data.name]}
                                            className={"has-adornment"}
                                            helperText={
                                                isUrlInvalid[data.name] && !valueChanged
                                                    ? t("BUSINESSES.EDIT_LINKS.INVALID_ULR_MEDIA_NETWORK")
                                                    : ""
                                            }
                                            data-e2e={"link-" + data?.name}
                                        />
                                    )}
                                    control={control}
                                    name={data?.name}
                                />
                            ))}
                        </Box>
                    </Paper>
                    {linksNetwork?.appointment && (
                        <Paper className="inputs-wrapper">
                            <Typography variant="h2">
                                <Icon name="calendarCheck" sx={{ width: 24 }} />
                                {t("BUSINESSES.EDIT_LINKS.MAKE_APPOINTMENT_INFOS")}
                            </Typography>
                            <Box className="wrapper">
                                <Controller
                                    render={({ field }) => (
                                        <BaseInput
                                            {...field}
                                            inputRef={(input: HTMLInputElement) => {
                                                if (
                                                    input &&
                                                    isUrlInvalid["appointmentUrl"] &&
                                                    isSubmitting &&
                                                    errorLinks[0] === "appointmentUrl"
                                                ) {
                                                    input.focus()
                                                }
                                            }}
                                            label={
                                                (showAdornment.appointmentUrl || watch("appointmentUrl") !== "") &&
                                                t("BUSINESSES.EDIT_LINKS.MAKE_APPOINTMENT_INPUT_INFOS")
                                            }
                                            onFocus={() =>
                                                setShowAdornment((prev) => ({ ...prev, appointmentUrl: true }))
                                            }
                                            onBlur={() =>
                                                setShowAdornment((prev) => ({ ...prev, appointmentUrl: false }))
                                            }
                                            onChange={(e) => {
                                                field.onChange(e)
                                                setValueChanged(true)
                                            }}
                                            startAdornment={<Icon name="calendarCheck" />}
                                            error={isUrlInvalid["appointmentUrl"]}
                                            className={classNames(
                                                `input-adorment-fixed ${
                                                    (showAdornment.appointmentUrl || watch("appointmentUrl") !== "") &&
                                                    "has-adornment"
                                                }`
                                            )}
                                            placeholder={
                                                !showAdornment.appointmentUrl
                                                    ? t("BUSINESSES.EDIT_LINKS.MAKE_APPOINTMENT_INPUT_INFOS")
                                                    : ""
                                            }
                                            helperText={
                                                isUrlInvalid["appointmentUrl"] && !valueChanged
                                                    ? t("BUSINESSES.EDIT_LINKS.INVALID_ULR_APPOINTMENT")
                                                    : ""
                                            }
                                        />
                                    )}
                                    control={control}
                                    name="appointmentUrl"
                                />
                            </Box>
                        </Paper>
                    )}
                </Box>
                <FormAction handleCancel={handleCancelButton} isLoading={loading} isDisabled={loading} isUpdating />
            </form>
        </FormContainer>
    )
}

export default EditLink
