import { FilterAnalytics } from "@/components/features/analytics/filter"
import useStyles from "@/components/features/analytics/styles"
import Icon from "@/components/base/Icon"
import { ChartView } from "@/components/base/chart"
import TitleWrapper from "@/components/widget/page-container/list-container/title-wrapper"
import { SkeletonStat } from "@/components/widget/skeleton/statistic"
import useLogic from "@/hooks/request-opinion/useLogic"
import { Container, Paper, Stack, Typography } from "@mui/material"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { BusinessCompare } from "@/components/features/analytics/business-compare"
import { Column } from "@/components/widget/table/partials/custom-table"
import { RenderText } from "@/components/features/analytics/render-text-table-to-compare"

type ColumnProps = Column & {
    id: string
}

export const StatisticCampaignPage = ({ mockData = null }) => {
    const { classes } = useStyles()
    const { t } = useTranslation()
    const {
        graph,
        handleSelectionChange,
        handleSelectionPeriodChange,
        graphs,
        loading,
        selectedBusinesses,
        selectedPeriod,
        listBusinessToCompare,
        businessById,
    } = useLogic()

    const columnsBusinessCompare: readonly ColumnProps[] = useMemo(() => {
        return [
            {
                id: t("BUSINESSES.TITLE"),
                label: t("BUSINESSES.TITLE"),
                width: "15%",
                hidden: false,
                component: ({ row }) => {
                    return (
                        <Stack>
                            <Typography className="ellipse">{row.locationName}</Typography>
                            <Typography variant="body2" component="span" color={"#68738D"} className="ellipse">
                                {row.locationAddress}
                            </Typography>
                        </Stack>
                    )
                },
            },
            {
                id: "feedback_sms",
                label: t("REQUEST_OPINION.SMS"),
                width: "15%",
                hidden: false,
                sortable: true,
                component: ({ row }) => {
                    const business = businessById[row.locationId]?.[0]
                    return <RenderText data={business?.locationState?.newReviewUri ? row.feedback_sms : "-"} />
                },
            },
            {
                id: "feedback_email",
                label: t("REQUEST_OPINION.EMAIL"),
                width: "15%",
                hidden: false,
                sortable: true,
                component: ({ row }) => {
                    const business = businessById[row.locationId]?.[0]
                    return <RenderText data={business?.locationState?.newReviewUri ? row.feedback_email : "-"} />
                },
            },
            {
                id: "feedback_qrcode",
                label: t("REQUEST_OPINION.CODE_QR"),
                width: "15%",
                hidden: false,
                sortable: true,
                component: ({ row }) => {
                    const business = businessById[row.locationId]?.[0]
                    return <RenderText data={business?.locationState?.newReviewUri ? row.feedback_qrcode : "-"} />
                },
            },
            {
                id: "feedback_link",
                label: t("REQUEST_OPINION.LINK"),
                width: "15%",
                hidden: false,
                sortable: true,
                component: ({ row }) => {
                    const business = businessById[row.locationId]?.[0]
                    return <RenderText data={business?.locationState?.newReviewUri ? row.feedback_link : "-"} />
                },
            },
            {
                id: "feedback_nfc",
                label: t("REQUEST_OPINION.NFC_CARD"),
                width: "15%",
                hidden: false,
                sortable: true,
                component: ({ row }) => {
                    const business = businessById[row.locationId]?.[0]
                    return <RenderText data={business?.locationState?.newReviewUri ? row.feedback_nfc : "-"} />
                },
            },
            {
                id: "feedback_total",
                label: t("REQUEST_OPINION.TOTAL"),
                width: "15%",
                hidden: false,
                sortable: true,
                component: ({ row }) => {
                    const business = businessById[row.locationId]?.[0]
                    return <RenderText data={business?.locationState?.newReviewUri ? row.feedback_total : "-"} />
                },
            },
        ]
    }, [businessById])

    return (
        <Container className={classes.container}>
            <TitleWrapper pageIcon="barChart" title={t("REQUEST_OPINION.TITLE")} />
            {loading && <SkeletonStat type="request_opinion" />}
            {mockData === null && !loading && (
                <FilterAnalytics
                    selectedPeriod={selectedPeriod}
                    selectedBusinesses={selectedBusinesses}
                    handleSelectionChange={handleSelectionChange}
                    handleSelectionPeriodChange={handleSelectionPeriodChange}
                    widthTotal={true}
                />
            )}

            {graph && !loading && (
                <>
                    {listBusinessToCompare?.length > 1 && (
                        <BusinessCompare
                            details={listBusinessToCompare}
                            columns={columnsBusinessCompare}
                            title={"REQUEST_OPINION.TITLE"}
                        />
                    )}

                    <Paper>
                        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} gap={2}>
                            <Stack flexDirection={"row"} alignItems={"center"} gap={2} px={1}>
                                <Icon name="star" sx={{ color: "orange.main" }} />
                                <Typography variant={"h2"} fontSize={"24px"} fontWeight="500" sx={{ color: "inherit" }}>
                                    {t("REQUEST_OPINION.TITLE")}
                                </Typography>
                            </Stack>
                        </Stack>
                        <ChartView graphs={graphs?.feedback} />
                    </Paper>

                    <Paper>
                        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} gap={2}>
                            <Stack flexDirection={"row"} alignItems={"center"} gap={2} px={1}>
                                <Icon name="successHand" sx={{ path: { strokeWidth: 4 } }} />
                                <Typography variant={"h2"} fontSize={"24px"} fontWeight="500" sx={{ color: "inherit" }}>
                                    {t("REQUEST_OPINION.CUSTOMER_APPRECIATION")}
                                </Typography>
                            </Stack>
                        </Stack>
                        {graph?.period.length > 0 && (
                            <Stack flexDirection="row" gap={2}>
                                <Stack className="btn-review good">
                                    <Icon name="successHand" sx={{ padding: 1, path: { strokeWidth: 4 } }} />
                                    <Typography variant="h2" className="text-good" data-testid="like_total">
                                        {graphs?.appreciationClient.totalLike}
                                    </Typography>
                                </Stack>
                                <Stack className="btn-review bad">
                                    <Icon
                                        name="warningHand"
                                        sx={{ color: "#FFC431", padding: 1, path: { strokeWidth: 4 } }}
                                    />
                                    <Typography variant="h2" className="text-bad" data-testid="dislike_total">
                                        {graphs?.appreciationClient.totalDislike}
                                    </Typography>
                                </Stack>
                            </Stack>
                        )}
                        <ChartView graphs={graphs?.appreciationClient} />
                    </Paper>
                </>
            )}
        </Container>
    )
}
