import SerializedString from "@/components/widget/serialized-string"
import useBusinessLogic from "@/hooks/businesses/useBusinessLogic"
import { Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import ErrorMessage from "../../error-message"
import OverviewTitle from "../title"
import OverviewDescriptionStore from "@/store/overview/long-description"

const OverviewDescription = ({ sx, isLoading }) => {
    const { t } = useTranslation()
    const { description } = OverviewDescriptionStore()
    const { navigateTo } = useBusinessLogic()
    return (
        <>
            {description && (
                <Paper
                    elevation={2}
                    className="clickable"
                    id="description"
                    sx={sx}
                    onClick={() => !isLoading && navigateTo("edit-description")}
                >
                    <OverviewTitle
                        title={t("BUSINESSES.LONG_DESCRIPTION")}
                        // icon="file"
                        isSecondTitle
                        tooltipText={t("BUSINESSES.EDIT_CONTENTS.MAIN_TOOLTIP")}
                    />
                    {(!description || !description?.short) && <ErrorMessage />}
                    {description?.short && <SerializedString text={description?.short ?? ""} />}
                </Paper>
            )}
        </>
    )
}

export default OverviewDescription
