import { useDebounce } from "@/hooks/useDebounce"
import BaseInput from "../input"
import { useEffect, useState, useCallback } from "react"
import { Box, IconButton, TextFieldProps } from "@mui/material"
import CustomTooltip from "../Tooltip"
import Icon from "../Icon"
import { useTranslation } from "react-i18next"

const baseInputSx = {
    width: "100%",
    "@media (min-width: 1024px)": {
        maxWidth: 320,
    },
    input: {
        height: "39px",
    },
}

const iconBtnSx = { p: 0, color: "inherit" }

const endAdornmentSx = {
    display: "flex",
    gap: 0.25,
    height: "56px",
    alignItems: "center",
    "& svg": {
        color: "action.active",
    },
}

type InputSearchProps = {
    onValueChange: (value: string) => void // useCallback
}

const InputSearch = ({ onValueChange, ...props }: InputSearchProps & Partial<TextFieldProps>) => {
    const [inputValue, setInputValue] = useState("")
    const debouncedChange = useDebounce(inputValue, 500)
    const { t } = useTranslation()

    const handleClearTextSearch = useCallback(() => {
        setInputValue("")
    }, [])

    useEffect(() => {
        onValueChange?.(debouncedChange)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedChange])

    return (
        <BaseInput
            size="small"
            id="input-search"
            sx={baseInputSx}
            hiddenLabel={true}
            onChange={(e) => {
                setInputValue(e.currentTarget.value as string)
            }}
            endAdornment={
                inputValue?.length > 0 && (
                    <Box sx={endAdornmentSx}>
                        <CustomTooltip title={t("BUSINESSES.ERASE_TEXT")}>
                            <IconButton
                                sx={iconBtnSx}
                                onClick={() => handleClearTextSearch()}
                                className="text-clear-icon"
                            >
                                <Icon name="close" />
                            </IconButton>
                        </CustomTooltip>
                    </Box>
                )
            }
            {...props}
        />
    )
}

export default InputSearch
