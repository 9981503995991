import { SocialsApi } from "@/api"
import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import { modalPost } from "@/components/features/socials/style"
import usePageLogic from "@/hooks/social/usePageLogic"
import useNotification from "@/hooks/useNotification"
import useQueryParams from "@/hooks/useQueryParams"
import SocialStore from "@/store/social"
import SocialPopupStore, { SocialDialogType } from "@/store/social-popup"
import UiStore from "@/store/ui"
import { withSxProp } from "@/utils"
import { Box, Dialog, IconButton, Stack, Typography } from "@mui/material"
import dayjs from "dayjs"
import "dayjs/locale/en"
import "dayjs/locale/fr"
import "dayjs/locale/fr-ca"
import advancedFormat from "dayjs/plugin/advancedFormat"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { SocialsAlertContentAlert } from "../../alert/content-alert"
import SocialsForm from "../../form"
import { SocialsModalConfirmDeleteContent } from "../confirm-delete/content"
import { SocialsAlertErrorPostDetails } from "../error-details"
import { SocialsModalSuccess } from "../success"
import SocialsModalConnexionDetail from "../connexion-detail"
import SocialsModalPostList from "./list"
import { SocialService } from "@/services"
import useLocationStore from "@/store/location"
dayjs.extend(advancedFormat)

const SocialsModalPost = ({ open, onClose }) => {
    const { t } = useTranslation()
    const { notif } = useNotification()
    const { fetchPosts } = usePageLogic()
    const [searchParams] = useSearchParams()
    const { businesses } = useLocationStore()
    const { showPopupContent, show, updatePopup } = SocialPopupStore()
    const { posts, update, tempPost, loadingDelete, itemsError, providerError } = SocialStore()
    const { preferredLanguage } = UiStore()
    const postId = searchParams.get("edit-post")
    const day = searchParams.get("day")
    const [defaultDateFilter, setDefaultDateFilter] = useState("")
    const { removeQueryParams, setQueryParams } = useQueryParams()

    const alertSuccessContent = useMemo(() => {
        if (show === "success_scheduled") {
            return {
                icon: "scheduled",
                text: t("SOCIALS.SCHEDULED_POST"),
            }
        } else if (show === "success_normal") {
            return {
                icon: "success",
                text: t("SOCIALS.POST_SENT"),
            }
        }
    }, [show, t])

    const formattedDate = useMemo(() => {
        if (!preferredLanguage || !defaultDateFilter) return ""
        const formatDate = preferredLanguage?.toLowerCase() === "en" ? "MMMM Do, YYYY" : "D MMMM YYYY"
        if (show !== "multiple_post") {
            return ""
        }
        return dayjs(defaultDateFilter).locale(preferredLanguage?.toLowerCase()).format(formatDate)
    }, [defaultDateFilter, preferredLanguage, show])

    const currentPosts = useMemo(() => {
        if (show === "multiple_post" && defaultDateFilter && posts[defaultDateFilter]) {
            return posts[defaultDateFilter]
        }
        return []
    }, [show, posts, defaultDateFilter])

    const isNewPost = useMemo(() => {
        return !tempPost?.isLastPost || tempPost?.repost
    }, [tempPost?.isLastPost, tempPost?.repost])

    const isLastPost = useMemo(() => {
        return tempPost?.isLastPost && !tempPost?.repost
    }, [tempPost?.isLastPost, tempPost?.repost])

    const title = useMemo(() => {
        if (show === "alert_exit") return t("SOCIALS.ALERT.TITLE")
        if (show === "error_details") return t("SOCIALS.ALERT_POST_ERROR.TITLE")
        if (show === "alert_delete") return t("SOCIALS.ALERT_DELETE.TITLE")
        if (show === "single_post" && isNewPost) return t("SOCIALS.NEW_POST")
        if (show === "single_post" && isLastPost) return t("SOCIALS.POST")
        if (show === "multiple_post") return `${t("SOCIALS.POST_DATE")} ${formattedDate}`
        if (show === "connexion_details") return t("SOCIALS.CONNECTION_DETAILS")
        return ""
    }, [show, t, isNewPost, isLastPost, formattedDate])

    const closeGlobalPopup = useCallback(() => {
        showPopupContent("")
        update({
            tempPost: null,
        })
        onClose()
    }, [onClose, showPopupContent, update])

    const handleClose = useCallback(() => {
        setDefaultDateFilter("")
        switch (show) {
            case "success_normal":
                closeGlobalPopup()
                break
            case "alert_exit":
            case "error_details":
            case "alert_delete":
            case "connexion_details":
                showPopupContent("")
                update({
                    tempPost: null,
                })
                onClose()
                break
            case "single_post":
                if (tempPost?.formHaveChanged) {
                    showPopupContent("alert_exit")
                } else closeGlobalPopup()
                break
            default:
                closeGlobalPopup()
                break
        }
    }, [show, closeGlobalPopup, showPopupContent, update, onClose, tempPost?.formHaveChanged])

    const handleBack = () => {
        switch (show) {
            case "error_details":
            case "alert_exit":
            case "connexion_details":
                showPopupContent("single_post")
                removeQueryParams(["show"])
                break
            default:
                break
        }
    }

    const handleCloseDelete = () => {
        showPopupContent("single_post")
    }

    const handleConfirmDelete = useCallback(async () => {
        update({ loadingDelete: true })
        const res = await SocialsApi.removePost(postId)
        if (res?.error) {
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        } else {
            await fetchPosts()
            notif({ message: t("SOCIALS.SUCCESS_DELETE_POST"), type: "SUCCESS" })
        }
        showPopupContent("")
        update({ loadingDelete: false, tempPost: null })
        onClose()
    }, [update, postId, showPopupContent, onClose, notif, t, fetchPosts])

    const widthModal = useMemo(() => {
        switch (show) {
            case "alert_exit":
            case "alert_delete":
            case "error_details":
            case "connexion_details":
                return "460px"
            case "success_normal":
            case "success_scheduled":
                return "428px"
            default:
                return "765px"
        }
    }, [show])

    const showBackButton = useMemo(() => {
        return show === "error_details" || show === "connexion_details"
    }, [show])

    const getPostItems = useCallback(async () => {
        if (postId) {
            const post = posts[day]?.find((p) => p.id === postId)
            const postItems = post?.postItems || []
            const { provider, rows } = SocialService.formatRowsPostError(postItems, businesses)

            update({ itemsError: rows, providerError: provider })
        }
    }, [postId, day, businesses, posts])

    useEffect(() => {
        getPostItems()
    }, [getPostItems])

    useEffect(() => {
        const singlePost = searchParams.get("create-post") || searchParams.get("edit-post")
        const multiplePost = searchParams.get("multi-post")
        const show = searchParams.get("show") as SocialDialogType
        if (!["single_post", "multiple_post"].includes(show) && show) {
            showPopupContent(show)
            return
        }
        if (singlePost) {
            showPopupContent("single_post")
            setDefaultDateFilter("")
            return
        }

        if (multiplePost) {
            setDefaultDateFilter(day)
            showPopupContent("multiple_post")
            return
        }
    }, [searchParams, showPopupContent, day])

    useEffect(() => {
        const params = new URLSearchParams(location.search)

        if (params.has("create-post") || params.has("edit-post") || params.has("multi-post")) {
            updatePopup({
                openPopup: true,
            })
            if (show !== "") {
                setQueryParams({ show })
            } else {
                removeQueryParams(["show"])
            }
        } else {
            updatePopup({
                openPopup: false,
            })
        }
    }, [location.search, show])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-remove"
            disableRestoreFocus
            scroll="body"
            PaperProps={{ elevation: 6, sx: withSxProp(modalPost(widthModal, show)) }}
        >
            <>
                <Stack className="bloc-modal">
                    <>
                        <Box className="title-wrapper">
                            {showBackButton && (
                                <CustomTooltip title={t("USERS.PREVIOUS_PAGE_TOOLTIP")}>
                                    <IconButton onClick={handleBack}>
                                        <Icon name="back" />
                                    </IconButton>
                                </CustomTooltip>
                            )}
                            <Typography id="modal-modal-title" variant="h2">
                                {title}
                            </Typography>
                            <CustomTooltip title={t("USERS.CLOSE")}>
                                <IconButton onClick={handleClose}>
                                    <Icon name="x" />
                                </IconButton>
                            </CustomTooltip>
                        </Box>
                        {show === "single_post" && <SocialsForm />}
                        {show === "multiple_post" && <SocialsModalPostList posts={currentPosts} />}
                        {show === "alert_exit" && <SocialsAlertContentAlert handleBack={handleBack} />}
                        {show === "error_details" && (
                            <SocialsAlertErrorPostDetails items={itemsError} provider={providerError} />
                        )}
                        {show === "alert_delete" && (
                            <SocialsModalConfirmDeleteContent
                                handleClose={handleCloseDelete}
                                handleConfirm={handleConfirmDelete}
                                isLoading={loadingDelete}
                            />
                        )}
                        {(show === "success_normal" || show === "success_scheduled") && (
                            <SocialsModalSuccess message={alertSuccessContent.text} icon={alertSuccessContent.icon} />
                        )}
                        {show === "connexion_details" && <SocialsModalConnexionDetail />}
                    </>
                </Stack>
            </>
        </Dialog>
    )
}

export default SocialsModalPost
