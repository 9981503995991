import TitleWrapper from "@/components/widget/page-container/list-container/title-wrapper"
import { ListReputation } from "@/components/features/reputation/list-reputation"
import { Container } from "@mui/material"

import useStyles from "@/components/features/reputation/styles"
import { SkeletonHeaderBtn } from "@/components/widget/skeleton/header-btn-left-right"
import { SkeletonReputationItem } from "@/components/widget/skeleton/reputation/bloc-item-reputation"
import useLogic from "@/hooks/reputation/list/useLogic"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"

const ReputationPage = ({ mockData = null }) => {
    const { classes } = useStyles()
    const { t } = useTranslation()
    const {
        loading,
        items,
        total,
        page,
        setPage,
        refBloc,
        alert,
        handleSelectionChange,
        handleSelectionRateChange,
        handleDeleteChange,
        isLimitExceeded,
    } = useLogic()

    const listReputationProps = items
        ? {
              items,
              total,
              refBloc,
              page,
              setPage,
              isLimitExceeded,
          }
        : {
              mockData,
              total: 10,
              page: 0,
              isLimitExceeded: false,
          }

    return (
        <Container className={classes.container}>
            <TitleWrapper pageIcon="star" title={t("REPUTATION.TITLE")} alert={alert} loading={loading} />
            {loading ? (
                <Fragment>
                    <SkeletonHeaderBtn />
                    {[...Array(5)].map((_, key) => (
                        <SkeletonReputationItem key={key} />
                    ))}
                </Fragment>
            ) : (
                <ListReputation
                    {...listReputationProps}
                    handleSelectionChange={handleSelectionChange}
                    handleSelectionRateChange={handleSelectionRateChange}
                    handleDeleteChange={handleDeleteChange}
                />
            )}
        </Container>
    )
}

export default ReputationPage
