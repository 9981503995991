import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import { modalStyle } from "@/pages/users/styles"
import { withSxProp } from "@/utils"
import { Box, Button, IconButton, Modal, Paper, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { deleteModal } from "./styles"

interface PropsModal {
    open: boolean
    onClose: () => void
    onNext: () => void
}

export const AlertModalCreationBusiness = ({ open, onClose, onNext }: PropsModal) => {
    const { t } = useTranslation()
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-remove"
            disableRestoreFocus
        >
            <Paper elevation={6} sx={withSxProp(modalStyle, deleteModal)}>
                <Box className="title-wrapper">
                    <Typography id="modal-modal-title" variant="h2">
                        {t("BUSINESSES.ALERT.TITLE")}
                    </Typography>
                    <CustomTooltip title={t("USERS.CLOSE")}>
                        <IconButton onClick={onClose}>
                            <Icon name="x" />
                        </IconButton>
                    </CustomTooltip>
                </Box>
                <Stack>
                    <Typography margin={0}>{t("BUSINESSES.ALERT.DESCRIPTION_TOP")}</Typography>
                    <Typography>{t("BUSINESSES.ALERT.DESCRIPTION_BOTTOM")}</Typography>
                </Stack>
                <Box className="action-wrapper">
                    <Button data-testid="cancel-button" onClick={onClose}>
                        {t("BUSINESSES.ALERT.CANCEL")}
                    </Button>
                    <Button
                        onClick={() => {
                            onClose()
                            onNext()
                        }}
                        data-testid="continue-button"
                        variant="contained"
                    >
                        {t("BUSINESSES.ALERT.CONTINUE")}
                    </Button>
                </Box>
            </Paper>
        </Modal>
    )
}
