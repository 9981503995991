import Icon from "@/components/base/Icon"
import BaseInput from "@/components/base/input"
import BaseSelect from "@/components/base/select"
import InputPrice from "@/components/widget/input-price"
import { useDebounce } from "@/hooks/useDebounce"
import { ServiceSection, ServiceSectionItem } from "@/store/location"
import { Box, Button, Chip, IconButton, Paper, Typography } from "@mui/material"
import cloneDeep from "lodash.clonedeep"
import { Dispatch, Fragment, SetStateAction, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
interface FormLocationServiceInput {
    title: string
    items?: ServiceSection[]
    currency: Currency
    customServices: any
    serviceIds: string[]
    setServiceIds: Dispatch<SetStateAction<string[]>>
    onFormChange?: (data: DataServiceItem[]) => void
}

interface Currency {
    code?: string
    label?: string
}

interface DataServiceItem extends ServiceSectionItem {
    priceType?: string
}

export const FormLocationService = ({
    title,
    items,
    currency,
    customServices,
    serviceIds,
    setServiceIds,
    onFormChange,
}: FormLocationServiceInput) => {
    const { t } = useTranslation()
    const [data, setData] = useState<DataServiceItem[]>()
    const values = useDebounce(data, 300)

    const services = useMemo(() => {
        return (customServices || [])?.sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
    }, [customServices])

    const priceType = useMemo(() => {
        return [
            { label: t("BUSINESSES.EDIT_SERVICES.NO_PRICE"), value: "no_price" },
            { label: t("BUSINESSES.EDIT_SERVICES.FREE_PRICE"), value: "free" },
            { label: t("BUSINESSES.EDIT_SERVICES.FIXE_PRICE"), value: "fixed" },
        ]
    }, [t])

    const thousandSeparator = useMemo(() => {
        let separator = ","
        switch (currency.code?.toUpperCase()) {
            case "EUR":
            case "CAD":
                separator = " "
                break
        }
        return separator
    }, [currency])

    const getPriceType = useCallback((price: string, free?: boolean) => {
        if (free) {
            return "free"
        }
        if (price) {
            return "fixed"
        }
        if (price === null) {
            return "no_price"
        }
        return "no_price"
    }, [])

    const handleAddService = useCallback(
        (item = null) => {
            const updatedData = cloneDeep(data)
            if (item) {
                const updated = {
                    label: item?.displayName,
                    description: null,
                    price: null,
                    priceType: "no_price",
                }
                if (item.serviceTypeId) {
                    Object.assign(updated, { serviceTypeId: item.serviceTypeId })
                    const updatedServiceIds = [...serviceIds, item.serviceTypeId]
                    setServiceIds(updatedServiceIds)
                }
                updatedData.push(updated)
            } else {
                updatedData.push({
                    label: "",
                    price: null,
                    priceType: "no_price",
                })
            }
            setData([...updatedData])
        },
        [setServiceIds, setData, data, serviceIds]
    )

    const handleDeleteItem = useCallback(
        (index) => {
            const updatedData = cloneDeep(data)
            const updatedServiceIds = cloneDeep(serviceIds)
            if (updatedData?.[index]?.serviceTypeId) {
                const findIndex = serviceIds.indexOf(updatedData[index].serviceTypeId)
                if (findIndex > -1) {
                    updatedServiceIds.splice(findIndex, 1)
                }
                setServiceIds([...updatedServiceIds])
            }
            updatedData?.splice(index, 1)
            setData([...updatedData])
        },
        [setData, setServiceIds, data, serviceIds]
    )

    const handlePriceTypeChanged = useCallback(
        (item, index) => {
            const updatedData = cloneDeep(data)
            updatedData[index].priceType = item.value
            updatedData[index].price = ""
            if (item.value === "free") {
                updatedData[index].free = true
            } else {
                delete updatedData[index].free
            }
            setData([...updatedData])
        },
        [setData, data]
    )

    const handleInputChange = useCallback(
        (key, value, index) => {
            const updatedData = cloneDeep(data)
            updatedData[index][key] = value
            setData([...updatedData])
        },
        [setData, data]
    )

    useEffect(() => {
        const __items = items?.map((item) => {
            return item?.items?.map((entry) => {
                return {
                    ...entry,
                    priceType: entry.priceType || getPriceType(entry?.price, entry?.free),
                }
            })
        })
        const sorted = __items?.flat()?.sort((a, b) => (a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1))
        setData(sorted || [])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items])

    useEffect(() => {
        if (typeof onFormChange === "function") {
            onFormChange(values)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values])

    return (
        <Paper className="inputs-wrapper">
            <Box className="title">
                <Icon name="tag" />
                <Typography data-testid="title-location-service" variant="h2">
                    {title}
                </Typography>
            </Box>
            {data?.map((entry, index) => (
                <Paper elevation={2} sx={{ flexDirection: "row" }} key={index}>
                    <Box className="w-100">
                        <BaseInput
                            label={t("BUSINESSES.EDIT_SERVICES.SERVICE_NAME")}
                            required
                            name="service-name"
                            showLabelStar
                            value={entry?.label}
                            onInput={(e) => handleInputChange("label", e.target.value, index)}
                            disabled={!!entry.serviceTypeId}
                            error={entry?.label?.length === 0}
                            // helperText={`${entry?.label?.length || 0}/140`}
                            helperTextPosition="right"
                            max={140}
                            autoFocus={!entry?.label}
                        />
                        <BaseSelect
                            label={t("BUSINESSES.EDIT_SERVICES.PRICE_TYPE")}
                            className="select"
                            options={priceType}
                            defaultValue={entry?.priceType}
                            onItemClicked={(item) => handlePriceTypeChanged(item, index)}
                        />
                        <InputPrice
                            label={`${t("BUSINESSES.EDIT_SERVICES.PRICE")} (${currency?.code?.toUpperCase()})`}
                            onChange={(e) => handleInputChange("price", e.target.value, index)}
                            value={entry?.price}
                            error={entry?.priceType === "fixed" && !entry.price}
                            currency={currency?.label || "$"}
                            thousandSeparator={thousandSeparator}
                            disabled={!(entry?.priceType === "fixed")}
                            focus={!entry?.price && entry?.priceType === "fixed"}
                        />
                        <BaseInput
                            helperText={`${entry?.description?.length || 0}/250`}
                            helperTextPosition="right"
                            label={t("BUSINESSES.EDIT_SERVICES.DESCRIPTION")}
                            multiline
                            value={entry?.description || ""}
                            max={250}
                            onInput={(e) => handleInputChange("description", e.target.value, index)}
                        />
                    </Box>
                    <IconButton data-testid="delete-item" onClick={() => handleDeleteItem(index)}>
                        <Icon name="delete1" sx={{ color: "error.main" }} />
                    </IconButton>
                </Paper>
            ))}
            <Box className="chip-wrapper">
                {services?.map((item, index) => {
                    return (
                        <Fragment key={index}>
                            <Chip
                                color="secondary"
                                variant="outlined"
                                icon={<Icon name="plus" />}
                                label={item?.displayName}
                                clickable
                                onClick={() => handleAddService(item)}
                            />
                        </Fragment>
                    )
                })}
                <Button
                    color="primary"
                    variant="text"
                    className="add-more"
                    size="medium"
                    onClick={() => handleAddService()}
                >
                    <Icon name="plus" />
                    {t("BUSINESSES.EDIT_SERVICES.CUSTOM_SERVICE")}
                </Button>
            </Box>
        </Paper>
    )
}
