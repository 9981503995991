import Icon from "@/components/base/Icon"
import UiStore from "@/store/ui"
import { IReviews } from "@/types/campaign"
import { checkDisplayOfDate } from "@/utils"
import { Divider, Paper, Stack, Tooltip, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import OverviewsStatistique from "../statistique"
import useStyles from "../styles"
import { useMemo } from "react"

interface OverviewsItemProps {
    handleShowDetails: (review: IReviews) => void
    review: IReviews
    isSingleLocation: boolean
}

const OverviewsItem = ({ review, isSingleLocation, handleShowDetails }: OverviewsItemProps) => {
    const { t } = useTranslation()
    const { preferredLanguage } = UiStore()
    const { classes } = useStyles({ isClickable: review?.is_clickable })()

    const date = useMemo(() => {
        if (review?.scheduled_at) return review?.scheduled_at
        return review?.created
    }, [review?.created, review?.scheduled_at, review?.status])

    const colorStatus = useMemo(() => {
        if (review?.status === "COMPLETED") return "success.main"
        if (review?.status === "SCHEDULED") return "action.active"
        return "primary.main"
    }, [review?.status])

    const statusText = useMemo(() => {
        if (review?.status === "COMPLETED") return t("CAMPAIGN.SENT")
        if (review?.status === "SCHEDULED") return t("CAMPAIGN.SCHEDULED")
        return t("CAMPAIGN.WAITING")
    }, [review?.status, t])

    return (
        <Paper
            elevation={2}
            className={classes.paperOverviews}
            onClick={() => (review?.is_clickable ? handleShowDetails(review) : null)}
            data-testid="overview-item"
        >
            <Stack flexDirection={"column"} gap={1} padding="16px 0px 0px;">
                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                    <Stack flexDirection={"row"} gap={1}>
                        <Tooltip title={review?.type === "sms" ? t("CAMPAIGN.TYPE_SMS") : t("CAMPAIGN.TYPE_EMAIL")}>
                            <span>
                                <Icon color="#000" name={review?.type === "sms" ? "messageText" : "envelope"} />
                            </span>
                        </Tooltip>
                        <Typography fontFamily={"Poppins Medium"} data-testid="overview-date">
                            {checkDisplayOfDate(date, preferredLanguage)}
                        </Typography>
                    </Stack>
                    <Typography
                        sx={{
                            color: colorStatus,
                        }}
                        data-testid="overview-status"
                    >
                        {statusText}
                    </Typography>
                </Stack>
                {!isSingleLocation && (
                    <Stack flexDirection="column">
                        <Typography
                            sx={{
                                color: "action.active",
                            }}
                        >
                            {review?.location?.name}
                        </Typography>
                        <Typography
                            sx={{
                                color: "action.active",
                            }}
                            fontSize={"12px"}
                            fontWeight={600}
                            fontFamily={"Poppins Medium"}
                        >
                            {`${review.location.address}, ${review.location.city}`}
                        </Typography>
                    </Stack>
                )}
            </Stack>
            <Stack>
                <Divider className="separator" />
                <OverviewsStatistique
                    status={review.status}
                    stats={{
                        sent: review.stats.sent,
                        received: review.stats.total,
                        opened: review.stats.clicked,
                    }}
                />
            </Stack>
        </Paper>
    )
}

export default OverviewsItem
