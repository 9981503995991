import { useTranslation } from "react-i18next"
import { useCallback, useEffect, useRef, useState } from "react"
import useUiPageSession from "@/hooks/useUiPageSession"
import useCompanies from "@/hooks/companies/list/useLogic"
import useBusinessHooks from "@/components/widget/autocomplete/business-autocomplete/useBusinessHooks"
import { ReputationService } from "@/services"
import ReputationStore from "@/store/reputation"
import UserSessionStore from "@/store/user-session"

const MESSAGE_TEXT = {
    RECONNECT_GOOGLE_TITLE: "BUSINESSES.RECONNECT_GOOGLE.TITLE",
    RECONNECT_GOOGLE_WARNING: "BUSINESSES.RECONNECT_GOOGLE.WARNING",
}

const useLogic = () => {
    useUiPageSession({
        title: "REPUTATION.TITLE",
        page: "reputation",
    })

    const { t } = useTranslation()
    const refBloc = useRef<HTMLDivElement | null>(null)
    const { profile } = UserSessionStore()
    const {
        update: updateReputation,
        reviews: items,
        payloadFilter,
        updatePayloadFilter,
        alreadyLoaded,
        loading,
        alert,
    } = ReputationStore()
    const { fetchBusinessList } = useBusinessHooks()
    const { items: companies } = useCompanies()

    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(0)
    const [isLimitExceeded, setIsLimitExceeded] = useState(false)
    const [defaultIds, setDefaultIds] = useState<string[]>([])
    const [isDefaultIdsLoaded, setIsDefaultIdsLoaded] = useState(false)
    const [companiesSupportWithLocation, setCompaniesSupportWithLocation] = useState<
        {
            locationId: string
            email_support: string
            companyId: string
            isShowChoiceEmailSupport?: boolean
        }[]
    >([])

    const generateCompaniesSupport = useCallback(
        (business: any[]) =>
            companies.length > 0
                ? business.map((location) => {
                      const company = companies.find((comp) => String(comp.uid) === String(location?.companyId))
                      const result = {
                          locationId: location?.value,
                          email_support: company?.emailSupport || "",
                          companyId: String(location?.companyId),
                          isShowChoiceEmailSupport: company?.isShowChoiceEmailSupport,
                      }
                      return result
                  })
                : [],
        [companies]
    )

    const scrollIntoView = useCallback(() => {
        if (refBloc.current) {
            refBloc.current.style.scrollMargin = "200px"
            refBloc.current.scrollIntoView({ behavior: "smooth" })
        }
    }, [])

    const checkLimitIA = useCallback(async () => {
        const res = await ReputationService.checkLimitSuggestIA("gpt4omini")
        if (res?.["@type"]) setIsLimitExceeded(res?.isLimitExceeded || false)
    }, [])

    const fetchAvis = useCallback(async () => {
        if (!profile?.uid || defaultIds.length === 0) {
            updateReputation({ alreadyLoaded: true, loading: false })
            return
        }

        const ids = payloadFilter?.locationsId?.length ? payloadFilter.locationsId : defaultIds

        if (payloadFilter?.reply === null) {
            delete payloadFilter.reply
        }

        const response = await ReputationService.fetchAvis({
            ...payloadFilter,
            _page: page + 1,
            locationsId: ids,
        })

        const reviews = response.data.map((item: any) => {
            const corresponding = companiesSupportWithLocation.find((company) => company.locationId === item.locationId)
            if (corresponding) {
                item.companyId = corresponding.companyId
                item.isShowChoiceEmailSupport = corresponding.isShowChoiceEmailSupport
                item.email_support = corresponding.email_support
            }
            return item
        })

        updateReputation({
            loading: false,
            alreadyLoaded: true,
            reviews,
            alert: response.metadata?.has_review_hidden
                ? {
                      title: t(MESSAGE_TEXT.RECONNECT_GOOGLE_TITLE),
                      contents: t(MESSAGE_TEXT.RECONNECT_GOOGLE_WARNING),
                  }
                : null,
        })

        setTotal(response?.metadata?.total || 0)
        scrollIntoView()
    }, [
        profile?.uid,
        defaultIds,
        payloadFilter,
        page,
        companiesSupportWithLocation,
        t,
        updateReputation,
        scrollIntoView,
    ])

    const handleSelectionChange = useCallback(
        (items) => {
            const locationIds = items?.map((item) => item.value) || []
            setPage(0)
            updatePayloadFilter({ _page: 0, locationsId: locationIds })
            updateReputation({ selectedBusinesses: items })
        },
        [updatePayloadFilter, updateReputation]
    )

    const handleSelectionRateChange = useCallback(
        (items) => {
            const filteredRatings = items.filter((item) => item.value !== "6").map((item) => Number(item.value))
            const hasNotAnswered = items.some((item) => item.value === "6")
            setPage(0)
            updatePayloadFilter({
                _page: 0,
                rating: filteredRatings,
                reply: hasNotAnswered ? [] : null,
            })
            updateReputation({ selectedRating: items })
        },
        [updatePayloadFilter, updateReputation]
    )

    const handleDeleteChange = useCallback(() => {
        updatePayloadFilter({ ...payloadFilter, rating: [] })
        updateReputation({ selectedRating: [] })
    }, [payloadFilter, updatePayloadFilter, updateReputation])

    useEffect(() => {
        if (isDefaultIdsLoaded && companiesSupportWithLocation.length > 0) {
            fetchAvis()
        }
    }, [isDefaultIdsLoaded, companiesSupportWithLocation, fetchAvis])

    useEffect(() => {
        if (!alreadyLoaded) {
            updateReputation({ loading: true })
        }
    }, [alreadyLoaded, updateReputation])

    useEffect(() => {
        if (isDefaultIdsLoaded) {
            checkLimitIA()
        }
    }, [checkLimitIA, isDefaultIdsLoaded])

    useEffect(() => {
        if (profile?.uid && companies.length > 0) {
            ;(async () => {
                const list = await fetchBusinessList()
                setCompaniesSupportWithLocation(generateCompaniesSupport(list))
                setDefaultIds(list.map((item) => item.value) || [])
                setIsDefaultIdsLoaded(true)
            })()
        }
    }, [profile?.uid, fetchBusinessList, generateCompaniesSupport, companies.length])

    return {
        total,
        refBloc,
        alert,
        handleSelectionChange,
        handleSelectionRateChange,
        handleDeleteChange,
        items,
        page,
        setPage,
        loading,
        isLimitExceeded,
    }
}

export default useLogic
