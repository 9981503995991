import { ApiService } from ".."
import { CookiesService } from "@/services"
import { ErrorResponse } from "../base"
import { ICompaniesResponse } from "@/types/companies"

type CreateCompanyPayload = {
    name: string
    city: string
    zip: string
    address: string
    province_code?: string
    country_code: string
}

interface FetchListProps {
    page_number?: number
    page_size?: number
    user_uid?: string
    entity_type?: string
    fields?: string
}

class CompanyApi {
    fetchListsCompanies = async ({
        page_number,
        page_size,
        user_uid,
        fields = "",
    }: FetchListProps): Promise<ICompaniesResponse & Partial<ErrorResponse>> => {
        return ApiService.get(`/compagnies`, {
            _page: page_number,
            _limit: page_size,
            user_uid,
            reseller_uid: CookiesService.get("resellerUid"),
            fields,
        })
    }
    createCompany: any = async (
        user_uid: string,
        { name, city, zip, address, province_code, country_code }: CreateCompanyPayload
    ) => {
        return await ApiService.post(`/company`, {
            user_uid,
            name,
            city,
            zip,
            address,
            province_code,
            country_code,
            reseller_uid: CookiesService.get("resellerUid"),
        })
    }

    getACompanyEmailSupport = async ({
        companyId,
        user_uid,
    }: {
        companyId: string
        user_uid: string
    }): Promise<any> => {
        return await ApiService.get(`/company/${companyId}`, { user_uid, fields: ["email_support"] })
    }

    updateACompanyEmailSupport = async ({
        companyId,
        user_uid,
        email_support,
        is_show_choice_email_support,
    }: {
        companyId: string
        user_uid: string
        email_support?: string
        is_show_choice_email_support?: boolean
    }): Promise<void> => {
        return await ApiService.put(`/company/${companyId}`, {
            user_uid,
            email_support,
            is_show_choice_email_support,
        })
    }
}

export default new CompanyApi()
