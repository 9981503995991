import i18n from "@/i18n"
import { IKpiCampaignStore, PayloadFilter } from "@/types/statistic"
import { create } from "zustand"

export interface IKpiCampaignSetter {
    update?: (update: Partial<IKpiCampaignStore>) => void
    updatePayload: (update: Partial<PayloadFilter>) => void
    updatePeriod: (update: Partial<PayloadFilter>) => void
    flush?: () => void
}

const initalState: IKpiCampaignStore = {
    graph: null,
    kpi: null,
    selectedBusinesses: [],
    selectedPeriod: {
        label: i18n.t("LOCAL_PRESENCE.TOTAL"),
        value: 0,
    },
    loading: false,
    payloadFilter: {
        locationsId: [],
        start: "today",
        type: "all",
        previous_period: true,
    },
}

const KpiCampaignStore = create<IKpiCampaignStore & IKpiCampaignSetter>((set) => ({
    ...initalState,
    update: (update) => set(() => ({ ...update })),
    updatePayload: (update) => set((state) => ({ payloadFilter: { ...state.payloadFilter, ...update } })),
    updatePeriod: (update) => set((state) => ({ selectedPeriod: { ...state.selectedPeriod, ...update } })),
    flush: () => set(initalState),
}))

export default KpiCampaignStore
