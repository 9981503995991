import Icon from "@/components/base/Icon"
import { ProgrammingDateBloc } from "@/components/widget/programming-date-bloc"
import { SocialService } from "@/services"
import { Button, CircularProgress, Stack } from "@mui/material"
import dayjs from "dayjs"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { zodResolver } from "@hookform/resolvers/zod"
import { SubmitHandler, useForm, useWatch } from "react-hook-form"
import { z } from "zod"
import { useCallback } from "react"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import { btnActionEdit } from "../styles"
import { CampaignApi } from "@/api"
import useNotification from "@/hooks/useNotification"
import useCampaignCredit from "@/hooks/campaign/useCampaignCredit"

dayjs.extend(utc)
dayjs.extend(timezone)

export const BlocEditProgramming = ({
    setIsEdit,
    minDate,
    review,
    businesses,
    handleGetSingleReview,
    handleGetAllReviews,
    onClose,
}) => {
    const { t } = useTranslation()
    const { notif } = useNotification()
    const { fetchCredit } = useCampaignCredit()

    const schema = z
        .object({
            hour: z.string().optional(),
            date: z.string().optional(),
        })
        .superRefine(({ hour, date }, refinementContext) => {
            const isScheduledAtDateNotValid = !date || !hour

            if (isScheduledAtDateNotValid) {
                refinementContext.addIssue({
                    code: "custom",
                    path: ["date", "hour"],
                    message: "hour and date is required if add date is checked",
                })
                return true
            }

            if (date !== "") {
                const dateToPublish = dayjs(`${date}T${hour}:00`).format("YYYY-MM-DDTHH:mm:ss")
                const scheduledAtWithUtc = dayjs(dateToPublish)

                const today = SocialService.getMaxDateByLocationIds([review.location_id], businesses)
                const formattedDay = dayjs(today).format("YYYY-MM-DDTHH:mm:ss")

                if (scheduledAtWithUtc.isBefore(formattedDay, "day")) {
                    refinementContext.addIssue({
                        code: "custom",
                        path: ["date"],
                        message: "date is anterior to today",
                    })
                    return true
                }
                if (scheduledAtWithUtc.isBefore(formattedDay)) {
                    refinementContext.addIssue({
                        code: "custom",
                        path: ["hour"],
                        message: "hour is anterior",
                    })
                    return true
                }
            }
        })

    type SchemaValuesType = z.infer<typeof schema>
    const defaultValues = useMemo(() => {
        const scheduledAt = dayjs.utc(review?.scheduled_at)
        const date = scheduledAt.format("YYYY-MM-DD")
        const hour = scheduledAt.format("HH:mm")

        return {
            date: date,
            hour: hour,
        }
    }, [review])

    const { handleSubmit, formState, control, setValue, trigger } = useForm<SchemaValuesType>({
        defaultValues,
        resolver: zodResolver(schema),
        mode: "onChange",
    })
    const formValues = useWatch({ control })

    const onCancel = () => {
        setIsEdit(false)
    }

    const onChangeDate = useCallback(
        (date: any) => {
            setValue("date", date.format("YYYY-MM-DD"))
            trigger("date")
        },
        [setValue]
    )

    const onSubmit: SubmitHandler<any> = useCallback(
        async (data: SchemaValuesType) => {
            const scheduledAt = dayjs(`${data.date}T${data.hour}:00`).format("YYYY-MM-DDTHH:mm:ss")
            const response = await CampaignApi.reschedule({
                scheduledAt,
                campaignId: review.campaign_id,
                type: review.type,
            })

            await fetchCredit(review?.location?.companyId)
            if (response?.data === "success") {
                notif({ message: t("CAMPAIGN.RESCHEDULE_SUCCESS"), type: "SUCCESS" })
                handleGetSingleReview()
                handleGetAllReviews()
                setIsEdit(false)
            } else {
                notif({ message: t(`SYSTEM_ERROR.${response.error}`), type: "ERROR" })
                setIsEdit(false)
                if (response.error === "CAMPAIGN_ALREADY_PUBLISHED") onClose()
            }
        },
        [handleGetSingleReview, handleGetAllReviews, review?.campaign_id, review?.type, review?.location]
    )

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={2}>
                <ProgrammingDateBloc
                    onChangeDate={onChangeDate}
                    control={control}
                    locationIds={[review.location_id]}
                    errors={formState.errors}
                    disabled={false}
                    minDate={minDate}
                    hour={formValues.hour}
                    date={formValues.date}
                />
                <Stack flexDirection={"row"} gap={2} justifyContent={"end"} sx={btnActionEdit}>
                    <Button
                        color="secondary"
                        sx={{ padding: "8px 16px" }}
                        onClick={onCancel}
                        className="btn-cancel"
                        data-testid="btn-cancel"
                    >
                        {t("USERS.CANCEL")}
                    </Button>

                    <Button
                        disabled={Boolean(formState.errors.date || formState.errors.hour || formState.isSubmitting)}
                        variant={"contained"}
                        type="submit"
                        className="btn-save"
                        data-testid="btn-save"
                    >
                        {formState.isSubmitting && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                        {!formState.isSubmitting && <Icon name="save" sx={{ marginRight: "8px" }} />}
                        {t("USERS.SAVE")}
                    </Button>
                </Stack>
            </Stack>
        </form>
    )
}
