import Icon from "@/components/base/Icon"
import CustomTable from "@/components/widget/table/partials/custom-table"
import { paginate } from "@/utils/paginate"
import { Box, Paper, Stack, Typography } from "@mui/material"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useBusinessCompare } from "./useBusinessCompare"

export const BusinessCompare = ({ details, columns, title }) => {
    const { t } = useTranslation()
    const [page, setPage] = useState(0)
    const { defaultRows, handleSort, activeSort } = useBusinessCompare(details)

    const onPageChange = (newPage: number) => {
        setPage(newPage)
    }

    const paginateRows = useMemo(() => {
        const response = paginate(defaultRows, page === 0 ? 1 : page + 1, 5)
        return response.items || []
    }, [defaultRows, page])

    return (
        <Paper elevation={1}>
            <Stack flexDirection={"row"} alignItems={"center"} gap={2} paddingX={1}>
                <Icon name="barChart" />
                <Box>
                    <Typography data-testid="repartition-title" variant="h5">
                        {t(title)}
                    </Typography>
                </Box>
            </Stack>
            <CustomTable
                columns={columns}
                rows={paginateRows}
                showHeader={true}
                onSort={handleSort}
                activeSort={activeSort}
                pagination={{
                    totalCount: details.length,
                    rowsPerPage: 5,
                    page: page,
                }}
                onPageChange={onPageChange}
                dataTestid="business-compare"
            />
        </Paper>
    )
}
