import { AdvancedCard } from "@/components/widget/advanced-card"
import { Box, Skeleton } from "@mui/material"
import { ReactNode } from "react"

export const LocationCardSkeleton = () => {
    const footer: ReactNode = (
        <div
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                margin: 8,
                height: "100%",
                alignItems: "center",
            }}
        >
            <Skeleton variant="rectangular" width={150} height={20} />
            <Skeleton variant="rectangular" width={175} height={20} />
        </div>
    )

    return (
        <AdvancedCard
            footer={footer}
            sx={{
                height: "161px",
                width: "100%",
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Skeleton variant="rectangular" width={72} height={72} />
                <Box sx={{ flex: 1 }}>
                    <Skeleton variant="rectangular" height={20} width="50%" sx={{ marginBottom: 2 }} />
                    <Skeleton variant="rectangular" height={15} width="30%" />
                </Box>
            </Box>
        </AdvancedCard>
    )
}
