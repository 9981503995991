import React from "react"
import { Document, Page, View, Text, Image, StyleSheet, Svg, Path } from "@react-pdf/renderer"

const styles = StyleSheet.create({
    page: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center", // Centers vertically
        alignItems: "center", // Centers horizontally
        backgroundColor: "#ffffff",
        padding: 30,
        width: "100%",
        height: "100%", // Full page height
    },
    section: {
        width: "100%", // Ensure content width is relative to the page
        margin: "auto",
        padding: 10,
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center", // Centers vertically
        alignSelf: "center",
    },
    title: {
        fontSize: 24,
        fontWeight: "bold",
        maxWidth: 300,
        textAlign: "center",
        marginBottom: 16, // Add spacing below the title
    },
    qrCode: {
        width: 269,
        height: 269,
        marginVertical: 16,
    },
    logo: {
        width: 80,
        height: 80,
        marginVertical: 16,
        borderRadius: 8,
    },
    text: {
        fontSize: 12,
        marginTop: 10,
    },
})

interface PDFDocumentProps {
    qrDataUrl: string
    text: string
    logo: string
}

const StarIcon = ({ width = 32, height = 33 }) => (
    <Svg width={width} height={height} viewBox="0 0 32 33">
        <Path
            d="M14.8597 5.06257C15.1632 4.44761 15.315 4.14013 15.521 4.04189C15.7003 3.95641 15.9086 3.95641 16.0878 4.04189C16.2939 4.14013 16.4457 4.44761 16.7492 5.06257L19.6291 10.8969C19.7187 11.0784 19.7635 11.1692 19.829 11.2397C19.8869 11.3021 19.9565 11.3526 20.0337 11.3885C20.121 11.4291 20.2211 11.4437 20.4215 11.473L26.8633 12.4146C27.5416 12.5137 27.8808 12.5633 28.0378 12.729C28.1743 12.8732 28.2386 13.0712 28.2126 13.2681C28.1827 13.4944 27.9371 13.7335 27.446 14.2119L22.7864 18.7503C22.6412 18.8918 22.5686 18.9625 22.5217 19.0467C22.4802 19.1212 22.4536 19.2031 22.4433 19.2878C22.4317 19.3834 22.4489 19.4833 22.4831 19.6832L23.5826 26.0935C23.6985 26.7696 23.7565 27.1077 23.6476 27.3083C23.5527 27.4829 23.3842 27.6053 23.1889 27.6415C22.9645 27.6831 22.6609 27.5234 22.0537 27.2042L16.2948 24.1756C16.1154 24.0812 16.0257 24.0341 15.9312 24.0155C15.8475 23.9991 15.7614 23.9991 15.6777 24.0155C15.5832 24.0341 15.4935 24.0812 15.314 24.1756L9.55513 27.2042C8.94798 27.5234 8.6444 27.6831 8.41994 27.6415C8.22464 27.6053 8.05611 27.4829 7.96131 27.3083C7.85234 27.1077 7.91033 26.7696 8.02629 26.0935L9.12574 19.6832C9.16001 19.4833 9.17715 19.3834 9.16555 19.2878C9.15529 19.2031 9.12867 19.1212 9.08717 19.0467C9.04031 18.9625 8.96768 18.8918 8.82243 18.7503L4.16284 14.2119C3.67173 13.7335 3.42618 13.4944 3.3963 13.2681C3.3703 13.0712 3.43453 12.8732 3.5711 12.729C3.72806 12.5633 4.06724 12.5137 4.74559 12.4146L11.1874 11.473C11.3877 11.4437 11.4879 11.4291 11.5751 11.3885C11.6524 11.3526 11.7219 11.3021 11.7799 11.2397C11.8454 11.1692 11.8902 11.0784 11.9798 10.8969L14.8597 5.06257Z"
            fill="#FFC431"
        />
    </Svg>
)

export const PDFDocument: React.FC<PDFDocumentProps> = ({ qrDataUrl, text, logo }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 1,
                        marginVertical: 16,
                    }}
                >
                    <StarIcon width={31} height={31} />
                    <View style={{ marginBottom: 4 }}>
                        <StarIcon width={34} height={34} />
                    </View>
                    <View style={{ marginBottom: 8 }}>
                        <StarIcon width={38} height={38} />
                    </View>
                    <View style={{ marginBottom: 4 }}>
                        <StarIcon width={34} height={34} />
                    </View>
                    <StarIcon width={31} height={31} />
                </View>

                <Text style={styles.title}>{text}</Text>
                <Image style={styles.qrCode} src={qrDataUrl} />
                {logo && <Image style={styles.logo} src={logo + "?noCache=" + Math.random().toString()} />}
            </View>
        </Page>
    </Document>
)
