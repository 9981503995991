import Icon from "@/components/base/Icon"
import InputEmojiCustom from "@/components/widget/input-emoji"
import { DeleteModal } from "@/components/widget/modal-delete"
import useLogic from "@/hooks/reputation/item/useLogic"
import useLocationStore from "@/store/location"
import UiStore from "@/store/ui"
import { checkDisplayOfDate, convertToStarArray, getIconStar } from "@/utils"
import { Alert, AlertTitle, Button, Divider, IconButton, Menu, MenuItem, Paper, Stack, Typography } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import useStyles from "../styles"
import useResponsive from "@/hooks/use-responsive"
import { AddAddressModal } from "@/components/features/reputation/list-reputation/modal/add-address-modal"
import { BtnDotsHorizontalDelete } from "@/components/widget/btn-dots-horizontal-delete"
import { menuBtnRight } from "@/components/widget/btn-dots-horizontal-delete/styles"

const ItemReputation = ({ data, handleDeleteChange, isLimitExceeded, showChoiceMail, handleChangeEmailChoice }) => {
    const { classes } = useStyles()
    const { preferredLanguage } = UiStore()
    const { businesses } = useLocationStore()
    const [showAddAddressModal, setShowAddAddressModal] = useState<boolean>(false)
    const isFeedBack = useMemo<boolean>(() => !!data?.meta?.dilypse_id && data.tag === "NEGATIVE", [data])
    const showChoiceAddressIsDefined = useMemo(() => showChoiceMail !== undefined, [showChoiceMail])
    const [showChoiceAddress, setShowChoiceAddress] = useState<boolean>(
        isFeedBack &&
            showChoiceAddressIsDefined &&
            (!data?.reply?.content || data?.reply?.content === "") &&
            showChoiceMail !== false
    )

    useEffect(() => {
        if (isFeedBack && showChoiceAddressIsDefined) {
            const newShowChoiceAddress =
                (!data?.reply?.content || data?.reply?.content === "") && showChoiceMail !== false

            setShowChoiceAddress(newShowChoiceAddress)
        }
    }, [isFeedBack, data?.reply?.content, showChoiceMail, showChoiceAddressIsDefined])

    const {
        t,
        text,
        handleText,
        handleOnEnter,
        toggleMenu,
        open,
        handleClose,
        anchorEl,
        modify,
        handleModify,
        loading,
        maxLength,
        handleModalDelete,
        openModalDelete,
        openModalDeleteAvis,
        handleModalDeleteAvis,
        handleDeleteAvis,
        handleSuggest,
        loadingAI,
        userUid,
        userEmail,
        handleContinueWithActualAddress,
    } = useLogic(data?.id, data?.reply?.content || "", handleDeleteChange)

    const classAnswered = useMemo(() => {
        return data?.reply?.content ? "success.main" : "text.disabled"
    }, [data])

    const { isMobile } = useResponsive()

    const stars = useMemo(() => {
        const score = data?.rating || 0
        const scoreArray = convertToStarArray(score)
        return scoreArray
    }, [data?.rating])

    const isSingleLocation = useMemo(() => {
        return businesses?.length === 1
    }, [businesses])

    return (
        <Paper className="bloc" data-testid="reviews">
            <Stack className="section">
                <AddAddressModal
                    open={showAddAddressModal}
                    handleClose={() => setShowAddAddressModal(false)}
                    companyId={data.companyId}
                    userUid={userUid}
                />
                <Stack className="section-header">
                    <Stack className="row-item fixed-left">
                        {/* <Icon name="google" data-e2e="google-icon" /> */}
                        {data?.platformLogo && <img width={24} src={data?.platformLogo} alt="logo platform" />}
                        <Stack>
                            <Typography fontSize={"16px"} color={"action.active"} data-testid="location-name">
                                {data?.locationName}
                            </Typography>
                            {data?.locationAddress && !isSingleLocation && (
                                <Typography
                                    variant="h6"
                                    fontSize={"12px"}
                                    component={"span"}
                                    sx={{ color: "action.active", fontWeight: 600, lineHeight: "normal" }}
                                    className="ellipse"
                                    data-e2e="location-address"
                                >
                                    {`${data.locationAddress}, ${data?.locationCity}`}
                                </Typography>
                            )}
                        </Stack>
                    </Stack>
                    <Stack className="row-item">
                        {!isMobile && (
                            <Typography fontSize={"16px"} color={classAnswered} data-e2e="review-status">
                                {data?.reply?.content ? t("REPUTATION.ANSWERED") : t("REPUTATION.NOT_ANSWERED")}
                            </Typography>
                        )}
                        <Icon name="checkCircle" color={classAnswered} />
                        {data?.meta?.dilypse_id && (
                            <BtnDotsHorizontalDelete handleRemove={() => handleModalDeleteAvis(true)} />
                        )}
                    </Stack>
                    <DeleteModal
                        open={openModalDeleteAvis}
                        onClose={() => handleModalDeleteAvis(false)}
                        title={t("REPUTATION.DELETE")}
                        description={t("REPUTATION.DELETE_ASK")}
                        loading={loading}
                        onDelete={() => handleDeleteAvis()}
                    />
                </Stack>
                <Stack className="section-content" gap={2}>
                    <Stack className="section-user">
                        <Stack className="section-name">
                            {data?.author?.name && !data?.author?.isAnonymous && (
                                <Typography className="name" variant="h2" fontSize={"20px"} data-testid="author">
                                    {data?.author?.name}
                                </Typography>
                            )}
                            {data?.author?.isAnonymous && (
                                <Typography className="name" variant="h2" fontSize={"20px"} data-testid="author">
                                    {t("REPUTATION.ANONYMOUS")}
                                </Typography>
                            )}
                            <Typography
                                fontSize={"12px"}
                                color={"action.active"}
                                sx={{ width: "50%" }}
                                textAlign="right"
                                data-testid="createdAt"
                            >
                                {checkDisplayOfDate(data?.createdAt, preferredLanguage)}
                            </Typography>
                        </Stack>
                        {isFeedBack ? (
                            <Stack>
                                <Typography variant="caption" color="action.active" fontFamily={"Poppins Medium"}>
                                    {data.email}
                                </Typography>
                                <Typography variant="caption" color="info.main" fontFamily={"Poppins Medium"}>
                                    {t("CAMPAIGN.ONLY_VISIBLE_BY_YOU")}
                                </Typography>
                            </Stack>
                        ) : (
                            <Stack className="stars" data-e2e="review-stars">
                                {stars.map((i, k) => (
                                    <Icon key={k} name={getIconStar(i)} sx={{ color: "#FFC431" }} />
                                ))}
                            </Stack>
                        )}
                    </Stack>
                    <Stack>
                        <Typography sx={{ wordBreak: "break-word" }} fontSize={"16px"} data-e2e="review-content">
                            {data?.content}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack className="section-response">
                    {data?.reply?.content && !modify && (
                        <Stack className={classes.blocResponse}>
                            <Divider className="divider-left" orientation="vertical" flexItem />
                            <Stack width="100%" gap={1}>
                                <Stack width={"100%"}>
                                    <Stack className="section-title">
                                        <Stack flexDirection={"row"} gap={1} alignItems={"center"} width={"100%"}>
                                            {data?.reply?.ai ? (
                                                <Icon name="doubleStar" sx={{ color: "#8311D7" }} />
                                            ) : (
                                                <Icon name="send" sx={{ color: "primary.main" }} />
                                            )}
                                            <Stack className="response-title">
                                                <Typography
                                                    className="name"
                                                    variant="caption"
                                                    fontSize={"16px"}
                                                    fontWeight="600"
                                                    fontFamily={"Poppins Medium"}
                                                    data-testid="answer"
                                                >
                                                    {t("REPUTATION.ANSWER")}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        {data.tag !== "NEGATIVE" && (
                                            <Stack
                                                flexDirection={"row"}
                                                gap={1}
                                                alignItems={"center"}
                                                textAlign="right"
                                            >
                                                {data?.reply?.updatedAt && (
                                                    <Typography fontSize={"12px"} color={"action.active"} width="120px">
                                                        {checkDisplayOfDate(data?.reply?.updatedAt, preferredLanguage)}
                                                    </Typography>
                                                )}
                                                <Stack className="menu-right">
                                                    <IconButton onClick={toggleMenu} data-testid="menu-right">
                                                        <Icon name="threeDotsHorizontal" />
                                                    </IconButton>
                                                    <Menu
                                                        elevation={6}
                                                        id={"basic-menu"}
                                                        open={open}
                                                        onClose={handleClose}
                                                        anchorEl={anchorEl}
                                                        sx={menuBtnRight}
                                                    >
                                                        <MenuItem
                                                            onClick={() => handleModify(true)}
                                                            className="clickable"
                                                            data-testid="modify"
                                                        >
                                                            <Icon
                                                                name="edit"
                                                                color="action.active"
                                                                className="edit-icon"
                                                            />
                                                            <Typography fontSize={"16px"} color={"action.active"}>
                                                                {t("REPUTATION.MODIFY")}
                                                            </Typography>
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => handleModalDelete(true)}
                                                            className="remove clickable"
                                                            data-testid="remove"
                                                        >
                                                            <Icon name="delete1" sx={{ color: "error.main" }} />
                                                            <Typography fontSize={"16px"} color={"error.main"}>
                                                                {t("REPUTATION.REMOVE")}
                                                            </Typography>
                                                        </MenuItem>
                                                    </Menu>
                                                </Stack>
                                            </Stack>
                                        )}
                                    </Stack>
                                </Stack>
                                <Stack>
                                    <Typography
                                        sx={{ wordBreak: "break-word", whiteSpace: "pre-line" }}
                                        fontSize={"16px"}
                                        data-testid="answer-content"
                                    >
                                        {data?.reply?.content}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    )}
                    {showChoiceAddress && showChoiceAddressIsDefined && (
                        <Alert
                            sx={{ flexDirection: "row", backgroundColor: "#E5F6FD", color: "#2EAFF7" }}
                            severity="error"
                            variant="filled"
                            data-testid="alert-choose-a-email-support"
                        >
                            <AlertTitle sx={{ color: "#014361" }}>{t("REPUTATION.VERIFY_EMAIL")}</AlertTitle>
                            <Stack gap={1} marginBottom={2}>
                                <Typography fontWeight={500}>
                                    {/* Display the email underlined */}
                                    {(() => {
                                        const translation = t("REPUTATION.VERIFY_EMAIL_DETAILS", { email: userEmail })

                                        if (translation.includes(userEmail)) {
                                            const split = translation.split(userEmail)
                                            split.splice(1, 0, userEmail)

                                            return split.map((part, index) =>
                                                part === userEmail ? (
                                                    <span key={index} style={{ textDecoration: "underline" }}>
                                                        {userEmail}
                                                    </span>
                                                ) : (
                                                    part
                                                )
                                            )
                                        }

                                        const parts = translation.split("{email}")

                                        return (
                                            <>
                                                {parts[0]}
                                                <span style={{ textDecoration: "underline" }}>{userEmail}</span>
                                                {parts[1] || ""}{" "}
                                                {/* Si parts[1] est undefined, affiche une chaîne vide */}
                                            </>
                                        )
                                    })()}
                                </Typography>
                            </Stack>
                            <Stack flexDirection={isMobile ? "column-reverse" : "row"} gap={0.5}>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setShowAddAddressModal(true)
                                    }}
                                >
                                    {t("REPUTATION.USE_ANOTHER_ADDRESS")}
                                </Button>
                                <Button
                                    variant="text"
                                    onClick={async () => {
                                        setShowChoiceAddress(false)
                                        handleChangeEmailChoice(data.companyId)
                                        await handleContinueWithActualAddress(data?.companyId)
                                    }}
                                >
                                    {t("REPUTATION.CONTINUE_ACTUAL_ADDRESS")}
                                </Button>
                            </Stack>
                        </Alert>
                    )}
                    {(modify || !data?.reply?.content) && !showChoiceAddress && showChoiceAddressIsDefined && (
                        <InputEmojiCustom
                            text={text}
                            setText={handleText}
                            handleSave={handleOnEnter}
                            handleCancel={() => handleModify(false)}
                            isModify={modify}
                            responseByAi={data?.reply?.ai}
                            isLoading={loading}
                            loadingAI={loadingAI}
                            maxLength={maxLength}
                            handleSuggest={handleSuggest}
                            isLimitExceeded={isLimitExceeded}
                        />
                    )}
                </Stack>
            </Stack>
            <DeleteModal
                open={openModalDelete}
                onClose={() => handleModalDelete(false)}
                title={t("REPUTATION.REMOVE_ANSWER")}
                description={t("REPUTATION.ASK")}
                loading={loading}
                onDelete={() => handleOnEnter(null)}
            />
        </Paper>
    )
}

export default ItemReputation
