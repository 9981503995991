import Icon from "@/components/base/Icon"
import BaseSelect from "@/components/base/select"
import { TitleWrapper } from "@/components/base/title-wrapper"
import EditStandardScheduleContainer from "@/components/features/business/form-container/edit/edit-standard-schedule"
import FormAction from "@/components/features/business/form-container/edit/form-action"
import Schedule from "@/components/features/business/schedule/standard-item"
import useLogic from "@/hooks/businesses/edit-standard-schedule/useLogic"
import { PageProps } from "@/types/page"
import { Alert, AlertTitle, Box, Paper, Typography } from "@mui/material"

const EditStandardSchedule = ({ onClose, open }: PageProps) => {
    const {
        t,
        days,
        loading,
        isFormValid,
        isFormSubmitted,
        scheduleStatus,
        defaultStatusSchedule,
        setDays,
        handleSubmit,
        onSubmit,
        onErrors,
        handlePreviousButton,
        handleCancelButton,
        handleStatusScheduleClick,
    } = useLogic()

    return (
        <EditStandardScheduleContainer open={open} onClose={onClose}>
            <TitleWrapper
                handlePreviousButton={handlePreviousButton}
                title={t("BUSINESSES.SCHEDULE.EDIT_STANDARD_OPENING")}
            />
            <form onSubmit={handleSubmit(onSubmit, onErrors)}>
                <Box className="inputs-container">
                    <Paper
                        className="inputs-wrapper"
                        sx={{
                            gap: defaultStatusSchedule == "OPEN" && "initial",
                            "@media (min-width: 768px)": {
                                gap: !defaultStatusSchedule?.includes("CLOSED") ? "16px" : "initial",
                            },
                        }}
                    >
                        <Box className="title-wrapper">
                            <Box className="title">
                                <Icon name="calendar" />
                                <Typography variant="h2">{t("BUSINESSES.SCHEDULE.STANDARD_OPENING")}</Typography>
                            </Box>
                            {defaultStatusSchedule && (
                                <Box className="select-status-wrapper" data-e2e="select-standard-statut">
                                    <BaseSelect
                                        label={t("BUSINESSES.SCHEDULE.BUSINESS_STATUS")}
                                        options={scheduleStatus}
                                        onItemClicked={handleStatusScheduleClick}
                                        defaultValue={defaultStatusSchedule}
                                        className="select-status-schedule"
                                    />
                                </Box>
                            )}
                        </Box>
                        <Box className="paper-wrapper">
                            {defaultStatusSchedule === "OPEN" &&
                                days?.map((day, key) => (
                                    <Schedule
                                        dataE2e={`standar-schedule-${key}`}
                                        day={day}
                                        setDays={setDays}
                                        key={key}
                                        isFormSubmitted={isFormSubmitted}
                                    />
                                ))}
                            {defaultStatusSchedule === "OPEN_WITHOUT_STANDARD_HOURS" && (
                                <Alert
                                    severity="info"
                                    sx={{
                                        boxSizing: "border-box",
                                        flexDirection: "row",
                                        mx: "auto",
                                        "@media (min-width: 768px)": { width: "100%" },
                                    }}
                                >
                                    <AlertTitle>{t("BUSINESSES.SCHEDULE.HIDDEN_GOOGLE_TITLE")}</AlertTitle>
                                    {t("BUSINESSES.SCHEDULE.HIDDEN_GOOGLE_CONTENT")}
                                </Alert>
                            )}
                        </Box>
                    </Paper>
                </Box>
                <FormAction
                    isUpdating
                    handleCancel={handleCancelButton}
                    isLoading={loading}
                    isDisabled={(isFormSubmitted && !isFormValid) || loading}
                />
            </form>
        </EditStandardScheduleContainer>
    )
}

export default EditStandardSchedule
