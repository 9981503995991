import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import { withSxProp } from "@/utils"
import { Box, Divider, FormLabel, IconButton, Modal, Paper, Stack, Switch, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { modalStyle } from "@/pages/users/styles"
import { modalQR } from "../styles"
import { Link } from "react-router-dom"
import useClipboard from "@/hooks/useClipboard"
import { FeedbackService } from "@/api"

export const LinkDetails = ({ open, handleClose, linkLocation }) => {
    const { t } = useTranslation()
    const { ButtonClipboard } = useClipboard()
    const [activeFilter, setActiveFilter] = useState(false)

    useEffect(() => {
        setActiveFilter(linkLocation?.feedback ?? false)
    }, [linkLocation])

    const handleChangeFilter = () => {
        const linkValue: string = linkLocation?.value ? linkLocation.value.split("/").pop() || "" : ""
        FeedbackService.updateFeedBack({ status: !activeFilter, link: linkValue })
            .then(() => {
                setActiveFilter((prev) => !prev)
            })
            .catch(console.error)
    }

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-remove">
            <Paper elevation={6} sx={withSxProp(modalStyle, modalQR)}>
                <Stack className="bloc-modal">
                    <Stack padding={2} gap={1}>
                        <Box className="title-wrapper">
                            <Typography id="modal-modal-title" variant="h2">
                                {t("QR_CODE_LINK.MODAL.TITLE_LINK")}
                            </Typography>
                            <CustomTooltip title={t("USERS.CLOSE")}>
                                <IconButton data-testid="close-modal-delete" onClick={handleClose}>
                                    <Icon name="x" />
                                </IconButton>
                            </CustomTooltip>
                        </Box>
                        <Stack
                            className="bloc-switch"
                            flexDirection={"row"}
                            alignItems={"flex-start"}
                            justifyContent={"flex-start"}
                            gap={1}
                        >
                            <Switch checked={activeFilter} onChange={handleChangeFilter} />
                            <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                                <FormLabel sx={{ color: "action.active" }}>
                                    {t("QR_CODE_LINK.MODAL.ACTIVE_FILTER_REVIEW")}
                                </FormLabel>
                                <CustomTooltip title={t("CAMPAIGN.FILTER")}>
                                    <IconButton className="edit-btn">
                                        <Icon name="info" sx={{ color: "action.active" }} />
                                    </IconButton>
                                </CustomTooltip>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Divider />
                    <Stack padding={2}>
                        <Link
                            to={linkLocation?.value ?? "#"}
                            id="link-url"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#0180FF" }}
                        >
                            <Typography
                                variant="body1"
                                color={"primary"}
                                sx={{ textOverflow: "ellipsis", display: "block", overflow: "hidden" }}
                            >
                                {linkLocation?.value ?? ""}
                            </Typography>
                        </Link>
                    </Stack>
                    <Divider />
                    <Stack gap={2} padding={2}>
                        <ButtonClipboard label={t("QR_CODE_LINK.MODAL.COPY")} value={linkLocation?.value} />
                    </Stack>
                </Stack>
            </Paper>
        </Modal>
    )
}
