import { TitleWrapper } from "@/components/base/title-wrapper"
import UiPageSessionStore from "@/store/ui-page-session"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
type DetailsQrCodeLocationProps = {
    name: string
}

export const DetailsQrCodeLocation: React.FC<DetailsQrCodeLocationProps> = ({ name }) => {
    const { updateState: setUiPageSession } = UiPageSessionStore()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const handlePreviousButton = useCallback(() => {
        setUiPageSession({ menuPage: "qr" })
        navigate("/qr")
    }, [navigate, setUiPageSession])

    return (
        <TitleWrapper
            className="title-qr-code"
            title={name}
            tooltipText={t("USERS.BACK_TOOLTIP")}
            handlePreviousButton={handlePreviousButton}
        />
    )
}
