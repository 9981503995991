import useStyles from "@/components/features/campaign/styles"
import { Box, Divider, Skeleton, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

export default function EmailSkeleton() {
    const { t } = useTranslation()
    const { classes } = useStyles()

    return (
        <Box className={classes.boxMessage}>
            <Stack gap={2}>
                <Stack>
                    <Typography component="span" color={"action.active"}>
                        {t("CAMPAIGN.FROM")}:
                    </Typography>
                    <Skeleton variant="rectangular" width="40%" height={24} />
                </Stack>
                <Stack gap={1}>
                    <Typography component="span" color={"action.active"}>
                        {t("CAMPAIGN.SUBJECT")}:
                    </Typography>
                    <Skeleton variant="rectangular" width="40%" height={24} />
                </Stack>
                <Stack className="preview-message" gap={0.5}>
                    <Skeleton variant="rectangular" width="100%" height={16} />
                    <Skeleton variant="rectangular" width="100%" height={16} />
                    <Skeleton variant="rectangular" width="100%" height={16} />
                    <Skeleton variant="rectangular" width="100%" height={16} />
                    <Skeleton variant="rectangular" width="80%" height={16} />
                </Stack>

                <Divider className="separate" orientation="horizontal" flexItem />
                <Stack gap={1}>
                    <Skeleton variant="rectangular" width="72px" height="72px" />
                    <Stack gap={0.5}>
                        <Skeleton variant="rectangular" width="50%" height={24} />
                        <Skeleton variant="rectangular" width="80%" height={16} />
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    )
}
